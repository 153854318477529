const ENV = {
    'local': {
        base_url: "http://127.0.0.1:8000",
        site_url: "http://localhost:3000",
    },
    'dosug1': {
        title: 'Indi Admin',
        base_url: "https://indi-api.treeweb.top",
        site_url: "https://indi.treeweb.top",
    },
    'dosug4': {
        title: 'Devki Admin',
        base_url: "https://devki-api.treeweb.top",
        site_url: "https://devki.treeweb.top",
    },
};

const urls = ENV['dosug1'];

export const BASE_URL = urls.base_url;
export const SITE_URL = urls.site_url;
export const TITLE = urls.title;
