import React, { PureComponent } from 'react';

import { Loader } from "components/common";
import styles from './screen.module.css';


export default class AppInitScreen extends PureComponent {
  render() {
    return (
      <div className={styles.main}>
        <Loader active={true} fixed={true} color="transparent"/>
      </div>
    );
  }
}
