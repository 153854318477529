import { BASE_URL } from "config";
import * as base from "./_base";

export const endpoints = {
    list: `${BASE_URL}/api/v1/profile/`,
    detail: (id) => `${BASE_URL}/api/v1/profile/${id}/`,
};

export const profileListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const profileDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const profileUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const profileDelete = (token, data) => base.Delete(endpoints.detail, token, data);
