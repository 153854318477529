import React, { Component } from 'react';
import { Button, Modal, Form, Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";

import MainFieldSet from './MainFieldSet';


class ReviewForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, review, contentType, objectId } = this.props;
        const instance = review.editObject;

        let data = Object.assign({ content_type: contentType, object_id: objectId }, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        if (data.meeting_date) {
          data.meeting_date = data.meeting_date.format('YYYY-MM-DD');
        }
        console.log(data);
        actions.reviewUpdate(data);
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.reviewShowEditForm({ show: false });
  };

  render() {
    const { form, review, objectName } = this.props;
    const { showEditForm, updateStatus } = review;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = updateStatus.isLoading;
    let title = 'Редактирование отзыва';
    if (objectName) {
      title += ' для анкеты ' + objectName;
    }

    return (
      <Modal
        width={800}
        visible={showEditForm}
        title={title}
        onCancel={this.close}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit}>
            {errorData.non_field_errors &&
            <div className="form-error">{errorData.non_field_errors}</div>}

            <MainFieldSet review={review} form={form}/>
          </Form>
        </Spin>
      </Modal>
    );
  }
}


const WrappedReviewForm = Form.create()(ReviewForm);

function mapStateToProps(state) {
  return {
    review: state.review,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      actions.review,
    ), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedReviewForm);

