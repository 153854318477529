import { BASE_URL } from "config";
import * as base from "./_base";

export const endpoints = {
    list: `${BASE_URL}/api/v1/site/nav/`,
    detail: (id) => `${BASE_URL}/api/v1/site/nav/${id}/`,
};

export const siteNavListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const siteNavDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const siteNavUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const siteNavDelete = (token, data) => base.Delete(endpoints.detail, token, data);
