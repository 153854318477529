import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/profile';


export function* watch() {
  yield takeEvery(actions.PROFILE_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.PROFILE_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.PROFILE_UPDATE.START, actionUpdate);
  yield takeEvery(actions.PROFILE_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.profileListLoad,
    {
      request: actions.profileListLoadRequest,
      complete: actions.profileListLoadComplete,
      error: actions.profileListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.profileDetailLoad,
    {
      request: actions.profileDetailLoadRequest,
      complete: actions.profileDetailLoadComplete,
      error: actions.profileDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.profileUpdate,
    {
      request: actions.profileUpdateRequest,
      complete: actions.profileUpdateComplete,
      error: actions.profileUpdateError,
    },
    function* (data) {
      yield put(actions.profileShowEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(actions.profileDetailLoad({ id: data.id }));
        yield put(push(`/profile/${data.id}`));
      } else {
        const filter = yield select((state) => state.profile.filter);
        yield put(actions.profileListLoad(filter));
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.profileDelete,
    {
      request: actions.profileDeleteRequest,
      complete: actions.profileDeleteComplete,
      error: actions.profileDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.profile.filter);
      yield put(actions.profileListLoad(filter));
    }
  );
}
