import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const CITY_LIST_LOAD = createRequestTypes('CITY_LIST_LOAD');
export const CITY_DETAIL_LOAD = createRequestTypes('CITY_DETAIL_LOAD');
export const CITY_UPDATE = createRequestTypes('CITY_UPDATE');
export const CITY_DELETE = createRequestTypes('CITY_DELETE');
export const CITY_SHOW_EDIT_FORM = 'CITY_SHOW_EDIT_FORM';

export const cityShowEditForm = createAction(CITY_SHOW_EDIT_FORM);

export const cityListLoad = createAction(CITY_LIST_LOAD.START);
export const cityListLoadRequest = createAction(CITY_LIST_LOAD.REQUEST);
export const cityListLoadComplete = createAction(CITY_LIST_LOAD.COMPLETE);
export const cityListLoadError = createAction(CITY_LIST_LOAD.ERROR);

export const cityDetailLoad = createAction(CITY_DETAIL_LOAD.START);
export const cityDetailLoadRequest = createAction(CITY_DETAIL_LOAD.REQUEST);
export const cityDetailLoadComplete = createAction(CITY_DETAIL_LOAD.COMPLETE);
export const cityDetailLoadError = createAction(CITY_DETAIL_LOAD.ERROR);

export const cityUpdate = createAction(CITY_UPDATE.START);
export const cityUpdateRequest = createAction(CITY_UPDATE.REQUEST);
export const cityUpdateComplete = createAction(CITY_UPDATE.COMPLETE);
export const cityUpdateError = createAction(CITY_UPDATE.ERROR);

export const cityDelete = createAction(CITY_DELETE.START);
export const cityDeleteRequest = createAction(CITY_DELETE.REQUEST);
export const cityDeleteComplete = createAction(CITY_DELETE.COMPLETE);
export const cityDeleteError = createAction(CITY_DELETE.ERROR);
