import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { OnOff, DateTimeText, FieldValue, GenericFKValue } from "components/common";
import { PageBlock } from "components/layout";
import { SiteValue } from "components/blocks/Site";


class ReviewMainScreen extends Component {
  render() {
    const { review, actions } = this.props;
    const detail = review.detail || {};

    return (
      <div className="page-content page-content-full">
        <Row gutter={16}>
          <Col span={15}>
            <PageBlock>
              <FieldValue label="Сайт" labelWidth={6}>
                <SiteValue id={detail.site} asLink={true}/>
              </FieldValue>

              <FieldValue label="Анкета" labelWidth={6}>
                <GenericFKValue object={detail.content_object}/>
              </FieldValue>

              <FieldValue label="Имя" labelWidth={6}>
                {detail.name}
              </FieldValue>

              <FieldValue label="Отзыв" labelWidth={6}>
                {detail.text}
              </FieldValue>

              <FieldValue label="Дата" labelWidth={6}>
                <DateTimeText date={detail.date} onlyDate={false}/>
              </FieldValue>

              <FieldValue label="Опубликовано" labelWidth={6}>
                <OnOff
                  object={detail}
                  param="is_published"
                  loading={review.updateStatus.isLoading}
                  onChange={data => actions.reviewUpdate(data)}
                />
              </FieldValue>
            </PageBlock>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    review: state.review,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.review), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewMainScreen);
