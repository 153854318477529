import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { CityList, CityForm } from 'components/blocks/City';


class CityListScreen extends Component {
  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.cityShowEditForm({ show: true, object: item });
  };

  render() {
    const { history } = this.props;

    return (
      <Page>
        <PageHeader
          title="Города"
          actions={[
            <Button type="primary" className="" icon="plus" onClick={() => this.showEditForm()}>
              Добавить город
            </Button>
          ]}
        />

        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            <CityList history={history}/>
          </div>
        </div>

        <CityForm/>
      </Page>
    );
  }
}


function mapStateToProps(state) {
  return {
    city: state.city,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.city), dispatch),
    dispatch: dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(CityListScreen);
