import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Button } from "antd";

import { actions } from "store";
import { ReviewList, ReviewForm } from 'components/blocks/Review';


class ProfileReviewListScreen extends Component {
  showForm = (item = null) => {
    const { actions } = this.props;
    actions.reviewShowEditForm({ show: true, object: item });
  };

  render() {
    const { profile, history } = this.props;
    const detail = profile.detail;

    return (
      <Fragment>
        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            {detail &&
            <ReviewList
              history={history}
              activeFilter={{ content_type: 'profile', object_id: detail.id }}
              topActions={[
                <Button type="primary" className="" icon="plus" onClick={() => this.showForm()}>
                  Добавить отзыв
                </Button>
              ]}
            />}
          </div>
        </div>

        {detail &&
        <ReviewForm contentType='profile' objectId={detail.id} objectName={detail.name}/>}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.review), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileReviewListScreen);
