import React, { PureComponent, Fragment } from 'react';
import { Dropdown, Menu, Icon, Popconfirm } from 'antd';
import classNames from 'classnames';


export default class ActionButton extends PureComponent {
  render() {
    const { className, actions } = this.props;

    const cx = classNames([
      'action-button',
      className
    ]);

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        {actions.map(obj => {
          return (
            <Menu.Item key={obj.key} onClick={!obj.confirm && obj.action}>
              {obj.confirm ?
                <Popconfirm
                  placement="bottomLeft" title={obj.confirm.title}
                  okText={obj.confirm.okText} cancelText={obj.confirm.cancelText}
                  onConfirm={obj.action}
                >
                    {obj.icon && <Icon type={obj.icon}/>}
                  {obj.title}
                </Popconfirm>
                :
                <Fragment>
                    {obj.icon && <Icon type={obj.icon}/>}
                  {obj.title}
                </Fragment>
              }

            </Menu.Item>
          )
        })}
      </Menu>
    );

    return (
      <Dropdown className={cx} overlay={menu} trigger={['click']}>
        <Icon type="more"/>
      </Dropdown>
    );
  }
}
