import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { cityDetailLoad } from "store/api/city";
import { DataLoadComponent } from 'components/common';


class CityValue extends DataLoadComponent {
  apiDetailLoad = cityDetailLoad;

  render() {
    const { id, asLink, state } = this.props;
    const { detail, loadStatus } = this.state;
    const value = (detail && detail.name) || id;
    const _id = (detail && detail.id) || id;

    return (
      <Spin spinning={loadStatus.isLoading}>
        {asLink && _id ?
          <Link to={{ pathname: `/city/${_id}`, state: state }}>{value}</Link> :
          <span>{value}</span>}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(CityValue);
