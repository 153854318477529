import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.bannerShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.bannerListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.bannerListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.bannerListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.bannerDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.bannerDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.bannerDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.bannerUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.bannerUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.bannerUpdateError]: (state, action) => base.updateError(state, action),

    [actions.bannerDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.bannerDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.bannerDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
