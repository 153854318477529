import moment from "moment";

export const parseDate = (date, format = 'YYYY-MM-DDTHH:mm:ss') => {
    return moment(date, format)
};

export const getDateNowStr = (format = 'YYYY-MM-DDTHH:mm:ss') => {
    return moment().format(format)
};

export const MONTHS = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
};


export const MONTHS2 = {
    0: 'Января',
    1: 'Февраля',
    2: 'Марта',
    3: 'Апреля',
    4: 'Мая',
    5: 'Июня',
    6: 'Июля',
    7: 'Августа',
    8: 'Сентября',
    9: 'Октября',
    10: 'Ноября',
    11: 'Декабря',
};


export const getDateParam = (year, month, date) => {
    let _date = null;
    if (year && month && date) {
        _date = `${year}-${month}-${date}`;
    }
    return _date;
};
