import React, { Component } from 'react';
import { Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { arrayIsEqual, tableColumn } from "utils";
import { TableList, ActionButton, DateTimeText, GenericFKValue } from 'components/common';
import { SiteSelect, SiteValue } from 'components/blocks/Site';
import { ReviewValue } from 'components/blocks/Review';


class ReviewList extends Component {
  state = {
    updateItemId: null,
    columnList: ['id', 'name', 'profile', 'text', 'date', 'is_published', 'actions'],
    columnByName: {
      id: tableColumn('ID', 'id', {
        sorter: true,
        width: 59,
      }),
      name: tableColumn('Имя', 'name', {
        render: (val, item) => <ReviewValue detail={item} asLink={true}/>,
        sorter: true,
        width: 150,
      }),
      profile: tableColumn('Анкета', 'profile', {
        width: 150,
        render: (val, item) => <GenericFKValue object={item.content_object}/>
      }),
      text: tableColumn('Отзыв', 'text', {}),
      date: tableColumn('Дата', 'date', {
        render: (text, item) => <DateTimeText date={text}/>,
        sorter: true,
        width: 130,
      }),
      is_published: tableColumn('Опубликовано', 'is_published', {
        sorter: true,
        width: 120,
        render: (val, item) =>
          <div>
            <Switch
              style={{ marginTop: '5px', minWidth: '62px' }}
              checkedChildren="да" unCheckedChildren="нет"
              checked={item.is_published}
              loading={this.props.review.updateStatus.isLoading && this.state.updateItemId === item.id}
              onClick={() => this.reviewOnOff(item)}
            />
          </div>,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    const { review } = this.props;
    if (activeFilter === undefined) {
      activeFilter = review.filter.activeFilter
    }
    this.props.actions.reviewListLoad({ query, activeFilter, locationChange: true });
  };

  showEditForm = (item) => {
    this.props.actions.reviewShowEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.reviewDelete({ id: item.id })
  };

  reviewOnOff = (item) => {
    const data = { id: item.id, is_published: !item.is_published, preventLoadDetail: true };
    this.setState({
      updateItemId: item.id,
    });
    this.props.actions.reviewUpdate(data);
  };

  render() {
    const { history, defaultFilter, activeFilter, topActions, review } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={review}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        topActions={topActions}
        showSearch={true}
        onRow={(item, rowIndex) => {
          return {
            onDoubleClick: event => {
              this.props.history.push(`/review/${item.id}`);
            },
          };
        }}

        filterFields={{
          list: [
            'site', 'is_published'
          ],
          byName: {
            'site': {
              label: 'Сайт',
              type: 'list_int',
              component: SiteSelect,
              valueComponent: SiteValue,
            },
            'is_published': {
              label: 'Опубликовано',
              type: 'bool',
            },
          }
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    review: state.review,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      actions.review,
    ), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewList);
