import React, { Component } from 'react';
import { Row, Col, Collapse } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactPlayer from 'react-player';

import { actions } from "store";
import { getUrl } from "utils";
import { getParametetsValue } from "utils/choices";
import { ModerationNewValue, Status, FieldValue, OnOff } from "components/common";
import { SiteValue } from "components/blocks/Site";
import { CityValue } from "components/blocks/City";
import { DistrictValue } from "components/blocks/District";
import { ServiceValue } from "components/blocks/Service";
import styles from './detail.module.css';


class ProfileMainScreen extends Component {
  render() {
    const { profile } = this.props;
    const detail = profile.detail || {};
    const moderation_data = detail.moderation_data || {};

    const siteObj = detail.site || {};
    const parameters = detail.parameters || {};
    const contacts = detail.contacts || {};
    const photos = moderation_data.photos || detail.photos;
    const videos = moderation_data.videos || detail.videos;

    const districts = Array.isArray(detail.districts) ? detail.districts : [];
    const services = Array.isArray(detail.services) ? detail.services : [];

    const prices = detail.prices || {};

    return (
      <div className="page-content page-content-full">
        <Row gutter={16}>
          <Col span={16}>
            <div className="page-block">
              <FieldValue label="Имя" labelWidth={5}>
                {detail.name}
                <ModerationNewValue value={moderation_data.name}/>
              </FieldValue>

              <FieldValue label="Описание" labelWidth={5}>
                {detail.text}
                <ModerationNewValue value={moderation_data.text}/>
              </FieldValue>

              <FieldValue label="Сайт" labelWidth={5}>
                <SiteValue detail={siteObj}/>
              </FieldValue>

              <FieldValue label="Город" labelWidth={5}>
                <CityValue detail={siteObj.city}/>
              </FieldValue>

              <FieldValue label="Районы" labelWidth={5}>
                {districts.length > 0 && <DistrictValue activeFilter={{ id: districts.join(',') }}/>}
              </FieldValue>

              <FieldValue label="Услуги" labelWidth={5}>
                {services.length > 0 && <ServiceValue activeFilter={{ id: services.join(',') }}/>}
              </FieldValue>

              <FieldValue label="Статус" labelWidth={5}>
                <Status status={detail.status}/>
              </FieldValue>

              <FieldValue label="Включена" labelWidth={5}>
                <OnOff
                  object={detail}
                  param="is_on"
                  loading={profile.updateStatus.isLoading}
                  onChange={data => this.props.actions.profileUpdate(data)}
                />
              </FieldValue>
            </div>

            <br/>

            <div className="page-block">
              <Row>
                <Col span={12}>
                  <FieldValue label="Пол" labelWidth={10}>
                    {getParametetsValue(parameters, 'gender')}
                  </FieldValue>

                  <FieldValue label="Возраст" labelWidth={10}>
                    {getParametetsValue(parameters, 'age')}
                  </FieldValue>

                  <FieldValue label="Вес" labelWidth={10}>
                    {getParametetsValue(parameters, 'weight')}
                  </FieldValue>

                  <FieldValue label="Рост" labelWidth={10}>
                    {getParametetsValue(parameters, 'height')}
                  </FieldValue>
                </Col>

                <Col span={12}>
                  <FieldValue label="Внешность" labelWidth={10}>
                    {getParametetsValue(parameters, 'ethnicity')}
                  </FieldValue>

                  <FieldValue label="Цвет волос" labelWidth={10}>
                    {getParametetsValue(parameters, 'hair_color')}
                  </FieldValue>

                  <FieldValue label="Тип груди" labelWidth={10}>
                    {getParametetsValue(parameters, 'breast_type')}
                  </FieldValue>

                  <FieldValue label="Размер груди" labelWidth={10}>
                    {getParametetsValue(parameters, 'breast_size')}
                  </FieldValue>
                </Col>
              </Row>
            </div>

            <br/>

            <div className="page-block">
              <FieldValue label="Телефон" labelWidth={5}>
                {contacts.phone}
              </FieldValue>

              <FieldValue label="WhatsApp" labelWidth={5}>
                {contacts.whatsapp}
              </FieldValue>

              <FieldValue label="Telegram" labelWidth={5}>
                {contacts.telegram}
              </FieldValue>

              <FieldValue label="Skype" labelWidth={5}>
                {contacts.skype}
              </FieldValue>

              <FieldValue label="Viber" labelWidth={5}>
                {contacts.viber}
              </FieldValue>
            </div>
          </Col>

          <Col span={8}>
            <div className="page-block">
              {photos && photos.length > 0 &&
              <div>
                <div className={styles.photoDetail}>
                  <img src={getUrl(photos[0].url)} alt=""/>
                </div>

                {photos.length > 1 &&
                <div className={styles.photos}>
                  {photos.slice(1).map(p => (
                    <div key={`photo${p.id}`} className={styles.photo}>
                      <img src={getUrl(p.url)} alt=""/>
                    </div>
                  ))}
                </div>
                }
              </div>
              }
            </div>

            <br/>

            {videos && videos.length > 0 &&
            <div className="page-block">
              {videos.map(item => (
                <div key={`video${item.id}`} className='player-wrapper'>
                  <ReactPlayer
                    className='react-player'
                    url={getUrl(item.url)}
                    light={getUrl(item.poster_url)}
                    controls={true}
                    width='100%'
                    height='100%'
                  />
                </div>
              ))}
            </div>
            }

            <br/>

            <Collapse defaultActiveKey={['in', 'out']}>
              <Collapse.Panel header="Апартаменты" key="in">
                <FieldValue label="1 час" labelWidth={8}>
                  {prices.in_hour} {prices.in_hour && 'руб'} {prices.in_hour_note && <span>(prices.in_hour_note)</span>}
                </FieldValue>
                <FieldValue label="2 часа" labelWidth={8}>
                  {prices.in_hour2} {prices.in_hour2 && 'руб'} {prices.in_hour2_note &&
                <span>(prices.in_hour2_note)</span>}
                </FieldValue>
                <FieldValue label="Ночь" labelWidth={8}>
                  {prices.in_night} {prices.in_night && 'руб'} {prices.in_night_note &&
                <span>(prices.in_night_note)</span>}
                </FieldValue>
                <FieldValue label="Экспресс" labelWidth={8}>
                  {prices.in_express} {prices.in_express && 'руб'} {prices.in_express_note &&
                <span>(prices.in_express_note)</span>}
                </FieldValue>
              </Collapse.Panel>

              <Collapse.Panel header="Выезд" key="out">
                <FieldValue label="1 час" labelWidth={8}>
                  {prices.out_hour} {prices.out_hour && 'руб'} {prices.out_hour_note &&
                <span>(prices.out_hour_note)</span>}
                </FieldValue>
                <FieldValue label="2 часа" labelWidth={8}>
                  {prices.out_hour2} {prices.out_hour2 && 'руб'} {prices.out_hour2_note &&
                <span>(prices.out_hour2_note)</span>}
                </FieldValue>
                <FieldValue label="Ночь" labelWidth={8}>
                  {prices.out_night} {prices.out_night && 'руб'} {prices.out_night_note &&
                <span>(prices.out_night_note)</span>}
                </FieldValue>
                <FieldValue label="Экспресс" labelWidth={8}>
                  {prices.out_express} {prices.out_express && 'руб'} {prices.out_express_note &&
                <span>(prices.out_express_note)</span>}
                </FieldValue>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.profile), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMainScreen);
