import React, { Component } from 'react';

import { DistrictTransferField } from 'components/blocks/District';


export default class GeoFieldSet extends Component {
  render() {
    const { form, profile } = this.props;
    const { updateStatus, editObject } = profile;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const siteId = form.getFieldValue('site');

    return (
      <div className="form-block">
        <DistrictTransferField
          form={form}
          instance={instance}
          name="districts"
          label="Районы"
          required={false}
          errorData={errorData}
          activeFilter={{ city__site: siteId }}
        />
      </div>
    );
  }
}
