import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SEO_TEMPLATE_PHOTO_LIST_LOAD = createRequestTypes('SEO_TEMPLATE_PHOTO_LIST_LOAD');
export const SEO_TEMPLATE_PHOTO_DETAIL_LOAD = createRequestTypes('SEO_TEMPLATE_PHOTO_DETAIL_LOAD');
export const SEO_TEMPLATE_PHOTO_UPDATE = createRequestTypes('SEO_TEMPLATE_PHOTO_UPDATE');
export const SEO_TEMPLATE_PHOTO_DELETE = createRequestTypes('SEO_TEMPLATE_PHOTO_DELETE');
export const SEO_TEMPLATE_PHOTO_SHOW_EDIT_FORM = 'SEO_TEMPLATE_PHOTO_SHOW_EDIT_FORM';

export const seoTemplatePhotoShowEditForm = createAction(SEO_TEMPLATE_PHOTO_SHOW_EDIT_FORM);

export const seoTemplatePhotoListLoad = createAction(SEO_TEMPLATE_PHOTO_LIST_LOAD.START);
export const seoTemplatePhotoListLoadRequest = createAction(SEO_TEMPLATE_PHOTO_LIST_LOAD.REQUEST);
export const seoTemplatePhotoListLoadComplete = createAction(SEO_TEMPLATE_PHOTO_LIST_LOAD.COMPLETE);
export const seoTemplatePhotoListLoadError = createAction(SEO_TEMPLATE_PHOTO_LIST_LOAD.ERROR);

export const seoTemplatePhotoDetailLoad = createAction(SEO_TEMPLATE_PHOTO_DETAIL_LOAD.START);
export const seoTemplatePhotoDetailLoadRequest = createAction(SEO_TEMPLATE_PHOTO_DETAIL_LOAD.REQUEST);
export const seoTemplatePhotoDetailLoadComplete = createAction(SEO_TEMPLATE_PHOTO_DETAIL_LOAD.COMPLETE);
export const seoTemplatePhotoDetailLoadError = createAction(SEO_TEMPLATE_PHOTO_DETAIL_LOAD.ERROR);

export const seoTemplatePhotoUpdate = createAction(SEO_TEMPLATE_PHOTO_UPDATE.START);
export const seoTemplatePhotoUpdateRequest = createAction(SEO_TEMPLATE_PHOTO_UPDATE.REQUEST);
export const seoTemplatePhotoUpdateComplete = createAction(SEO_TEMPLATE_PHOTO_UPDATE.COMPLETE);
export const seoTemplatePhotoUpdateError = createAction(SEO_TEMPLATE_PHOTO_UPDATE.ERROR);

export const seoTemplatePhotoDelete = createAction(SEO_TEMPLATE_PHOTO_DELETE.START);
export const seoTemplatePhotoDeleteRequest = createAction(SEO_TEMPLATE_PHOTO_DELETE.REQUEST);
export const seoTemplatePhotoDeleteComplete = createAction(SEO_TEMPLATE_PHOTO_DELETE.COMPLETE);
export const seoTemplatePhotoDeleteError = createAction(SEO_TEMPLATE_PHOTO_DELETE.ERROR);
