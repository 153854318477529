import React, { PureComponent } from 'react';

import { getUrl } from 'utils';
import styles from './photo.module.css';


export default class Photo extends PureComponent {
    render() {
        const { url, width, height } = this.props;

        return (
            <div
                className={styles.photo}
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    backgroundImage: url ? `url(${getUrl(url)})`: 'none',
                }}
            />
        );
    }
}
