import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { BalanceList } from 'components/blocks/Balance';


class BalanceListScreen extends Component {
  render() {
    const { history } = this.props;

    return (
      <Page>
        <PageHeader title="Балансы пользователей"/>

        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            <BalanceList history={history}/>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    balance: state.balance,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.balance), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceListScreen);
