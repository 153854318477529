import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { TransactionList } from 'components/blocks/Transaction';


class TransactionListScreen extends Component {
  render() {
    const { history } = this.props;

    return (
      <Page>
        <PageHeader title="Транзакции"/>

        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            <TransactionList history={history}/>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    transaction: state.transaction,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.transaction), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionListScreen);
