import React, { Component } from 'react';
import { Input, DatePicker, Col, Row } from "antd";

import { parseDate } from 'utils';
import { FormItem } from 'utils';


export default class MainFieldSet extends Component {
  render() {
    const { form, review } = this.props;
    const { updateStatus, editObject } = review;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    return (
      <Row gutter={16}>
        <Col span={24}>
          <div className="form-block">
            <FormItem
              form={form}
              instance={instance}
              name="date"
              label="Дата"
              required={true}
              errorData={errorData}
              convertValue={value => {
                return parseDate(value, 'YYYY-MM-DD');
              }}
            >
              <DatePicker/>
            </FormItem>
          </div>
        </Col>

        <Col span={24}>
          <div className="form-block">
            <FormItem
              form={form}
              instance={instance}
              name="name"
              label="Имя"
              required={true}
              errorData={errorData}
            >
              <Input size="large"/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="text"
              label="Отзыв"
              required={true}
              errorData={errorData}
            >
              <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
            </FormItem>
          </div>
        </Col>
      </Row>
    );
  }
}
