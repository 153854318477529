import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as balanceActions from '../balance/actions';
import * as transactionActions from '../transaction/actions';
import * as api from 'store/api/refill';


export function* watch() {
  yield takeEvery(actions.REFILL_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.REFILL_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.REFILL_UPDATE.START, actionUpdate);
}


function* actionListLoad(action) {
  yield base.Request(action, api.refillListLoad,
    {
      request: actions.refillListLoadRequest,
      complete: actions.refillListLoadComplete,
      error: actions.refillListLoadError
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.refillDetailLoad,
    {
      request: actions.refillDetailLoadRequest,
      complete: actions.refillDetailLoadComplete,
      error: actions.refillDetailLoadError
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.refillUpdate,
    {
      request: actions.refillUpdateRequest,
      complete: actions.refillUpdateComplete,
      error: actions.refillUpdateError
    },
    function* (data) {
      yield put(actions.refillShowEditForm({ show: false }));
      yield put(balanceActions.balanceDetailLoad({ id: data.balance }));

      const filter = yield select((state) => state.transaction.filter);
      yield put(transactionActions.transactionListLoad(filter));
      yield put(push(`/balance/${data.balance}/transaction`));
    }
  );
}
