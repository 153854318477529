import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { UserList, UserForm } from 'components/blocks/User';


class UserListScreen extends Component {
  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.userShowEditForm({ show: true, object: item });
  };

  render() {
    const { history } = this.props;

    return (
      <Page>
        <PageHeader
          title="Пользователи"
          actions={[
            <Button type="primary" className="" icon="plus" onClick={() => this.showEditForm()}>
              Добавить пользователя
            </Button>
          ]}
        />

        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            <UserList history={history}/>
          </div>
        </div>

        <UserForm/>
      </Page>
    );
  }
}


function mapStateToProps(state) {
  return {
    user: state.user,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.user), dispatch),
    dispatch: dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserListScreen);
