import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { OnOff, FieldValue } from "components/common";


class BalanceMainScreen extends Component {
  render() {
    const { balance } = this.props;
    const detail = balance.detail || {};
    const user = detail.user || {};

    return (
      <div className="page-content page-content-full">
        <Row gutter={16}>
          <Col span={15}>
            <div className="page-block">
              <FieldValue label="Пользователь" labelWidth={6}>
                {user.email}
              </FieldValue>

              <FieldValue label="Баланс" labelWidth={6}>
                {detail.balance}
              </FieldValue>

              <FieldValue label="Вести учёт" labelWidth={6}>
                <OnOff
                  object={detail}
                  param="is_charging"
                  loading={balance.updateStatus.isLoading}
                  onChange={data => this.props.actions.balanceUpdate(data)}
                />
              </FieldValue>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    balance: state.balance,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.balance), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceMainScreen);
