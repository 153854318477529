import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { DistrictList, DistrictForm } from 'components/blocks/District';


class CityDistrictScreen extends Component {
  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.districtShowEditForm({ show: true, object: item });
  };

  render() {
    const { history, match } = this.props;
    const cityId = parseInt(match.params.id);

    return (
      <Fragment>
        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            <DistrictList
              basePath={`/city/${cityId}`}
              history={history}
              activeFilter={{ city: cityId }}
              topActions={[
                <Button type="primary" className="" icon="plus" onClick={() => this.showEditForm()}>
                  Добавить район
                </Button>
              ]}
            />
          </div>
        </div>

        <DistrictForm cityId={cityId}/>
      </Fragment>
    );
  }
}


function mapStateToProps(state) {
  return {
    city: state.city,
    district: state.district,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.district), dispatch),
    dispatch: dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(CityDistrictScreen);
