import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Input } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import getSlug from 'speakingurl';

import { actions } from "store";
import { FormItem } from 'utils/form_helpers';
import { Error } from 'components/common';
import SeoTemplatePartsDropdown, { renderTpl } from '../SeoTemplatePartsDropdown';


class SeoTemplatePageForm extends Component {
  state = {
    extra: {},
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, seo_template_page, type, siteId } = this.props;
        const instance = seo_template_page.editObject;

        let data = Object.assign({ type: type, site: siteId }, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        actions.seoTemplatePageUpdate(data);
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.seoTemplatePageShowEditForm({ show: false });
  };

  partAdd = (fieldName, part) => {
    const { form } = this.props;
    const oldValue = form.getFieldValue(fieldName) || '';
    const newValue = oldValue + part.value;
    form.setFieldsValue({
      [fieldName]: newValue,
    });
    this.updateExtra(fieldName, newValue);
  };

  updateExtra = (fieldName, value) => {
    const newExtra = Object.assign({}, this.state.extra);
    newExtra[fieldName] = renderTpl(value, this.props.type);
    if (fieldName === 'tpl_path') {
      newExtra[fieldName] = getSlug(newExtra[fieldName]);
    }
    this.setState({
      extra: newExtra,
    });
  };

  render() {
    const { form, seo_template_page, type } = this.props;
    const { showEditForm, updateStatus, editObject } = seo_template_page;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = seo_template_page.updateStatus.isLoading;

    const { extra } = this.state;

    return (
      <Modal
        width={960}
        visible={showEditForm}
        title="Редактирование seo-шаблона"
        onCancel={this.close}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit} onChange={e => this.updateExtra(e.target.id, e.target.value)}>
            <Error errors={errorData.non_field_errors}/>

            <FormItem
              form={form}
              instance={instance}
              name="tpl_path"
              label="Путь"
              required={true}
              errorData={errorData}
              extra={extra.tpl_path}
            >
              <Input
                size="large"
                style={{ width: '100%' }}
                addonAfter={
                  <SeoTemplatePartsDropdown
                    type={type}
                    onChange={(part) => this.partAdd('tpl_path', part)}
                  />
                }
              />
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="tpl_title"
              label="Тайтл"
              required={true}
              errorData={errorData}
              extra={extra.tpl_title}
            >
              <Input
                size="large"
                style={{ width: '100%' }}
                addonAfter={
                  <SeoTemplatePartsDropdown
                    type={type}
                    onChange={(part) => this.partAdd('tpl_title', part)}
                  />
                }
              />
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="tpl_description"
              label={
                <span>
                                    <span>Description</span>
                                    <span>&nbsp;&nbsp;</span>
                                    <SeoTemplatePartsDropdown
                                      type={type}
                                      onChange={(part) => this.partAdd('tpl_description', part)}
                                    />
                                </span>
              }
              required={true}
              errorData={errorData}
              extra={extra.tpl_description}
            >
              <Input.TextArea size="large" rows={5}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="tpl_h1"
              label="H1"
              required={true}
              errorData={errorData}
              extra={extra.tpl_h1}
            >
              <Input
                size="large"
                style={{ width: '100%' }}
                addonAfter={
                  <SeoTemplatePartsDropdown
                    type={type}
                    onChange={(part) => this.partAdd('tpl_h1', part)}
                  />
                }
              />
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="tpl_h2"
              label="H2"
              required={false}
              errorData={errorData}
              extra={extra.tpl_h2}
            >
              <Input
                size="large"
                style={{ width: '100%' }}
                addonAfter={
                  <SeoTemplatePartsDropdown
                    type={type}
                    onChange={(part) => this.partAdd('tpl_h2', part)}
                  />
                }
              />
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="tpl_text"
              label={
                <span>
                    <span>Текст</span>
                    <span>&nbsp;&nbsp;</span>
                    <SeoTemplatePartsDropdown
                      type={type}
                      onChange={(part) => this.partAdd('tpl_text', part)}
                    />
                </span>
              }
              required={false}
              errorData={errorData}
              extra={extra.tpl_text}
            >
              <Input.TextArea size="large" rows={10} autosize={{ minRows: 10 }}/>
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(SeoTemplatePageForm);

function mapStateToProps(state) {
  return {
    seo_template_page: state.seo_template_page,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.seo_template_page), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);

