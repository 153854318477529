import { BASE_URL } from 'config';

export function getUrl(url) {
    if (url && url.startsWith('/'))
        return BASE_URL + url;
    return url;
}


export function getHeaders(token = null) {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
    if (token) {
        headers['Authorization'] = `JWT ${token}`;
    }
    return headers;
}
