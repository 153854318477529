import React, { Component } from 'react';
import { Col, Input, Row } from "antd";

import { FormItem } from "utils";
import { PhotoUpload } from "components/common";
import { CitySelect } from 'components/blocks/City';
import { DomainSelect } from 'components/blocks/Domain';


export default class MainFieldSet extends Component {
  render() {
    const { form, instance, errorData } = this.props;

    return (
      <Row gutter={32}>
        <Col span={16}>
          <div className="form-block">
            <DomainSelect
              form={form}
              instance={instance}
              name="domain"
              label="Домен"
              required={true}
              errorData={errorData}
            />

            <FormItem
              form={form}
              instance={instance}
              name="hidden_domain"
              label="Скрытый домен"
              required={false}
              errorData={errorData}

            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <CitySelect
              form={form}
              instance={instance}
              name="city"
              label="Город"
              required={true}
              errorData={errorData}
            />

            <FormItem
              form={form}
              instance={instance}
              name="name"
              label="Название"
              required={true}
              errorData={errorData}

            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>
          </div>

          <div className="form-block">
            <FormItem
              form={form}
              instance={instance}
              name="profile_path"
              label="Путь к анкетам"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="sauna_path"
              label="Путь к саунам"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="salon_path"
              label="Путь к салонам"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="job_path"
              label="Путь к вакансиям"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="article_path"
              label="Путь к статьям"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="service_path"
              label="Путь к услугам"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="district_path"
              label="Путь к районам"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="ethnicity_path"
              label="Путь к внешностям"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>
          </div>
        </Col>

        <Col span={8}>
          <div className="form-block">
            <Row gutter={4}>
              <Col span={9}>
                <FormItem
                  form={form}
                  instance={instance}
                  name="config.logo_title"
                  label="Лого 1"
                  required={false}
                  errorData={errorData}
                >
                  <Input size="large" style={{ width: '100%' }}/>
                </FormItem>
              </Col>
              <Col span={9}>
                <FormItem
                  form={form}
                  instance={instance}
                  name="config.logo_title2"
                  label="Лого 2"
                  required={false}
                  errorData={errorData}
                >
                  <Input size="large" style={{ width: '100%' }}/>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  form={form}
                  instance={instance}
                  name="config.logo_suffix"
                  label="суффикс"
                  required={false}
                  errorData={errorData}
                >
                  <Input size="large" style={{ width: '100%' }}/>
                </FormItem>
              </Col>
            </Row>
          </div>

          <div className="form-block">
            <FormItem
              form={form}
              instance={instance}
              name="config.logo"
              label="Логотип"
              required={false}
              errorData={errorData}
              valuePropName="fileList"
            >
              <PhotoUpload
                mode="single"
                title="Загрузить логотип"
              />
            </FormItem>
          </div>

          <div className="form-block">
            <FormItem
              form={form}
              instance={instance}
              name="config.favicon"
              label="favicon"
              required={false}
              errorData={errorData}
              valuePropName="fileList"
            >
              <PhotoUpload
                mode="single"
                title="Загрузить favicon"
              />
            </FormItem>
          </div>
        </Col>
      </Row>
    );
  }
}
