import { takeEvery, select, put } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/review';


export function* watch() {
  yield takeEvery(actions.REVIEW_LIST_LOAD.START, actionReviewListLoad);
  yield takeEvery(actions.REVIEW_DETAIL_LOAD.START, actionReviewDetailLoad);
  yield takeEvery(actions.REVIEW_UPDATE.START, actionReviewUpdate);
  yield takeEvery(actions.REVIEW_DELETE.START, actionReviewDelete);
}

function* actionReviewListLoad(action) {
  yield base.Request(action, api.reviewListLoad,
    {
      request: actions.reviewListLoadRequest,
      complete: actions.reviewListLoadComplete,
      error: actions.reviewListLoadError
    },
  );
}

function* actionReviewDetailLoad(action) {
  yield base.Request(action, api.reviewDetailLoad,
    {
      request: actions.reviewDetailLoadRequest,
      complete: actions.reviewDetailLoadComplete,
      error: actions.reviewDetailLoadError
    },
  );
}

function* actionReviewUpdate(action) {
  yield base.Request(action, api.reviewUpdate,
    {
      request: actions.reviewUpdateRequest,
      complete: actions.reviewUpdateComplete,
      error: actions.reviewUpdateError,
      showEditForm: actions.reviewShowEditForm,
      detailLoad: actions.reviewDetailLoad,
      listLoad: actions.reviewListLoad,
    },
    function* (data) {
      yield put(actions.reviewShowEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(actions.reviewDetailLoad({ id: data.id }));
        yield put(push(`/review/${data.id}`));
      } else {
        const filter = yield select((state) => state.review.filter);
        yield put(actions.reviewListLoad(filter));
      }
    }
  );
}

function* actionReviewDelete(action) {
  yield base.Request(action, api.reviewDelete,
    {
      request: actions.reviewDeleteRequest,
      complete: actions.reviewDeleteComplete,
      error: actions.reviewDeleteError,
      listLoad: actions.reviewListLoad,
    },
    function* (data) {
      const filter = yield select((state) => state.review.filter);
      yield put(actions.reviewListLoad(filter));
    }
  );
}
