import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { userDetailLoad } from "store/api/user";
import { DataLoadComponent } from 'components/common';


class UserValue extends DataLoadComponent {
  apiDetailLoad = userDetailLoad;

  render() {
    const { id, asLink, state } = this.props;
    const { detail, loadStatus } = this.state;
    const value = (detail && detail.email) || id;
    const _id = (detail && detail.id) || id;

    return (
      <Spin spinning={loadStatus.isLoading}>
        {asLink && _id ?
          <Link to={{ pathname: `/user/${_id}`, state: state }}>{value}</Link> :
          <span>{value}</span>}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(UserValue);
