import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.pageShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.pageListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.pageListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.pageListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.pageDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.pageDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.pageDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.pageUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.pageUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.pageUpdateError]: (state, action) => base.updateError(state, action),

    [actions.pageDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.pageDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.pageDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
