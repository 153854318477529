import React from 'react';
import { Col, Row } from "antd";

import './styles.css';


const FieldValue = props => {
  const labelWidth = props.labelWidth || 5;
  return (
    <Row className="fieldValue">
      <Col span={labelWidth}>
        <span className="fieldValueLabel">{props.label}:</span>
      </Col>
      <Col span={24 - labelWidth}>{props.children}</Col>
    </Row>
  )
};

export default FieldValue;
