import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/profile_job';


export function* watch() {
  yield takeEvery(actions.JOB_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.JOB_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.JOB_UPDATE.START, actionUpdate);
  yield takeEvery(actions.JOB_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.jobListLoad,
    {
      request: actions.jobListLoadRequest,
      complete: actions.jobListLoadComplete,
      error: actions.jobListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.jobDetailLoad,
    {
      request: actions.jobDetailLoadRequest,
      complete: actions.jobDetailLoadComplete,
      error: actions.jobDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.jobUpdate,
    {
      request: actions.jobUpdateRequest,
      complete: actions.jobUpdateComplete,
      error: actions.jobUpdateError,
    },
    function* (data) {
      yield put(actions.jobShowEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(actions.jobDetailLoad({ id: data.id }));
        yield put(push(`/job/${data.id}`));
      } else {
        const filter = yield select((state) => state.job.filter);
        yield put(actions.jobListLoad(filter));
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.jobDelete,
    {
      request: actions.jobDeleteRequest,
      complete: actions.jobDeleteComplete,
      error: actions.jobDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.job.filter);
      yield put(actions.jobListLoad(filter));
    }
  );
}
