import { put, takeEvery, select } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/seo_template_photo';


export function* watch() {
  yield takeEvery(actions.SEO_TEMPLATE_PHOTO_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.SEO_TEMPLATE_PHOTO_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.SEO_TEMPLATE_PHOTO_UPDATE.START, actionUpdate);
  yield takeEvery(actions.SEO_TEMPLATE_PHOTO_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.seoTemplatePhotoListLoad,
    {
      request: actions.seoTemplatePhotoListLoadRequest,
      complete: actions.seoTemplatePhotoListLoadComplete,
      error: actions.seoTemplatePhotoListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.seoTemplatePhotoDetailLoad,
    {
      request: actions.seoTemplatePhotoDetailLoadRequest,
      complete: actions.seoTemplatePhotoDetailLoadComplete,
      error: actions.seoTemplatePhotoDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.seoTemplatePhotoUpdate,
    {
      request: actions.seoTemplatePhotoUpdateRequest,
      complete: actions.seoTemplatePhotoUpdateComplete,
      error: actions.seoTemplatePhotoUpdateError,
    },
    function* (data) {
      yield put(actions.seoTemplatePhotoShowEditForm({ show: false }));
      const filter = yield select((state) => state.seo_template_photo.filter);
      yield put(actions.seoTemplatePhotoListLoad(filter));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.seoTemplatePhotoDelete,
    {
      request: actions.seoTemplatePhotoDeleteRequest,
      complete: actions.seoTemplatePhotoDeleteComplete,
      error: actions.seoTemplatePhotoDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.seo_template_photo.filter);
      yield put(actions.seoTemplatePhotoListLoad(filter));
    }
  );
}
