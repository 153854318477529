import { handleActions } from 'redux-actions';
import { loadStates, loadStatus } from 'utils/redux';

import * as actions from './actions';


const initialState = {
    token: null,
    isAuthenticated: false,
    loginStatus: loadStatus(loadStates.notAsked),
};

export default handleActions(
    {
        [actions.authLogin]: (state, action) => ({
            ...state,
            loginStatus: loadStatus(loadStates.loading),
        }),
        [actions.authLoginComplete]: (state, action) => ({
            ...state,
            loginStatus: loadStatus(loadStates.loaded),
            token: action.payload.token,
            isAuthenticated: true,
        }),
        [actions.authLoginError]: (state, action) => ({
            ...state,
            loginStatus: loadStatus(loadStates.failed, action.payload),
        }),
        [actions.authLogout]: (state, action) => ({
            ...state,
            ...initialState
        }),
    },
    initialState
);
