import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SLIDER_LIST_LOAD = createRequestTypes('SLIDER_LIST_LOAD');
export const SLIDER_DETAIL_LOAD = createRequestTypes('SLIDER_DETAIL_LOAD');
export const SLIDER_UPDATE = createRequestTypes('SLIDER_UPDATE');
export const SLIDER_DELETE = createRequestTypes('SLIDER_DELETE');
export const SLIDER_SHOW_EDIT_FORM = 'SLIDER_SHOW_EDIT_FORM';

export const sliderShowEditForm = createAction(SLIDER_SHOW_EDIT_FORM);

export const sliderListLoad = createAction(SLIDER_LIST_LOAD.START);
export const sliderListLoadRequest = createAction(SLIDER_LIST_LOAD.REQUEST);
export const sliderListLoadComplete = createAction(SLIDER_LIST_LOAD.COMPLETE);
export const sliderListLoadError = createAction(SLIDER_LIST_LOAD.ERROR);

export const sliderDetailLoad = createAction(SLIDER_DETAIL_LOAD.START);
export const sliderDetailLoadRequest = createAction(SLIDER_DETAIL_LOAD.REQUEST);
export const sliderDetailLoadComplete = createAction(SLIDER_DETAIL_LOAD.COMPLETE);
export const sliderDetailLoadError = createAction(SLIDER_DETAIL_LOAD.ERROR);

export const sliderUpdate = createAction(SLIDER_UPDATE.START);
export const sliderUpdateRequest = createAction(SLIDER_UPDATE.REQUEST);
export const sliderUpdateComplete = createAction(SLIDER_UPDATE.COMPLETE);
export const sliderUpdateError = createAction(SLIDER_UPDATE.ERROR);

export const sliderDelete = createAction(SLIDER_DELETE.START);
export const sliderDeleteRequest = createAction(SLIDER_DELETE.REQUEST);
export const sliderDeleteComplete = createAction(SLIDER_DELETE.COMPLETE);
export const sliderDeleteError = createAction(SLIDER_DELETE.ERROR);
