import React, { Component } from 'react';
import { Checkbox } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { FormItem, getObject, getObjectList } from "utils";
import styles from './form.module.css';


class ServiceCheckboxGroup extends Component {
    componentDidMount() {
        const { actions, service_group, service } = this.props;
        if (service_group.loadStatus.notAsked) {
            actions.serviceGroupLoad({ query: '' });
        }
        if (service.loadStatus.notAsked || service.query !== '') {
            actions.serviceLoad({ query: '' });
        }
    }

    render() {
        const { service_group, service, form, instance, name, label, required, errorData, initialValue } = this.props;

        return (
            <FormItem
                form={form}
                instance={instance}
                name={name}
                label={label}
                required={required}
                errorData={errorData}
                initialValue={initialValue}
            >
                <Checkbox.Group>
                    {service_group.list.map(group_id => {
                        const group = getObject(service_group, group_id);
                        const services = getObjectList(service).filter(s => s.group === group_id);
                        return (
                            <div key={`sg${group_id}`} className={styles.group}>
                                <div className={styles.groupTitle}>{group.name}</div>
                                <div className={styles.groupBody}>
                                    {services.map(s => (
                                        <Checkbox className="ant-checkbox-group-item" key={`s${s.id}`} value={s.id}>
                                            {s.name}
                                        </Checkbox>
                                    ))}
                                </div>
                            </div>
                        )
                    })}
                </Checkbox.Group>
            </FormItem>
        );
    }
}


function mapStateToProps(state) {
    return {
        service_group: state.service_group,
        service: state.service,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.service_group, actions.service), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCheckboxGroup);
