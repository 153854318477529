import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Input, Col, Row } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { FormItem } from 'utils/form_helpers';
import { Error, PhotoUpload } from 'components/common';


class ArticleForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, article, siteId } = this.props;
        const instance = article.editObject;

        let data = Object.assign({ site: siteId }, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        if (data.image) {
          data.image = data.image.id;
        }
        console.log(data);

        actions.articleUpdate(data);
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.articleShowEditForm({ show: false });
  };

  render() {
    const { form, article } = this.props;
    const { showEditForm, updateStatus, editObject } = article;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = article.updateStatus.isLoading;

    return (
      <Modal
        width={640}
        visible={showEditForm}
        title="Редактирование статьи"
        onCancel={this.close}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit}>
            <Error errors={errorData.non_field_errors}/>

            <Row gutter={32}>
              <Col span={17}>
                <div className="form-block">
                  <FormItem
                    form={form}
                    instance={instance}
                    name="title"
                    label="Заголовок"
                    required={true}
                    errorData={errorData}
                  >
                    <Input size="large" style={{ width: '100%' }}/>
                  </FormItem>

                  <FormItem
                    form={form}
                    instance={instance}
                    name="slug"
                    label="Путь"
                    required={true}
                    errorData={errorData}
                  >
                    <Input size="large" style={{ width: '100%' }}/>
                  </FormItem>


                  <FormItem
                    form={form}
                    instance={instance}
                    name="teaser"
                    label="Анонс"
                    required={false}
                    errorData={errorData}
                  >
                    <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
                  </FormItem>

                  <FormItem
                    form={form}
                    instance={instance}
                    name="text"
                    label="Текст"
                    required={false}
                    errorData={errorData}
                  >
                    <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
                  </FormItem>
                </div>
              </Col>

              <Col span={7}>
                <FormItem
                  form={form}
                  instance={instance}
                  name="image"
                  label="Картинка"
                  required={false}
                  errorData={errorData}
                  valuePropName="fileList"
                >
                  <PhotoUpload
                    mode="single"
                    title="Загрузить картинку"
                  />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    );
  }
}


const WrappedForm = Form.create()(ArticleForm);

function mapStateToProps(state) {
  return {
    article: state.article,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.article), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);

