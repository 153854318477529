import React from 'react';
import { withRouter } from 'react-router-dom';
import './styles.css';


function Page({ children }) {
    return (
        <section className="page">
            <div className="page-inner">
                {children}
            </div>
        </section>
    );
}


export default withRouter(Page);