import React, { Component, Fragment } from 'react';
import { Button, Tabs } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import { actions } from "store";
import { getUrlParams } from "utils";
import { SeoTemplatePageList, SeoTemplatePageForm } from 'components/blocks/seo_template_page';
import { SeoTemplatePhotoList, SeoTemplatePhotoForm } from 'components/blocks/seo_template_photo';


class PageListScreen extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { match, location } = this.props;
    if (!location.search) {
      const site_id = match.params.id;
      let url = `/site/${site_id}/seo_template_page?type=profile`;
      this.props.history.push(url);
    }
  }

  onTabChange = (key) => {
    const { match } = this.props;
    const site_id = match.params.id;
    let url = `/site/${site_id}/seo_template_page`;
    url += `?type=${key}`;
    this.props.history.push(url);
  };

  renderTab = (title, key) => {
    const info = this.props.seo_template_page.info || {};
    const count = info[key];
    return (<div>{title} {count && <span>{count}</span>}</div>)
  };

  showSeoTemplatePhotoForm = (item = null) => {
    const { actions } = this.props;
    actions.seoTemplatePhotoShowEditForm({ show: true, object: item });
  };

  showSeoTemplatePageForm = (item = null) => {
    const { actions } = this.props;
    actions.seoTemplatePageShowEditForm({ show: true, object: item });
  };

  render() {
    const { site, history, location } = this.props;
    const detail = site.detail;
    const params = getUrlParams(location.search);
    const tabKey = (params && params.type) || 'profile';

    return (
      <Fragment>
        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            <div className="page-block-header">
              <div className="page-block-header-start">
                <Tabs className="page-header-tabs" activeKey={tabKey} onChange={this.onTabChange}>
                  <Tabs.TabPane tab={this.renderTab('Шаблоны анкет', 'profile')} key="profile"/>
                  <Tabs.TabPane tab={this.renderTab('Шаблоны картинок', 'photo')} key="photo"/>
                  <Tabs.TabPane tab={this.renderTab('Шаблоны услуг', 'service')} key="service"/>
                  <Tabs.TabPane tab={this.renderTab('Шаблоны районов', 'district')} key="district"/>
                  <Tabs.TabPane tab={this.renderTab('Шаблоны саун', 'profile')} key="sauna"/>
                  <Tabs.TabPane tab={this.renderTab('Шаблоны салонов', 'profile')} key="salon"/>
                  <Tabs.TabPane tab={this.renderTab('Шаблоны вакансий', 'profile')} key="job"/>
                </Tabs>
              </div>
            </div>
            <br/>

            {detail &&
            (tabKey === 'photo' ?
                <SeoTemplatePhotoList
                  history={history}
                  activeFilter={{ site: detail.id }}
                  topActions={[
                    <Button type="primary" className="" icon="plus" onClick={() => this.showSeoTemplatePhotoForm()}>
                      Добавить шаблон
                    </Button>
                  ]}
                />
                :
                <SeoTemplatePageList
                  history={history}
                  activeFilter={{ site: detail.id, type: tabKey }}
                  topActions={[
                    <Button type="primary" className="" icon="plus" onClick={() => this.showSeoTemplatePageForm()}>
                      Добавить шаблон
                    </Button>
                  ]}
                />
            )
            }
          </div>

          {detail &&
          <SeoTemplatePageForm siteId={detail.id} type={tabKey}/>}

          {detail &&
          <SeoTemplatePhotoForm siteId={detail.id} type={tabKey}/>}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    site: state.site,
    seo_template_page: state.seo_template_page,
    seo_template_photo: state.seo_template_photo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.seo_template_page, actions.seo_template_photo), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageListScreen);
