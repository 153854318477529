import { BASE_URL } from "config";

import * as base from './_base';


export const endpoints = {
    list: `${BASE_URL}/api/v1/service/`,
    detail: (id) => `${BASE_URL}/api/v1/service/${id}/`,
};

export const serviceListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const serviceDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const serviceUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const serviceDelete = (token, data) => base.Delete(endpoints.detail, token, data);
