import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { SiteForm, SiteList } from 'components/blocks/Site';


class CitySiteScreen extends Component {
    showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.siteShowEditForm({ show: true, object: item });
  };

  render() {
    const { history, match } = this.props;
    const cityId = parseInt(match.params.id);

    return (
      <Fragment>
        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            <SiteList
              history={history}
              activeFilter={{ city: cityId }}
              topActions={[
                <Button type="primary" className="" icon="plus" onClick={this.showEditForm}>
                  Добавить сайт
                </Button>
              ]}
            />
          </div>
        </div>

        <SiteForm cityId={cityId}/>
      </Fragment>
    );
  }
}


function mapStateToProps(state) {
  return {
    city: state.city,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.site), dispatch),
    dispatch: dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(CitySiteScreen);
