import { put, takeEvery, select } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/advert';


export function* watch() {
  yield takeEvery(actions.BANNER_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.BANNER_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.BANNER_UPDATE.START, actionUpdate);
  yield takeEvery(actions.BANNER_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.bannerListLoad,
    {
      request: actions.bannerListLoadRequest,
      complete: actions.bannerListLoadComplete,
      error: actions.bannerListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.bannerDetailLoad,
    {
      request: actions.bannerDetailLoadRequest,
      complete: actions.bannerDetailLoadComplete,
      error: actions.bannerDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.bannerUpdate,
    {
      request: actions.bannerUpdateRequest,
      complete: actions.bannerUpdateComplete,
      error: actions.bannerUpdateError,
    },
    function* (data) {
      yield put(actions.bannerShowEditForm({ show: false }));
      const filter = yield select((state) => state.banner.filter);
      yield put(actions.bannerListLoad(filter));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.bannerDelete,
    {
      request: actions.bannerDeleteRequest,
      complete: actions.bannerDeleteComplete,
      error: actions.bannerDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.banner.filter);
      yield put(actions.bannerListLoad(filter));
    }
  );
}
