import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ActionButton } from "components/common";
import { CityForm } from 'components/blocks/City';
import CityDistrictListScreen from './CityDistrictListScreen';
import CitySiteListScreen from './CitySiteListScreen';


class CityScreen extends Component {
  state = {
    id: null,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.cityDetailLoad({ id: match.params.id });
    this.setState({ id: match.params.id });
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.cityShowEditForm({ show: true, object: item });
  };

  delete = () => {
    const { actions } = this.props;
    const { id } = this.state;
    actions.cityDelete({ id });
  };

  render() {
    const { history, city } = this.props;
    const detail = city.detail || {};

    return (
      <Page>
        <PageHeader
          history={history}
          title={detail.name}
          actions={[
            <Button onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>,
            <ActionButton
              actions={[
                {
                  key: 'delete',
                  icon: <Icon type="delete"/>,
                  title: 'Удалить',
                  action: this.delete,
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}
            />
          ]}
          basePath={`/city/${this.state.id}`}
          tabs={[
            { title: 'Районы', key: 'detail' },
            { title: 'Сайты', key: 'sites' },
          ]}
        />

        <Route exact path="/city/:id" component={CityDistrictListScreen}/>
        <Route exact path="/city/:id/sites" component={CitySiteListScreen}/>

        <CityForm/>
      </Page>
    );
  }
}


function mapStateToProps(state) {
  return {
    city: state.city,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.city), dispatch),
    dispatch: dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(CityScreen);
