import { put, call, select } from 'redux-saga/effects';
import { replace } from "connected-react-router";


export function* Request(action, apiRequest, actions, callback = null) {
  const { payload } = action;
  const token = yield select((state) => state.auth.token);

  yield put(actions.request(payload));
  if (payload.locationChange) {
    yield put(replace({ search: payload.query }));
  }

  try {
    const { error, data, statusCode } = yield call(() => apiRequest(token, payload));

    if (error) {
      yield put(actions.error({ data, statusCode }));
    } else {
      yield put(actions.complete(data));
      if (callback) {
        yield callback(data);
      }
    }
  } catch (error) {
    console.log('Request Error', error);
    yield put(actions.error());
  }
}
