import React, { Component } from 'react';
import { Col, Row, Input } from "antd";

import { FormItem } from 'utils';


export default class ContactFieldSet extends Component {
  render() {
    const { form, job } = this.props;
    const { updateStatus, editObject } = job;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    return (
      <Row gutter={32}>
        <Col span={12}>
          <div className="form-block">
            <FormItem
              form={form}
              instance={instance}
              name="contacts.phone"
              label="Телефон"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="contacts.whatsapp"
              label="Whatsapp"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="contacts.telegram"
              label="Telegram"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="contacts.skype"
              label="Skype"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="contacts.viber"
              label="Viber"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>
          </div>
        </Col>
      </Row>
    );
  }
}
