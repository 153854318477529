import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const BANNER_LIST_LOAD = createRequestTypes('BANNER_LIST_LOAD');
export const BANNER_DETAIL_LOAD = createRequestTypes('BANNER_DETAIL_LOAD');
export const BANNER_UPDATE = createRequestTypes('BANNER_UPDATE');
export const BANNER_DELETE = createRequestTypes('BANNER_DELETE');
export const BANNER_SHOW_EDIT_FORM = 'BANNER_SHOW_EDIT_FORM';

export const bannerShowEditForm = createAction(BANNER_SHOW_EDIT_FORM);

export const bannerListLoad = createAction(BANNER_LIST_LOAD.START);
export const bannerListLoadRequest = createAction(BANNER_LIST_LOAD.REQUEST);
export const bannerListLoadComplete = createAction(BANNER_LIST_LOAD.COMPLETE);
export const bannerListLoadError = createAction(BANNER_LIST_LOAD.ERROR);

export const bannerDetailLoad = createAction(BANNER_DETAIL_LOAD.START);
export const bannerDetailLoadRequest = createAction(BANNER_DETAIL_LOAD.REQUEST);
export const bannerDetailLoadComplete = createAction(BANNER_DETAIL_LOAD.COMPLETE);
export const bannerDetailLoadError = createAction(BANNER_DETAIL_LOAD.ERROR);

export const bannerUpdate = createAction(BANNER_UPDATE.START);
export const bannerUpdateRequest = createAction(BANNER_UPDATE.REQUEST);
export const bannerUpdateComplete = createAction(BANNER_UPDATE.COMPLETE);
export const bannerUpdateError = createAction(BANNER_UPDATE.ERROR);

export const bannerDelete = createAction(BANNER_DELETE.START);
export const bannerDeleteRequest = createAction(BANNER_DELETE.REQUEST);
export const bannerDeleteComplete = createAction(BANNER_DELETE.COMPLETE);
export const bannerDeleteError = createAction(BANNER_DELETE.ERROR);
