import { put, call, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as actions from './actions';
import * as api from 'store/api/user';
import * as base from "../BaseSagas";


export function* watch() {
  yield takeEvery(actions.CURRENT_USER_LOAD.START, actionCurrentUserLoad);
  yield takeEvery(actions.USER_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.USER_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.USER_UPDATE.START, actionUpdate);
  yield takeEvery(actions.USER_DELETE.START, actionDelete);
}

function* actionCurrentUserLoad(action) {
  yield call(currentUserLoad, action.payload)
}

export function* currentUserLoad({ token }) {
  yield put(actions.currentUserLoadRequest());

  try {
    const { error, data, statusCode } = yield call(() => api.currentUserLoad({ token }));

    if (error) {
      yield put(actions.currentUserLoadError({ data, statusCode }));
    } else {
      yield put(actions.currentUserLoadComplete(data));
    }

  } catch (error) {
    yield put(actions.currentUserLoadError());
  }
}

function* actionListLoad(action) {
  yield base.Request(action, api.userListLoad,
    {
      request: actions.userListLoadRequest,
      complete: actions.userListLoadComplete,
      error: actions.userListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.userDetailLoad,
    {
      request: actions.userDetailLoadRequest,
      complete: actions.userDetailLoadComplete,
      error: actions.userDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.userUpdate,
    {
      request: actions.userUpdateRequest,
      complete: actions.userUpdateComplete,
      error: actions.userUpdateError,
    },
    function* (data) {
      yield put(actions.userShowEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(actions.userDetailLoad({ id: data.id }));
        yield put(push(`/user/${data.id}`));
      } else {
        const filter = yield select((state) => state.user.filter);
        yield put(actions.userListLoad(filter));
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.userDelete,
    {
      request: actions.userDeleteRequest,
      complete: actions.userDeleteComplete,
      error: actions.userDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.user.filter);
      yield put(actions.userListLoad(filter));
      yield put(push(`/user`));
    }
  );
}
