import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const ARTICLE_LIST_LOAD = createRequestTypes('ARTICLE_LIST_LOAD');
export const ARTICLE_DETAIL_LOAD = createRequestTypes('ARTICLE_DETAIL_LOAD');
export const ARTICLE_UPDATE = createRequestTypes('ARTICLE_UPDATE');
export const ARTICLE_DELETE = createRequestTypes('ARTICLE_DELETE');
export const ARTICLE_SHOW_EDIT_FORM = 'ARTICLE_SHOW_EDIT_FORM';

export const articleShowEditForm = createAction(ARTICLE_SHOW_EDIT_FORM);

export const articleListLoad = createAction(ARTICLE_LIST_LOAD.START);
export const articleListLoadRequest = createAction(ARTICLE_LIST_LOAD.REQUEST);
export const articleListLoadComplete = createAction(ARTICLE_LIST_LOAD.COMPLETE);
export const articleListLoadError = createAction(ARTICLE_LIST_LOAD.ERROR);

export const articleDetailLoad = createAction(ARTICLE_DETAIL_LOAD.START);
export const articleDetailLoadRequest = createAction(ARTICLE_DETAIL_LOAD.REQUEST);
export const articleDetailLoadComplete = createAction(ARTICLE_DETAIL_LOAD.COMPLETE);
export const articleDetailLoadError = createAction(ARTICLE_DETAIL_LOAD.ERROR);

export const articleUpdate = createAction(ARTICLE_UPDATE.START);
export const articleUpdateRequest = createAction(ARTICLE_UPDATE.REQUEST);
export const articleUpdateComplete = createAction(ARTICLE_UPDATE.COMPLETE);
export const articleUpdateError = createAction(ARTICLE_UPDATE.ERROR);

export const articleDelete = createAction(ARTICLE_DELETE.START);
export const articleDeleteRequest = createAction(ARTICLE_DELETE.REQUEST);
export const articleDeleteComplete = createAction(ARTICLE_DELETE.COMPLETE);
export const articleDeleteError = createAction(ARTICLE_DELETE.ERROR);
