import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { domainDetailLoad } from "store/api/domain";
import { DataLoadComponent } from 'components/common';


class DomainValue extends DataLoadComponent {
  apiDetailLoad = domainDetailLoad;

  render() {
    const { id, asLink, state } = this.props;
    const { detail, loadStatus } = this.state;
    const value = (detail && detail.domain) || id;
    let _id = id;
    if (detail && detail.parent) {
      _id = detail.parent;
    } else if (detail && detail.id) {
      _id = detail.id;
    }

    return (
      <Spin spinning={loadStatus.isLoading} wrapperClassName="spin-inline">
        {asLink && _id ?
          <Link to={{ pathname: `/domain/${_id}`, state: state }}>{value}</Link> :
          <span>{value}</span>}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(DomainValue);
