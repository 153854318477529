import React, { Component } from 'react';
import { Col, Row, Input, Select } from "antd";

import { FormItem } from 'utils';


export default class ParametersFieldSet extends Component {
  render() {
    const { form, profile } = this.props;
    const { updateStatus, editObject } = profile;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    return (
      <div className="form-block">
        <Row gutter={16}>
          <Col span={8}>
            <FormItem
              form={form}
              instance={instance}
              name="parameters.age"
              label="Возраст"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="parameters.weight"
              label="Вес"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="parameters.height"
              label="Рост"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              form={form}
              instance={instance}
              name="parameters.gender"
              label="Пол"
              errorData={errorData}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Выберите"
              >
                <Select.Option value='female'>Женский</Select.Option>
                <Select.Option value='male'>Мужской</Select.Option>
                <Select.Option value='trans'>Транс</Select.Option>
              </Select>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="parameters.ethnicity"
              label="Тип внешности"
              errorData={errorData}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Выберите"
              >
                <Select.Option value='slavic'>Славянский</Select.Option>
                <Select.Option value='european'>Европейский</Select.Option>
                <Select.Option value='arabian'>Восточный</Select.Option>
                <Select.Option value='asian'>Азиатский</Select.Option>
                <Select.Option value='ebony'>Африканский</Select.Option>
                <Select.Option value='indian'>Индийский</Select.Option>
                <Select.Option value='caucasian'>Кавказский</Select.Option>
                <Select.Option value='latin'>Латинский</Select.Option>
                <Select.Option value='mixed'>Смешанный</Select.Option>
              </Select>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="parameters.hair_color"
              label="Цвет волос"
              errorData={errorData}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Выберите"
              >
                <Select.Option value='blonde'>Светлые</Select.Option>
                <Select.Option value='brown'>Каштановые</Select.Option>
                <Select.Option value='black'>Чёрные</Select.Option>
                <Select.Option value='red'>Красные</Select.Option>
                <Select.Option value='other'>Другие</Select.Option>
              </Select>
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem
              form={form}
              instance={instance}
              name="parameters.breast_size"
              label="Размер груди"
              errorData={errorData}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Выберите"
              >
                <Select.Option value='A'>1</Select.Option>
                <Select.Option value='B'>2</Select.Option>
                <Select.Option value='C'>3</Select.Option>
                <Select.Option value='D'>4</Select.Option>
                <Select.Option value='E'>5</Select.Option>
                <Select.Option value='F'>6</Select.Option>
                <Select.Option value='G'>6+</Select.Option>
              </Select>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="parameters.breast_type"
              label="Тип груди"
              errorData={errorData}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Выберите"
              >
                <Select.Option value='Natural'>Натуральная</Select.Option>
                <Select.Option value='Silicon'>Силиконовая</Select.Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}
