import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const DISTRICT_LIST_LOAD = createRequestTypes('DISTRICT_LIST_LOAD');
export const DISTRICT_DETAIL_LOAD = createRequestTypes('DISTRICT_DETAIL_LOAD');
export const DISTRICT_UPDATE = createRequestTypes('DISTRICT_UPDATE');
export const DISTRICT_DELETE = createRequestTypes('DISTRICT_DELETE');
export const DISTRICT_SHOW_EDIT_FORM = 'DISTRICT_SHOW_EDIT_FORM';
export const DISTRICT_SELECT = 'DISTRICT_SELECT';

export const districtShowEditForm = createAction(DISTRICT_SHOW_EDIT_FORM);
export const districtSelect = createAction(DISTRICT_SELECT);

export const districtListLoad = createAction(DISTRICT_LIST_LOAD.START);
export const districtListLoadRequest = createAction(DISTRICT_LIST_LOAD.REQUEST);
export const districtListLoadComplete = createAction(DISTRICT_LIST_LOAD.COMPLETE);
export const districtListLoadError = createAction(DISTRICT_LIST_LOAD.ERROR);

export const districtDetailLoad = createAction(DISTRICT_DETAIL_LOAD.START);
export const districtDetailLoadRequest = createAction(DISTRICT_DETAIL_LOAD.REQUEST);
export const districtDetailLoadComplete = createAction(DISTRICT_DETAIL_LOAD.COMPLETE);
export const districtDetailLoadError = createAction(DISTRICT_DETAIL_LOAD.ERROR);

export const districtUpdate = createAction(DISTRICT_UPDATE.START);
export const districtUpdateRequest = createAction(DISTRICT_UPDATE.REQUEST);
export const districtUpdateComplete = createAction(DISTRICT_UPDATE.COMPLETE);
export const districtUpdateError = createAction(DISTRICT_UPDATE.ERROR);

export const districtDelete = createAction(DISTRICT_DELETE.START);
export const districtDeleteRequest = createAction(DISTRICT_DELETE.REQUEST);
export const districtDeleteComplete = createAction(DISTRICT_DELETE.COMPLETE);
export const districtDeleteError = createAction(DISTRICT_DELETE.ERROR);
