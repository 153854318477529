import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';

import { serviceDetailLoad, serviceListLoad } from "store/api/service";
import { DataLoadComponent } from 'components/common';


class ServiceValue extends DataLoadComponent {
  apiListLoad = serviceListLoad;
  apiDetailLoad = serviceDetailLoad;

  renderDetail = (item) => {
    const value = (item && item.name) || '';
    return (
      <span>{value}</span>
    );
  };

  renderList = (items) => {
    return items.map((item, index) => (
      <span key={`service${item.id}`}>
        {index > 0 ? <span>, </span> : ''}
        {this.renderDetail(item)}
       </span>
    ))
  };

  render() {
    const { id } = this.props;
    const { detail, items, loadStatus } = this.state;

    return (
      <Spin spinning={loadStatus.isLoading}>
        {id && this.renderDetail(detail)}
        {items.length > 0 && this.renderList(items)}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(ServiceValue);
