import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { tableColumn } from "utils";
import { ActionButton, TableList } from "components/common";


class ServiceList extends Component {
  state = {
    columnList: ['name', 'sort', 'actions'],
    columnByName: {
      name: tableColumn('Название', 'name', {
        sorter: true,
      }),
      sort: tableColumn('Сортировка', 'sort', {
        width: 120,
        sorter: true,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  load = ({ query, activeFilter }) => {
    this.props.actions.serviceGroupListLoad({});
    this.props.actions.serviceListLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    if (item.children) {
      this.props.actions.serviceGroupShowEditForm({ show: true, object: item })
    } else {
      this.props.actions.serviceShowEditForm({ show: true, object: item })
    }
  };

  delete = (item) => {
    if (item.children) {
      this.props.actions.serviceGroupDelete({ id: item.id });
    } else {
      this.props.actions.serviceDelete({ id: item.id });
    }
  };

  render() {
    const { history, defaultFilter, activeFilter, service_group, service } = this.props;
    const { columnList, columnByName } = this.state;

    const dataSource = service_group.list.reduce((groups, groupId) => {
      const children = service.list.reduce((services, serviceId) => {
        const obj = Object.assign(service.byId[serviceId], {pk: `service${serviceId}`});
        if (obj.group === groupId) {
          services.push(obj);
        }
        return services;
      }, []);

      const obj = Object.assign(service_group.byId[groupId], {pk: `group${groupId}`, children: children});
      groups.push(obj);
      return groups;
    }, []);

    return (
      <TableList
        rowKey="pk"
        history={history}
        data={service_group}
        dataSource={dataSource}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
      />
    );
  }
}


function mapStateToProps(state) {
  return {
    service_group: state.service_group,
    service: state.service,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.service_group, actions.service), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);
