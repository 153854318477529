import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.serviceGroupShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.serviceGroupListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.serviceGroupListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.serviceGroupListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.serviceGroupDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.serviceGroupDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.serviceGroupDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.serviceGroupUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.serviceGroupUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.serviceGroupUpdateError]: (state, action) => base.updateError(state, action),

    [actions.serviceGroupDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.serviceGroupDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.serviceGroupDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
