import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ServiceGroupForm, ServiceForm, ServiceList } from 'components/blocks/Service';


class ServiceListScreen extends Component {
  showServiceGroupForm = (item = null) => {
    const { actions } = this.props;
    actions.serviceGroupShowEditForm({ show: true, object: item });
  };

  showServiceForm = (item = null) => {
    const { actions } = this.props;
    actions.serviceShowEditForm({ show: true, object: item });
  };

  render() {
    const { history } = this.props;

    return (
      <Page>
          <PageHeader
            title="Услуги"
            actions={[
                <Button type="primary" className="" icon="plus" onClick={() => this.showServiceGroupForm()}>
                    Добавить группу
                </Button>,
                <Button type="primary" className="" icon="plus" onClick={() => this.showServiceForm()}>
                    Добавить услугу
                </Button>
            ]}
          />

        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            <ServiceList history={history}/>
          </div>
        </div>

        <ServiceForm/>
        <ServiceGroupForm/>
      </Page>
    );
  }
}


function mapStateToProps(state) {
  return {
    service_group: state.service_group,
    service: state.service,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.service_group, actions.service), dispatch),
    dispatch: dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ServiceListScreen);
