import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { Layout } from 'antd';

import { Header, Navigation } from 'components/layout';
import DashboardScreen from 'screens/DashboardScreen';
import { CityListScreen, CityDetailScreen } from 'screens/City';
import { SiteListScreen, SiteDetailScreen } from 'screens/Site';
import { DomainListScreen, DomainDetailScreen } from 'screens/Domain';
import { ProfileListScreen, ProfileDetailScreen } from 'screens/Profile';
import { SaunaListScreen, SaunaDetailScreen } from 'screens/Sauna';
import { SalonListScreen, SalonDetailScreen } from 'screens/Salon';
import { JobListScreen, JobDetailScreen } from 'screens/Job';
import { ServiceListScreen } from 'screens/Service';
import { BalanceListScreen, BalanceDetailScreen } from 'screens/Balance';
import { TransactionListScreen } from 'screens/Transaction';
import { UserListScreen, UserDetailScreen } from 'screens/User';
import { ReviewListScreen, ReviewDetailScreen } from 'screens/Review';


export default class MainScreen extends Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { history } = this.props;

    return (
      <Layout>
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth="0"
          className="app-layout-sider"
        >
          <div className="logo"/>
          <Navigation history={history}/>
        </Layout.Sider>
        <Layout className="app-layout">
          <Header history={history} handleToggle={this.toggle}/>

          <Layout.Content className="app-layout-content">
            <Switch>
              <Route exact path="/" component={DashboardScreen}/>

              <Route exact path="/city" component={CityListScreen}/>
              <Route path="/city/:id" component={CityDetailScreen}/>

              <Route exact path="/site" component={SiteListScreen}/>
              <Route path="/site/:id" component={SiteDetailScreen}/>

              <Route exact path="/domain" component={DomainListScreen}/>
              <Route path="/domain/:id" component={DomainDetailScreen}/>

              <Route exact path="/service" component={ServiceListScreen}/>

              <Route exact path="/profile" component={ProfileListScreen}/>
              <Route path="/profile/status:status" component={ProfileListScreen}/>
              <Route path="/profile/:id" component={ProfileDetailScreen}/>

              <Route exact path="/sauna" component={SaunaListScreen}/>
              <Route path="/sauna/status:status" component={SaunaListScreen}/>
              <Route path="/sauna/:id" component={SaunaDetailScreen}/>

              <Route exact path="/salon" component={SalonListScreen}/>
              <Route path="/salon/status:status" component={SalonListScreen}/>
              <Route path="/salon/:id" component={SalonDetailScreen}/>

              <Route exact path="/job" component={JobListScreen}/>
              <Route path="/job/status:status" component={JobListScreen}/>
              <Route path="/job/:id" component={JobDetailScreen}/>

              <Route exact path="/user" component={UserListScreen}/>
              <Route path="/user/:id" component={UserDetailScreen}/>

              <Route exact path="/review" component={ReviewListScreen}/>
              <Route path="/review/:id" component={ReviewDetailScreen}/>

              <Route exact path="/balance" component={BalanceListScreen}/>
              <Route path="/balance/:id" component={BalanceDetailScreen}/>

              <Route exact path="/transaction" component={TransactionListScreen}/>
            </Switch>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}
