import React, { Component } from 'react';
import { Row, Col, Collapse } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { PhotoDetail, FieldValue, OnOff } from "components/common";
import { DomainValue } from "components/blocks/Domain";
import { CityValue } from "components/blocks/City";


class SiteDetailScreen extends Component {
  render() {
    const { site } = this.props;
    const detail = site.detail || {};

    const domainObj = detail.domain || {};
    const cityObj = detail.city || {};

    const config = detail.config || {};
    const tariff = detail.tariff || {};

    return (
      <div className="page-content page-content-full">
        <Row gutter={32}>
          <Col span={16}>
            <div className="page-block">
              <FieldValue label="Домен" labelWidth={5}>
                <a href={`http://${domainObj.domain}`} target="_blank" rel="noopener noreferrer">
                  <DomainValue detail={domainObj}/>
                </a>
              </FieldValue>

              <FieldValue label="Скрытый домен" labelWidth={5}>
                {detail.hidden_domain &&
                <a href={`//${detail.hidden_domain}`} target="_blank" rel="noopener noreferrer">
                  {detail.hidden_domain}
                </a>}
              </FieldValue>

              <FieldValue label="Город" labelWidth={5}>
                <CityValue detail={cityObj} asLink={true}/>
              </FieldValue>

              <FieldValue label="Название" labelWidth={5}>
                {detail.name}
              </FieldValue>
            </div>

            <br/>

            <Collapse defaultActiveKey={['paths']}>
              <Collapse.Panel header="Пути" key="paths">
                <FieldValue label="Путь к анкетам" labelWidth={5}>
                  {detail.profile_path}
                </FieldValue>
                <FieldValue label="Путь к саунам" labelWidth={5}>
                  {detail.sauna_path}
                </FieldValue>
                <FieldValue label="Путь к салонам" labelWidth={5}>
                  {detail.salon_path}
                </FieldValue>
                <FieldValue label="Путь к вакансиям" labelWidth={5}>
                  {detail.job_path}
                </FieldValue>

                <FieldValue label="Путь к статьям" labelWidth={5}>
                  {detail.article_path}
                </FieldValue>
                <FieldValue label="Путь к услугам" labelWidth={5}>
                  {detail.service_path}
                </FieldValue>
                <FieldValue label="Путь к районам" labelWidth={5}>
                  {detail.district_path}
                </FieldValue>
                <FieldValue label="Путь к внешностям" labelWidth={5}>
                  {detail.ethnicity_path}
                </FieldValue>
              </Collapse.Panel>

              <Collapse.Panel header="Настройки" key="config">
                <FieldValue label="Meta" labelWidth={5}>
                  {config.meta}
                </FieldValue>

                <FieldValue label="Robots" labelWidth={5}>
                  {config.robots}
                </FieldValue>

                <FieldValue label="Метрика" labelWidth={5}>
                  {config.metrics}
                </FieldValue>

                <FieldValue label="Скрипты header" labelWidth={5}>
                  {config.scripts_header}
                </FieldValue>

                <FieldValue label="Скрипты footer" labelWidth={5}>
                  {config.scripts_footer}
                </FieldValue>
              </Collapse.Panel>
            </Collapse>
          </Col>

          <Col span={8}>
            <div className="page-block">
              <FieldValue label="Текст. логотип" labelWidth={12}>
                {config.logo_title}{config.logo_title2}{config.logo_suffix}
              </FieldValue>

              <PhotoDetail
                label="Логотип"
                photos={config.logo}
              />

              <br/>

              <PhotoDetail
                label="Favicon"
                photos={config.favicon}
              />
            </div>

            <br/>

            <div className="page-block">
              <FieldValue label="Биллинг включён" labelWidth={12}>
                <OnOff
                  object={detail}
                  param="billing_is_on"
                  loading={site.updateStatus.isLoading}
                  onChange={data => this.props.actions.siteUpdate(data)}
                />
              </FieldValue>
            </div>

            <br/>

            <Collapse defaultActiveKey={['profile']}>
              <Collapse.Panel header="Тарифы анкет" key="profile">
                <FieldValue label="Период" labelWidth={12}>
                  {tariff.profile_period} {tariff.profile_period && 'час'}
                </FieldValue>
                <FieldValue label="Цена размещения" labelWidth={12}>
                  {tariff.profile_price} {tariff.profile_price && 'руб'}
                </FieldValue>
                <FieldValue label="Цена поднятия" labelWidth={12}>
                  {tariff.profile_up_price} {tariff.profile_up_price && 'руб'}
                </FieldValue>
              </Collapse.Panel>

              <Collapse.Panel header="Тарифы саун" key="sauna">
                <FieldValue label="Период" labelWidth={12}>
                  {tariff.sauna_period} {tariff.sauna_period && 'час'}
                </FieldValue>
                <FieldValue label="Цена размещения" labelWidth={12}>
                  {tariff.sauna_price} {tariff.sauna_price && 'руб'}
                </FieldValue>
                <FieldValue label="Цена поднятия" labelWidth={12}>
                  {tariff.sauna_up_price} {tariff.sauna_up_price && 'руб'}
                </FieldValue>
              </Collapse.Panel>

              <Collapse.Panel header="Тарифы салонов" key="salon">
                <FieldValue label="Период" labelWidth={12}>
                  {tariff.salon_period} {tariff.salon_period && 'час'}
                </FieldValue>
                <FieldValue label="Цена размещения" labelWidth={12}>
                  {tariff.salon_price} {tariff.salon_price && 'руб'}
                </FieldValue>
                <FieldValue label="Цена поднятия" labelWidth={12}>
                  {tariff.salon_up_price} {tariff.salon_up_price && 'руб'}
                </FieldValue>
              </Collapse.Panel>

              <Collapse.Panel header="Тарифы вакансий" key="job">
                <FieldValue label="Период" labelWidth={12}>
                  {tariff.job_period} {tariff.job_period && 'час'}
                </FieldValue>
                <FieldValue label="Цена размещения" labelWidth={12}>
                  {tariff.job_price} {tariff.job_price && 'руб'}
                </FieldValue>
                <FieldValue label="Цена поднятия" labelWidth={12}>
                  {tariff.job_up_price} {tariff.job_up_price && 'руб'}
                </FieldValue>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    site: state.site,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.site), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetailScreen);
