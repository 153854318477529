import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/balance';


export function* watch() {
  yield takeEvery(actions.BALANCE_LIST_LOAD.START, actionBalanceLoad);
  yield takeEvery(actions.BALANCE_DETAIL_LOAD.START, actionBalanceDetailLoad);
  yield takeEvery(actions.BALANCE_UPDATE.START, actionUpdate);
}


function* actionBalanceLoad(action) {
  yield base.Request(action, api.balanceListLoad,
    {
      request: actions.balanceListLoadRequest,
      complete: actions.balanceListLoadComplete,
      error: actions.balanceListLoadError
    },
  );
}

function* actionBalanceDetailLoad(action) {
  yield base.Request(action, api.balanceDetailLoad,
    {
      request: actions.balanceDetailLoadRequest,
      complete: actions.balanceDetailLoadComplete,
      error: actions.balanceDetailLoadError
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.balanceUpdate,
    {
      request: actions.balanceUpdateRequest,
      complete: actions.balanceUpdateComplete,
      error: actions.balanceUpdateError,
    },
    function* (data) {
      if (!action.payload.preventLoadDetail) {
        yield put(actions.balanceDetailLoad({ id: data.id }));
        yield put(push(`/balance/${data.id}`));
      } else {
        const filter = yield select((state) => state.balance.filter);
        yield put(actions.balanceListLoad(filter));
      }
    }
  );
}
