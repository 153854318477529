import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const JOB_LIST_LOAD = createRequestTypes('JOB_LIST_LOAD');
export const JOB_DETAIL_LOAD = createRequestTypes('JOB_DETAIL_LOAD');
export const JOB_UPDATE = createRequestTypes('JOB_UPDATE');
export const JOB_DELETE = createRequestTypes('JOB_DELETE');
export const JOB_SHOW_EDIT_FORM = 'JOB_SHOW_EDIT_FORM';

export const jobShowEditForm = createAction(JOB_SHOW_EDIT_FORM);

export const jobListLoad = createAction(JOB_LIST_LOAD.START);
export const jobListLoadRequest = createAction(JOB_LIST_LOAD.REQUEST);
export const jobListLoadComplete = createAction(JOB_LIST_LOAD.COMPLETE);
export const jobListLoadError = createAction(JOB_LIST_LOAD.ERROR);

export const jobDetailLoad = createAction(JOB_DETAIL_LOAD.START);
export const jobDetailLoadRequest = createAction(JOB_DETAIL_LOAD.REQUEST);
export const jobDetailLoadComplete = createAction(JOB_DETAIL_LOAD.COMPLETE);
export const jobDetailLoadError = createAction(JOB_DETAIL_LOAD.ERROR);

export const jobUpdate = createAction(JOB_UPDATE.START);
export const jobUpdateRequest = createAction(JOB_UPDATE.REQUEST);
export const jobUpdateComplete = createAction(JOB_UPDATE.COMPLETE);
export const jobUpdateError = createAction(JOB_UPDATE.ERROR);

export const jobDelete = createAction(JOB_DELETE.START);
export const jobDeleteRequest = createAction(JOB_DELETE.REQUEST);
export const jobDeleteComplete = createAction(JOB_DELETE.COMPLETE);
export const jobDeleteError = createAction(JOB_DELETE.ERROR);
