import { all } from 'redux-saga/effects'
import * as app from 'store/app';
import * as auth from 'store/auth';
import * as user from 'store/user';
import * as city from 'store/city';
import * as district from 'store/district';
import * as site from 'store/site';
import * as domain from 'store/domain';
import * as service_group from 'store/service_group';
import * as service from 'store/service';
import * as profile from 'store/profile';
import * as sauna from 'store/profile_sauna';
import * as salon from 'store/profile_salon';
import * as job from 'store/profile_job';
import * as page from 'store/page';
import * as seo_template_page from 'store/seo_template_page';
import * as seo_template_photo from 'store/seo_template_photo';
import * as site_nav from 'store/site_nav';
import * as balance from 'store/balance';
import * as transaction from 'store/transaction';
import * as refill from 'store/refill';
import * as review from 'store/review';
import * as article from 'store/article';
import * as slider from 'store/slider';
import * as banner from 'store/banner';


const actions = {
  app: app.actions,
  auth: auth.actions,
  user: user.actions,
  city: city.actions,
  district: district.actions,
  site: site.actions,
  domain: domain.actions,
  service_group: service_group.actions,
  service: service.actions,
  profile: profile.actions,
  sauna: sauna.actions,
  salon: salon.actions,
  job: job.actions,
  page: page.actions,
  seo_template_page: seo_template_page.actions,
  seo_template_photo: seo_template_photo.actions,
  site_nav: site_nav.actions,

  balance: balance.actions,
  transaction: transaction.actions,
  refill: refill.actions,
  article: article.actions,
  review: review.actions,
  slider: slider.actions,
  banner: banner.actions,
};

const reducers = {
  app: app.reducer,
  auth: auth.reducer,
  user: user.reducer,
  city: city.reducer,
  district: district.reducer,
  site: site.reducer,
  domain: domain.reducer,
  service_group: service_group.reducer,
  service: service.reducer,
  profile: profile.reducer,
  sauna: sauna.reducer,
  salon: salon.reducer,
  job: job.reducer,
  page: page.reducer,
  seo_template_page: seo_template_page.reducer,
  seo_template_photo: seo_template_photo.reducer,
  site_nav: site_nav.reducer,

  balance: balance.reducer,
  transaction: transaction.reducer,
  refill: refill.reducer,
  article: article.reducer,
  review: review.reducer,
  slider: slider.reducer,
  banner: banner.reducer,
};


function* rootSaga() {
  yield all([
    app.sagas.watchAppInit(),
    app.sagas.watchAppDataLoad(),

    auth.sagas.watchAuthLogin(),
    auth.sagas.watchAuthLogout(),
    user.sagas.watch(),

    city.sagas.watch(),
    district.sagas.watch(),

    site.sagas.watch(),
    site_nav.sagas.watch(),
    domain.sagas.watch(),

    service_group.sagas.watch(),
    service.sagas.watch(),

    profile.sagas.watch(),
    sauna.sagas.watch(),
    salon.sagas.watch(),
    job.sagas.watch(),

    page.sagas.watch(),
    seo_template_page.sagas.watch(),
    seo_template_photo.sagas.watch(),

    balance.sagas.watch(),
    transaction.sagas.watch(),
    refill.sagas.watch(),
    article.sagas.watch(),
    review.sagas.watch(),
    slider.sagas.watch(),
    banner.sagas.watch(),
  ])
}


export {
  actions,
  reducers,
  rootSaga,
}
