import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.siteNavShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.siteNavListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.siteNavListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.siteNavListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.siteNavDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.siteNavDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.siteNavDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.siteNavUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.siteNavUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.siteNavUpdateError]: (state, action) => base.updateError(state, action),

    [actions.siteNavDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.siteNavDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.siteNavDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
