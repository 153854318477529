import { put, takeEvery, select } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/service';


export function* watch() {
  yield takeEvery(actions.SERVICE_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.SERVICE_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.SERVICE_UPDATE.START, actionUpdate);
  yield takeEvery(actions.SERVICE_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.serviceListLoad,
    {
      request: actions.serviceListLoadRequest,
      complete: actions.serviceListLoadComplete,
      error: actions.serviceListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.serviceDetailLoad,
    {
      request: actions.serviceDetailLoadRequest,
      complete: actions.serviceDetailLoadComplete,
      error: actions.serviceDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.serviceUpdate,
    {
      request: actions.serviceUpdateRequest,
      complete: actions.serviceUpdateComplete,
      error: actions.serviceUpdateError,
    },
    function* (data) {
      yield put(actions.serviceShowEditForm({ show: false }));
      const filter = yield select((state) => state.service.filter);
      yield put(actions.serviceListLoad(filter));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.serviceDelete,
    {
      request: actions.serviceDeleteRequest,
      complete: actions.serviceDeleteComplete,
      error: actions.serviceDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.service.filter);
      yield put(actions.serviceListLoad(filter));
    }
  );
}
