import React, { Component } from 'react';
import { Button, Icon, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ActionButton } from "components/common";
import { DomainForm } from 'components/blocks/Domain';
import DomainMainScreen from './DomainMainScreen';


class DomainScreen extends Component {
  state = {
    id: null,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.domainDetailLoad({ id: match.params.id });
    this.setState({ id: match.params.id });
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.domainShowEditForm({ show: true, object: item });
  };

  delete = () => {
    const { actions } = this.props;
    const { id } = this.state;
    actions.domainDelete({ id });
  };

  render() {
    const { history, domain } = this.props;
    const detail = domain.detail || {};

    return (
      <Page>
        <Spin spinning={domain.updateStatus.isLoading}>
          <PageHeader
            history={history}
            title={detail.domain}
            actions={[
              <Button onClick={() => this.showEditForm(detail)}>
                <Icon type="plus"/>
                <span>Редактировать</span>
              </Button>,
              <ActionButton
                actions={[
                  {
                    key: 'delete',
                    icon: <Icon type="delete"/>,
                    title: 'Удалить',
                    action: this.delete,
                    confirm: {
                      title: 'Уверены, что хотите удалить?',
                      okText: 'Удалить',
                      cancelText: 'Отмена'
                    }
                  },
                ]}
              />
            ]}
            basePath={`/domain/${this.state.id}`}
          />

          <Route exact path="/domain/:id" component={DomainMainScreen}/>

          <DomainForm/>
        </Spin>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    domain: state.domain,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.domain), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainScreen);
