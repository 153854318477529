import React, { Component } from 'react';
import { Input } from "antd";

import { FormItem } from 'utils';


export default class MainFieldSet extends Component {
  render() {
    const { form, user } = this.props;
    const { updateStatus, editObject } = user;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    return (
      <div className="form-block">
        <FormItem
          form={form}
          instance={instance}
          name="email"
          label="E-mail"
          required={true}
          errorData={errorData}
        >
          <Input size="large"/>
        </FormItem>

        {!instance &&
        <FormItem
          form={form}
          instance={instance}
          name="password"
          label="Пароль"
          required={true}
          errorData={errorData}
        >
          <Input.Password size="large"/>
        </FormItem>}

        <FormItem
          form={form}
          instance={instance}
          name="first_name"
          label="Имя"
          required={false}
          errorData={errorData}
        >
          <Input size="large"/>
        </FormItem>

        <FormItem
          form={form}
          instance={instance}
          name="last_name"
          label="Фамилия"
          required={false}
          errorData={errorData}
        >
          <Input size="large"/>
        </FormItem>
      </div>
    );
  }
}
