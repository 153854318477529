import React, { Component } from 'react';
import { Icon } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { arrayIsEqual, tableColumn } from "utils";
import { TableList, DateTimeText, ActionButton } from 'components/common';
import DomainValue from "../DomainValue";


class DomainList extends Component {
  state = {
    columnList: ['domain', 'date_created', 'date_ended', 'is_blocked', 'actions'],
    columnByName: {
      domain: tableColumn('Домен', 'domain', {
        render: (text, item) => <DomainValue detail={item} asLink={true}/>,
        sorter: true,
      }),
      date_created: tableColumn('Дата регистрации', 'date_created', {
        render: (text, item) => <DateTimeText date={text} onlyDate={true}/>,
        width: 120,
        sorter: true,
      }),
      date_ended: tableColumn('Дата продления', 'date_ended', {
        render: (text, item) => <DateTimeText date={text} onlyDate={true}/>,
        width: 120,
        sorter: true,
      }),
      is_blocked: tableColumn('Заблокирован', 'is_blocked', {
        render: (text, item) => text && <Icon type="close-circle" theme="filled" style={{ color: '#ff0000' }}/>,
        width: 150,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Сделать главным', key: 'make_main', icon: 'up-square', action: () => this.domainMakeMain(item),
            }, {
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.domainListLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.domainShowEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.domainDelete({ id: item.id })
  };

  domainMakeMain = (item) => {
    this.props.actions.domainMakeMain({ id: item.id })
  };

  render() {
    const { history, defaultFilter, activeFilter, topActions, domain } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={domain}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        topActions={topActions}
        onLoadData={this.load}
        childrenColumnName='mirrors'
      />
    );
  }
}


function mapStateToProps(state) {
  return {
    domain: state.domain,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.domain), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainList);
