import { put, takeEvery, select } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/site_nav';


export function* watch() {
  yield takeEvery(actions.SITE_NAV_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.SITE_NAV_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.SITE_NAV_UPDATE.START, actionUpdate);
  yield takeEvery(actions.SITE_NAV_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.siteNavListLoad,
    {
      request: actions.siteNavListLoadRequest,
      complete: actions.siteNavListLoadComplete,
      error: actions.siteNavListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.siteNavDetailLoad,
    {
      request: actions.siteNavDetailLoadRequest,
      complete: actions.siteNavDetailLoadComplete,
      error: actions.siteNavDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.siteNavUpdate,
    {
      request: actions.siteNavUpdateRequest,
      complete: actions.siteNavUpdateComplete,
      error: actions.siteNavUpdateError,
    },
    function* (data) {
      yield put(actions.siteNavShowEditForm({ show: false }));
      const filter = yield select((state) => state.site_nav.filter);
      yield put(actions.siteNavListLoad(filter));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.siteNavDelete,
    {
      request: actions.siteNavDeleteRequest,
      complete: actions.siteNavDeleteComplete,
      error: actions.siteNavDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.site_nav.filter);
      yield put(actions.siteNavListLoad(filter));
    }
  );
}
