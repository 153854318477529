import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.sliderShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.sliderListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.sliderListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.sliderListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.sliderDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.sliderDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.sliderDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.sliderUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.sliderUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.sliderUpdateError]: (state, action) => base.updateError(state, action),

    [actions.sliderDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.sliderDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.sliderDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
