import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { arrayIsEqual, tableColumn } from "utils";
import { TableList, DateTimeText } from 'components/common';


class TransactionList extends Component {
  state = {
    updateItemId: null,
    columnList: ['id', 'date', 'user_balance', 'balance', 'amount', 'comment'],
    columnByName: {
      id: tableColumn('ID', 'id', {
        width: 70,
      }),
      date: tableColumn('Дата', 'date', {
        render: (text, item) => <DateTimeText date={text}/>,
        sorter: true,
          width: 130,
      }),
      user_balance: tableColumn('Пользователь', 'user_balance', {
        width: 150,
        render: (user_balance, item) => (
          <Link to={`/balance/${user_balance.id}`}>
            {user_balance.user.email}
          </Link>
        ),
      }),
      balance: tableColumn('Баланс', 'balance', {
        sorter: true,
        width: 100,
      }),
      amount: tableColumn('Сумма', 'amount', {
        sorter: true,
        width: 100,
      }),
      comment: tableColumn('Комментарий', 'comment', {
        sorter: true,
        render: (text, item) => {
          const { content_object } = item;
          let obj_link = '';
          if (content_object.id) {
            obj_link = (
              <Link to={`/${content_object.type}/${content_object.id}`}>
                {content_object.name}
              </Link>
            );
          }
          let parts = text.split('[content_object]');
          for (let i = 1; i < parts.length; i += 2) {
            parts[i] = <span key={i}>{obj_link}</span>;
          }
          return (
            <span>{parts}</span>
          )
        },
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    const { transaction } = this.props;
    if (activeFilter === undefined) {
      activeFilter = transaction.filter.activeFilter
    }
    this.props.actions.transactionListLoad({ query, activeFilter, locationChange: true });
  };

  render() {
    const { transaction, defaultFilter, activeFilter, topActions, history } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={transaction}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        topActions={topActions}
        showSearch={true}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    transaction: state.transaction,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.transaction), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);


