import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.siteShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.siteListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.siteListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.siteListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.siteDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.siteDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.siteDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.siteUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.siteUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.siteUpdateError]: (state, action) => base.updateError(state, action),

    [actions.siteDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.siteDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.siteDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
