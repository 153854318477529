import React, { PureComponent } from 'react';
import classNames from 'classnames';

import styles from './loader.module.css';


export default class Loader extends PureComponent {
  render() {
    if (!this.props.active) return null;
    const { fixed, color } = this.props;

    const cx = classNames([
      styles.main,
      fixed && styles.mainFixed,
      color && styles[`color_${color}`] && styles[`color_${color}`],
    ]);

    return (
      <div className={cx}>
        <div className={styles.spinner}>
          <div className={styles.bounce1}/>
          <div className={styles.bounce2}/>
          <div className={styles.bounce3}/>
        </div>
      </div>
    );
  }
}
