import { put, takeEvery, select } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/slider';


export function* watch() {
  yield takeEvery(actions.SLIDER_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.SLIDER_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.SLIDER_UPDATE.START, actionUpdate);
  yield takeEvery(actions.SLIDER_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.sliderListLoad,
    {
      request: actions.sliderListLoadRequest,
      complete: actions.sliderListLoadComplete,
      error: actions.sliderListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.sliderDetailLoad,
    {
      request: actions.sliderDetailLoadRequest,
      complete: actions.sliderDetailLoadComplete,
      error: actions.sliderDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.sliderUpdate,
    {
      request: actions.sliderUpdateRequest,
      complete: actions.sliderUpdateComplete,
      error: actions.sliderUpdateError,
    },
    function* (data) {
      yield put(actions.sliderShowEditForm({ show: false }));
      const filter = yield select((state) => state.slider.filter);
      yield put(actions.sliderListLoad(filter));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.sliderDelete,
    {
      request: actions.sliderDeleteRequest,
      complete: actions.sliderDeleteComplete,
      error: actions.sliderDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.slider.filter);
      yield put(actions.sliderListLoad(filter));
    }
  );
}
