import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.serviceShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.serviceListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.serviceListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.serviceListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.serviceDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.serviceDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.serviceDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.serviceUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.serviceUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.serviceUpdateError]: (state, action) => base.updateError(state, action),

    [actions.serviceDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.serviceDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.serviceDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
