import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from "antd";

import { actions } from "store";
import { SitePageList, SitePageForm } from 'components/blocks/Site';


class PageListScreen extends Component {
  showForm = (item = null) => {
    const { actions } = this.props;
    actions.pageShowEditForm({ show: true, object: item });
  };

  render() {
    const { site, history } = this.props;
    const detail = site.detail;

    return (
      <Fragment>
        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            {detail &&
            <SitePageList
              history={history}
              activeFilter={{ site: detail.id }}
              topActions={[
                <Button type="primary" className="" icon="plus" onClick={() => this.showForm()}>
                  Добавить страницу
                </Button>
              ]}
            />}
          </div>

          {detail &&
          <SitePageForm siteId={detail.id}/>}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    site: state.site,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.page), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageListScreen);
