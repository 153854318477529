import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

import { salonDetailLoad } from "store/api/profile_salon";
import { DataLoadComponent, Photo } from 'components/common';


class SalonValue extends DataLoadComponent {
  apiDetailLoad = salonDetailLoad;

  renderValue = (detail) => {
    const { id } = this.props;
    const value = (detail && detail.name_en) || id;
    const photos = (detail && detail.photos) || [];
    return (
      <span style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}>
            <Photo
              style={{ borderRadius: 15, marginRight: 10 }}
              url={photos.length && (photos[0].small || photos[0].url)}
              width={30} height={30}
            />
            <span>{value}</span>
        </span>
    )
  };

  render() {
    const { id, mode } = this.props;
    const { detail, loadStatus } = this.state;
    const asLink = mode === 'link';

    return (
      <Spin spinning={loadStatus.isLoading}>
        {asLink ?
          <Link to={`/salon/${id}`}>{this.renderValue(detail)}</Link>
          :
          <span>{this.renderValue(detail)}</span>
        }
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(SalonValue);
