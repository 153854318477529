import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SALON_LIST_LOAD = createRequestTypes('SALON_LIST_LOAD');
export const SALON_DETAIL_LOAD = createRequestTypes('SALON_DETAIL_LOAD');
export const SALON_UPDATE = createRequestTypes('SALON_UPDATE');
export const SALON_DELETE = createRequestTypes('SALON_DELETE');
export const SALON_SHOW_EDIT_FORM = 'SALON_SHOW_EDIT_FORM';

export const salonShowEditForm = createAction(SALON_SHOW_EDIT_FORM);

export const salonListLoad = createAction(SALON_LIST_LOAD.START);
export const salonListLoadRequest = createAction(SALON_LIST_LOAD.REQUEST);
export const salonListLoadComplete = createAction(SALON_LIST_LOAD.COMPLETE);
export const salonListLoadError = createAction(SALON_LIST_LOAD.ERROR);

export const salonDetailLoad = createAction(SALON_DETAIL_LOAD.START);
export const salonDetailLoadRequest = createAction(SALON_DETAIL_LOAD.REQUEST);
export const salonDetailLoadComplete = createAction(SALON_DETAIL_LOAD.COMPLETE);
export const salonDetailLoadError = createAction(SALON_DETAIL_LOAD.ERROR);

export const salonUpdate = createAction(SALON_UPDATE.START);
export const salonUpdateRequest = createAction(SALON_UPDATE.REQUEST);
export const salonUpdateComplete = createAction(SALON_UPDATE.COMPLETE);
export const salonUpdateError = createAction(SALON_UPDATE.ERROR);

export const salonDelete = createAction(SALON_DELETE.START);
export const salonDeleteRequest = createAction(SALON_DELETE.REQUEST);
export const salonDeleteComplete = createAction(SALON_DELETE.COMPLETE);
export const salonDeleteError = createAction(SALON_DELETE.ERROR);
