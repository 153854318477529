import { put, takeEvery, select } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/district';


export function* watch() {
  yield takeEvery(actions.DISTRICT_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.DISTRICT_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.DISTRICT_UPDATE.START, actionUpdate);
  yield takeEvery(actions.DISTRICT_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.districtListLoad,
    {
      request: actions.districtListLoadRequest,
      complete: actions.districtListLoadComplete,
      error: actions.districtListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.districtDetailLoad,
    {
      request: actions.districtDetailLoadRequest,
      complete: actions.districtDetailLoadComplete,
      error: actions.districtDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.districtUpdate,
    {
      request: actions.districtUpdateRequest,
      complete: actions.districtUpdateComplete,
      error: actions.districtUpdateError,
    },
    function* (data) {
      const filter = yield select((state) => state.district.filter);
      yield put(actions.districtShowEditForm({ show: false }));
      yield put(actions.districtListLoad(filter));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.districtDelete,
    {
      request: actions.districtDeleteRequest,
      complete: actions.districtDeleteComplete,
      error: actions.districtDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.district.filter);
      yield put(actions.districtListLoad(filter));
    }
  );
}
