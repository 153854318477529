import React, { Component } from 'react';
import { Icon, Drawer, Form, Checkbox, Radio } from "antd";

import { FormItem, getUrlSearch } from "utils";

import './filter.css';


class Filter extends Component {
  state = {
    collapse: false,
    query: null,
    instance: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data, fields } = nextProps;
    const { query, params } = data.filter;

    if (query === prevState.query) {
      return null;
    }

    let instance = Object.keys(params).reduce(function (result, fieldName) {
      let value = params[fieldName];
      const field = fields.byName[fieldName];
      const fieldType = field && field.type;
      switch (fieldType) {
        case 'bool':
          value = value === 'true';
          break;
        case 'int':
          value = parseInt(value);
          break;
        case 'list':
          if (!Array.isArray(value)) {
            value = [value];
          }
          break;
        case 'list_int':
          if (Array.isArray(value)) {
            value = value.map(v => parseInt(v));
          } else {
            value = [parseInt(value)];
          }
          break;
        default:
          break
      }
      result[fieldName] = value;
      return result
    }, {});

    return {
      query: query,
      instance: instance,
    }
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { form, fields } = this.props;
    const { instance, collapse } = this.state;

    return (
      <Drawer
        title="Фильтры"
        visible={collapse}
        width={300}
        onClose={this.toggle}
        placement="right"
        destroyOnClose={true}
        handler={
          <div className="filter-handle" onClick={this.toggle}>
            <Icon
              type={collapse ? 'close' : 'filter'}
              style={{
                color: '#fff',
                fontSize: 20,
              }}
            />
          </div>
        }
        style={{
          zIndex: 999,
        }}
      >
        <div className="filter-content">
          <Form>
            {fields.list.map(name => {
              const field = fields.byName[name];
              if (field.component) {
                return (
                  <field.component
                    key={name}
                    form={form}
                    instance={instance}
                    name={name}
                    label={field.label}
                    activeFilter={field.activeFilter}
                  />
                )
              }
              switch (field.type) {
                case 'bool':
                  return (
                    <FormItem
                      key={name}
                      form={form}
                      instance={instance}
                      name={name}
                      valuePropName="checked"
                      label={field.label}
                    >
                      <Radio.Group defaultValue="" buttonStyle="solid">
                        <Radio.Button value="">Все</Radio.Button>
                        <Radio.Button value="true">Да</Radio.Button>
                        <Radio.Button value="false">Нет</Radio.Button>
                      </Radio.Group>
                    </FormItem>
                  );
                case 'list':
                  return (
                    <FormItem
                      key={name}
                      form={form}
                      instance={instance}
                      name={name}
                      label={field.label}
                    >
                      <Checkbox.Group options={field.options}/>
                    </FormItem>
                  );
                default:
                  return ''

              }
            })}
          </Form>
        </div>
      </Drawer>
    );
  }
}


const WrappedFilter = Form.create({
  onValuesChange(props, values, allFieldsValues) {
    const { data, onLoadData } = props;
    if (onLoadData) {
      let params = Object.assign({}, data.filter.params);

      Object.keys(allFieldsValues).forEach(fieldName => {
        let value = allFieldsValues[fieldName];
        if (value !== undefined) {
          params[fieldName] = value;
        }
      });

      const query = '?' + getUrlSearch(params);
      onLoadData({ query, activeFilter: data.filter.activeFilter })
    }
  }
})(Filter);

export default WrappedFilter;
