import React, { PureComponent } from 'react';

import styles from './text.module.css';


export default class TextEllipsis extends PureComponent {
    render() {
        const { text, width } = this.props;

        return (
            <div className={styles.text} title={text} style={{ width: `${width}px` }}>{text}</div>
        );
    }
}
