import React, { Component } from 'react';
import { Col, Row, Input, Select, Checkbox } from "antd";

import { FormItem } from 'utils';


export default class ParametersFieldSet extends Component {
  render() {
    const { form, sauna } = this.props;
    const { updateStatus, editObject } = sauna;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    return (
      <div className="form-block">
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              form={form}
              instance={instance}
              name="parameters.capacity"
              label="Вместимость"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="parameters.pool"
              label="Бассейн"
              required={false}
              errorData={errorData}
              valuePropName="checked"
            >
              <Checkbox/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="parameters.rest_room"
              label="Комната отдыха"
              required={false}
              errorData={errorData}
              valuePropName="checked"
            >
              <Checkbox/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="parameters.steam"
              label="Тип парной"
              required={false}
              errorData={errorData}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Выберите"
              >
                <Select.Option value='Русская'>Русская</Select.Option>
                <Select.Option value='Финская'>Финская</Select.Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}
