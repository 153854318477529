import axios from 'axios';
import { BASE_URL } from "config";
import * as base from './_base';

export const endpoints = {
  current: `${BASE_URL}/api/v1/user/current`,
  list: `${BASE_URL}/api/v1/user/`,
  detail: (id) => `${BASE_URL}/api/v1/user/${id}/`,
};


export const currentUserLoad = ({ token }) => {
  return axios.get(endpoints.current, {
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return { error: false, data: response.data, statusCode: response.status }
    })
    .catch(error => {
      return { error: true, data: error.response.data, statusCode: error.response.status }
    });
};

export const userListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const userDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const userUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const userDelete = (token, data) => base.Delete(endpoints.detail, token, data);
