import React, { PureComponent } from 'react';

import { parseDate } from "utils";


export default class DateTimeText extends PureComponent {
  render() {
    const { date, onlyDate } = this.props;
    let text = '';

    if (onlyDate) {
      text = parseDate(date).format('DD.MM.YYYY');
    } else {
      text = parseDate(date).format('DD.MM.YYYY HH:mm');
    }

    return (
      <div>{text}</div>
    );
  }
}
