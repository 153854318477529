import React, { Component } from 'react';
import { Button, Icon, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ActionButton } from "components/common";
import { SaunaForm } from 'components/blocks/Sauna';
import SaunaMainScreen from './SaunaMainScreen';
import SaunaReviewListScreen from './SaunaReviewListScreen';


class SaunaDetailScreen extends Component {
  state = {
    id: null,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.saunaDetailLoad({ id: match.params.id });
    this.setState({ id: match.params.id });
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.saunaShowEditForm({ show: true, object: item });
  };

  delete = () => {
    const { actions } = this.props;
    const { id } = this.state;
    actions.saunaDelete({ id });
  };

  render() {
    const { history, sauna } = this.props;
    const detail = sauna.detail || {};

    return (
      <Page>
        <Spin spinning={sauna.updateStatus.isLoading}>
          <PageHeader
            history={history}
            title={detail.name}
            actions={[
              <Button onClick={() => this.showEditForm(detail)}>
                <Icon type="plus"/>
                <span>Редактировать</span>
              </Button>,
              <ActionButton
                actions={[
                  {
                    key: 'delete',
                    icon: 'delete',
                    title: 'Удалить',
                    action: this.delete,
                    confirm: {
                      title: 'Уверены, что хотите удалить?',
                      okText: 'Удалить',
                      cancelText: 'Отмена'
                    }
                  },
                ]}
              />
            ]}
            basePath={`/sauna/${this.state.id}`}
            tabs={[
              { title: 'Детали', key: 'detail' },
              { title: 'Отзывы', key: 'reviews' },
            ]}
          />

          <Route exact path="/sauna/:id" component={SaunaMainScreen}/>
          <Route exact path="/sauna/:id/reviews" component={SaunaReviewListScreen}/>

          <SaunaForm/>
        </Spin>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    sauna: state.sauna,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.sauna), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaunaDetailScreen);
