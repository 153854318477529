import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils/redux';

export const AUTH_LOGIN = createRequestTypes('AUTH_LOGIN');
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const authLogin = createAction(AUTH_LOGIN.START);
export const authLoginRequest = createAction(AUTH_LOGIN.REQUEST);
export const authLoginComplete = createAction(AUTH_LOGIN.COMPLETE);
export const authLoginError = createAction(AUTH_LOGIN.ERROR);

export const authLogout = createAction(AUTH_LOGOUT);
