import React, { Component } from 'react';
import { Button, Icon, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ActionButton } from "components/common";
import { SiteForm } from 'components/blocks/Site';
import SiteMainScreen from './SiteMainScreen';
import DomainListScreen from './DomainListScreen';
import PageListScreen from './PageListScreen';
import SiteNavListScreen from './SiteNavListScreen';
import SeoTemplatePageListScreen from './SeoTemplatePageListScreen';
import ArticleListScreen from './ArticleListScreen';
import ProfileListScreen from './ProfileListScreen';
import SaunaListScreen from './SaunaListScreen';
import SalonListScreen from './SalonListScreen';
import JobListScreen from './JobListScreen';
import SliderListScreen from './SliderListScreen';
import BannerListScreen from './BannerListScreen';


class SiteDetailScreen extends Component {
  state = {
    id: null,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.siteDetailLoad({ id: match.params.id });
    this.setState({ id: match.params.id });
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.siteShowEditForm({ show: true, object: item });
  };

  delete = () => {
    const { actions } = this.props;
    const { id } = this.state;
    actions.siteDelete({ id });
  };

  render() {
    const { site, history } = this.props;
    const detail = site.detail || {};
    const domain = detail.domain || {};

    return (
      <Page>
        <Spin spinning={site.updateStatus.isLoading}>
          <PageHeader
            history={history}
            title={domain.domain}
            actions={[
              <Button onClick={() => this.showEditForm(detail)}>
                <Icon type="plus"/>
                <span>Редактировать</span>
              </Button>,
              <ActionButton
                actions={[
                  {
                    key: 'delete',
                    icon: <Icon type="delete"/>,
                    title: 'Удалить',
                    action: this.delete,
                    confirm: {
                      title: 'Уверены, что хотите удалить?',
                      okText: 'Удалить',
                      cancelText: 'Отмена'
                    }
                  },
                ]}
              />
            ]}
            basePath={`/site/${this.state.id}`}
            tabs={[
              { title: 'Детали', key: 'detail' },
              { title: 'Домены', key: 'domains' },
              { title: 'Навигация', key: 'nav' },
              { title: 'Слайдер', key: 'slider' },
              { title: 'Баннеры', key: 'banner' },
              { title: 'SEO-шаблоны', key: 'seo_template_page' },
              { title: 'Страницы', key: 'pages' },
              { title: 'Статьи', key: 'articles' },
              { title: 'Анкеты', key: 'profiles' },
              { title: 'Сауны', key: 'saunas' },
              { title: 'Салоны', key: 'salons' },
              { title: 'Вакансии', key: 'jobs' },
            ]}
          />

          <Route exact path="/site/:id" component={SiteMainScreen}/>
          <Route exact path="/site/:id/domains" component={DomainListScreen}/>
          <Route exact path="/site/:id/nav" component={SiteNavListScreen}/>
          <Route exact path="/site/:id/seo_template_page" component={SeoTemplatePageListScreen}/>
          <Route exact path="/site/:id/pages" component={PageListScreen}/>
          <Route exact path="/site/:id/articles" component={ArticleListScreen}/>
          <Route exact path="/site/:id/profiles" component={ProfileListScreen}/>
          <Route exact path="/site/:id/saunas" component={SaunaListScreen}/>
          <Route exact path="/site/:id/salons" component={SalonListScreen}/>
          <Route exact path="/site/:id/jobs" component={JobListScreen}/>
          <Route exact path="/site/:id/slider" component={SliderListScreen}/>
          <Route exact path="/site/:id/banner" component={BannerListScreen}/>

          <SiteForm/>
        </Spin>
      </Page>
    );
  }
}


function mapStateToProps(state) {
  return {
    site: state.site,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.site), dispatch),
    dispatch: dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SiteDetailScreen);
