import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Input, Checkbox } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { FormItem } from 'utils/form_helpers';
import { Error } from 'components/common';


class SitePageForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, page, siteId } = this.props;
        const instance = page.editObject;

        let data = Object.assign({ site: siteId }, values);
        if (instance && instance.id) {
          data.id = instance.id;
        } else {
          if (data.profile_filter) {
            data.type = 'page_profiles';
          }
          data.object_id = data.path;
        }

        actions.pageUpdate(data);
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.pageShowEditForm({ show: false });
  };

  render() {
    const { form, page } = this.props;
    const { showEditForm, updateStatus, editObject, editInitial } = page;
    const instance = editObject;
    const initial = editInitial || {};

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = page.updateStatus.isLoading;

    return (
      <Modal
        width={640}
        visible={showEditForm}
        title="Редактирование страницы"
        onCancel={this.close}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit}>
            <Error errors={errorData.non_field_errors}/>

            <FormItem
              className="ant-form-item2"
              form={form}
              instance={instance}
              name="seo_template_off"
              valuePropName="checked"
            >
              <Checkbox>Не использовать шаблон</Checkbox>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="path"
              label="Путь"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="title"
              label="Тайтл"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="description"
              label="Description"
              required={false}
              errorData={errorData}
            >
              <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="h1"
              label="H1"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="h2"
              label="H2"
              required={false}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="text"
              label="Текст"
              required={false}
              errorData={errorData}
            >
              <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="text_bottom"
              label="Текст внизу"
              required={false}
              errorData={errorData}
            >
              <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="text_left"
              label="Текст слева"
              required={false}
              errorData={errorData}
            >
              <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="text_right_tpl"
              label="Текст справа"
              required={false}
              errorData={errorData}
            >
              <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="profile_filter"
              label="фильтр анкет"
              required={false}
              errorData={errorData}
              initialValue={initial.profile_filter}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(SitePageForm);

function mapStateToProps(state) {
  return {
    page: state.page,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.page), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);

