import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, Photo, DateTimeText, Status, ActionButton } from 'components/common';
import { CityValue } from 'components/blocks/City';
import { SiteValue, SiteSelect } from 'components/blocks/Site';
import { UserValue } from 'components/blocks/User';


class ProfileList extends Component {
  state = {
    updateItemId: null,
    columnList: [
      'id', 'photo', 'name', 'phone', 'city', 'site', 'status', 'views_count', 'date_active', 'date_up',
      'user', 'external_site', 'actions'
    ],
    columnByName: {
      id: tableColumn('ID', 'id', {
        width: 50,
        sorter: true,
      }),
      photo: tableColumn('Фото', 'photo', {
        render: (text, item) => (
          <Link to={`/profile/${item.id}`}>
            <Photo url={item.photos.length && item.photos[0].url} width={80} height={60}/>
          </Link>
        ),
        width: 112,
      }),
      name: tableColumn('Имя', 'name', {
        render: (val, item) => <Link to={`/profile/${item.id}`}>{val}</Link>,
        sorter: true,
      }),
      phone: tableColumn('Телефон', 'phone', {
        render: (val, item) => item.contacts.phone,
        width: 150,
      }),
      city: tableColumn('Город', 'city', {
        render: (val, item) => <CityValue detail={item.site.city}/>,
        width: 130,
      }),
      site: tableColumn('Сайт', 'site', {
        render: (val, item) => <SiteValue detail={val}/>,
        width: 130,
      }),
      status: tableColumn('Статус', 'status', {
        render: (val, item) => (
          <div>
            <Status status={val}/>
            <Switch
              style={{ marginTop: '5px', minWidth: '62px' }}
              checkedChildren="вкл" unCheckedChildren="выкл"
              checked={item.is_on}
              loading={this.props.profile.updateStatus.isLoading && this.state.updateItemId === item.id}
              onClick={() => this.profileOnOff(item)}
            />
          </div>
        ),
        width: 120,
      }),
      views_count: tableColumn('Просм.', 'views_count', {
        width: 90,
        sorter: true,
      }),
      date_active: tableColumn('Активна до', 'date_active', {
        render: (val, item) => <DateTimeText date={val}/>,
        width: 112,
        sorter: true,
      }),
      date_up: tableColumn('Дата поднятия', 'date_up', {
        render: (val, item) => <DateTimeText date={val}/>,
        width: 112,
        sorter: true,
      }),
      user: tableColumn('Пользователь', 'user', {
        render: (val, item) => <UserValue detail={val}/>,
        width: 130,
        sorter: true,
      }),
      external_site: tableColumn('Внешний сайт', 'external_site', {
        width: 130,
        sorter: true,
      }),

      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    const { profile } = this.props;
    if (activeFilter === undefined) {
      activeFilter = profile.filter.activeFilter
    }
    this.props.actions.profileListLoad({ query, activeFilter, locationChange: true });
  };

  showEditForm = (item) => {
    this.props.actions.profileShowEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.profileDelete({ id: item.id })
  };

  profileOnOff = (item) => {
    const data = { id: item.id, is_on: !item.is_on, preventLoadDetail: true };
    this.setState({
      updateItemId: item.id,
    });
    this.props.actions.profileUpdate(data);
  };

  addPage = () => {
    const { actions, profile } = this.props;
    actions.pageShowEditForm({ show: true, object: null, editInitial: { profile_filter: profile.query } });
  };

  showForm = (item = null) => {
    const { actions } = this.props;
    actions.profileShowEditForm({ show: true, object: item });
  };

  render() {
    const { history, defaultFilter, activeFilter, topActions, profile } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={profile}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        topActions={topActions}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}

        onRow={(item, rowIndex) => {
          return {
            onDoubleClick: event => {
              this.props.history.push(`/profile/${item.id}`);
            },
          };
        }}

        showSearch={true}
        filtersAction={[
          <Button key="addPage" size="small" type="primary" onClick={this.addPage}>
            <Icon type="save"/>
            <span>Создать страницу</span>
          </Button>
        ]}
        filterFields={{
          list: [
            'is_verified', 'has_reviews', 'has_video', 'site',
            'prices.in_hour', 'prices.out_hour',
            'parameters.gender',
          ],
          byName: {
            'is_verified': {
              label: 'Проверенные фото',
              type: 'bool',
            },
            'has_reviews': {
              label: 'С отзывами',
              type: 'bool',
            },
            'has_video': {
              label: 'С видео',
              type: 'bool',
            },
            'site': {
              label: 'Сайт',
              type: 'list_int',
              component: SiteSelect,
              valueComponent: SiteValue,
            },
            'prices.in_hour': {
              label: 'Апартаменты час',
              type: 'list',
              options: [
                { label: '0-150 €', value: '0-150' },
                { label: '151-300 €', value: '151-300' },
                { label: '301-more €', value: '301-' },
              ]
            },
            'prices.out_hour': {
              label: 'Выезд час',
              type: 'list',
              options: [
                { label: '0-150 €', value: '0-150' },
                { label: '151-300 €', value: '151-300' },
                { label: '301-more €', value: '301-' },
              ]
            },
            'parameters.gender': {
              label: 'Пол',
              type: 'list',
              options: [
                { label: 'Женский', value: 'female' },
                { label: 'Мужской', value: 'male' },
                { label: 'Транс', value: 'trans' },
              ]
            },
          }
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.profile, actions.page), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileList);
