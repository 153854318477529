import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.districtShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.districtListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.districtListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.districtListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.districtDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.districtDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.districtDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.districtUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.districtUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.districtUpdateError]: (state, action) => base.updateError(state, action),

    [actions.districtDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.districtDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.districtDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
