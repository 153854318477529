import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.cityShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.cityListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.cityListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.cityListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.cityDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.cityDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.cityDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.cityUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.cityUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.cityUpdateError]: (state, action) => base.updateError(state, action),

    [actions.cityDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.cityDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.cityDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
