import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Input } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import getSlug from 'speakingurl';

import { actions } from "store";
import { FormItem } from 'utils/form_helpers';
import SeoTemplatePartsDropdown, { renderTpl } from '../SeoTemplatePartsDropdown';

import styles from "./form.module.css";


class SeoTemplatePhotoForm extends Component {
  state = {
    extra: {},
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, seo_template_photo, siteId } = this.props;
        const instance = seo_template_photo.editObject;

        let data = Object.assign({ type: 'profile', site: siteId }, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        actions.seoTemplatePhotoUpdate(data);
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.seoTemplatePhotoShowEditForm({ show: false });
  };

  partAdd = (fieldName, part) => {
    const { form } = this.props;
    const oldValue = form.getFieldValue(fieldName) || '';
    const newValue = oldValue + part.value;
    form.setFieldsValue({
      [fieldName]: newValue,
    });
    this.updateExtra(fieldName, newValue);
  };

  updateExtra = (fieldName, value) => {
    const newExtra = Object.assign({}, this.state.extra);
    newExtra[fieldName] = renderTpl(value, 'profile');
    if (fieldName === 'tpl_path') {
      newExtra[fieldName] = getSlug(newExtra[fieldName]);
    }
    this.setState({
      extra: newExtra,
    });
  };

  render() {
    const { form, seo_template_photo } = this.props;
    const { showEditForm, updateStatus, editObject } = seo_template_photo;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = seo_template_photo.updateStatus.isLoading;

    const { extra } = this.state;

    return (
      <Modal
        visible={showEditForm}
        title="Редактирование seo-шаблона"
        onCancel={this.close}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit} onChange={e => this.updateExtra(e.target.id, e.target.value)}>
            {errorData.non_field_errors &&
            <div className={styles.error}>{errorData.non_field_errors}</div>}

            <FormItem
              form={form}
              instance={instance}
              name="tpl_path"
              label="Путь"
              required={true}
              errorData={errorData}
              extra={extra.tpl_path}
            >
              <Input
                size="large"
                style={{ width: '100%' }}
                addonAfter={
                  <SeoTemplatePartsDropdown
                    type="profile"
                    onChange={(part) => this.partAdd('tpl_path', part)}
                  />
                }
              />
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="tpl_alt"
              label="Alt"
              required={true}
              errorData={errorData}
              extra={extra.tpl_alt}
            >
              <Input
                size="large"
                style={{ width: '100%' }}
                addonAfter={
                  <SeoTemplatePartsDropdown
                    type="profile"
                    onChange={(part) => this.partAdd('tpl_alt', part)}
                  />
                }
              />
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(SeoTemplatePhotoForm);

function mapStateToProps(state) {
  return {
    seo_template_photo: state.seo_template_photo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.seo_template_photo), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);

