import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.seoTemplatePhotoShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.seoTemplatePhotoListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.seoTemplatePhotoListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.seoTemplatePhotoListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.seoTemplatePhotoDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.seoTemplatePhotoDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.seoTemplatePhotoDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.seoTemplatePhotoUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.seoTemplatePhotoUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.seoTemplatePhotoUpdateError]: (state, action) => base.updateError(state, action),

    [actions.seoTemplatePhotoDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.seoTemplatePhotoDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.seoTemplatePhotoDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
