import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/domain';


export function* watch() {
  yield takeEvery(actions.DOMAIN_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.DOMAIN_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.DOMAIN_UPDATE.START, actionUpdate);
  yield takeEvery(actions.DOMAIN_DELETE.START, actionDelete);
  yield takeEvery(actions.DOMAIN_MAKE_MAIN.START, actionDomainMakeMain);
}

function* actionListLoad(action) {
  yield base.Request(action, api.domainListLoad,
    {
      request: actions.domainListLoadRequest,
      complete: actions.domainListLoadComplete,
      error: actions.domainListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.domainDetailLoad,
    {
      request: actions.domainDetailLoadRequest,
      complete: actions.domainDetailLoadComplete,
      error: actions.domainDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.domainUpdate,
    {
      request: actions.domainUpdateRequest,
      complete: actions.domainUpdateComplete,
      error: actions.domainUpdateError,
    },
    function* (data) {
      yield put(actions.domainShowEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(actions.domainDetailLoad({ id: data.id }));
        yield put(push(`/domain/${data.parent || data.id}`));
      } else {
        const filter = yield select((state) => state.domain.filter);
        yield put(actions.domainListLoad(filter));
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.domainDelete,
    {
      request: actions.domainDeleteRequest,
      complete: actions.domainDeleteComplete,
      error: actions.domainDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.domain.filter);
      yield put(actions.domainListLoad(filter));
    }
  );
}

function* actionDomainMakeMain(action) {
  yield base.Request(action, api.domainMakeMain,
    {
      request: actions.domainMakeMainRequest,
      complete: actions.domainMakeMainComplete,
      error: actions.domainMakeMainError,
    },
    function* (data) {
      const id = data.parent || data.id;
      yield put(actions.domainDetailLoad({ id: id }));
      yield put(push(`/domain/${id}`));
    }
  );
}
