import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Tabs } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { Error } from 'components/common';

import MainFieldSet from './MainFieldSet';
import ContactFieldSet from './ContactFieldSet';
import TariffFieldSet from './TariffFieldSet';


class SalonForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, salon } = this.props;
        const instance = salon.editObject;

        let data = Object.assign({}, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        console.log(data);
        actions.salonUpdate(data);
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.salonShowEditForm({ show: false });
  };

  render() {
    const { form, salon, siteId } = this.props;
    const { showEditForm, updateStatus } = salon;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = updateStatus.isLoading;

    return (
      <Modal
        width={960}
        visible={showEditForm}
        title="Редактирование салона"
        onCancel={this.close}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit}>
            <Error errors={errorData.non_field_errors}/>

            <Tabs className="tabs">
              <Tabs.TabPane tab="Основное" key="detail" forceRender={true}>
                <MainFieldSet salon={salon} siteId={siteId} form={form} onPhotoChange={this.handlePhotosChange}/>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Контакты" key="contacts" forceRender={true}>
                <ContactFieldSet salon={salon} form={form}/>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Тарифы" key="tariff" forceRender={true}>
                <TariffFieldSet salon={salon} form={form}/>
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(SalonForm);

function mapStateToProps(state) {
  return {
    salon: state.salon,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.salon), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);

