import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils/redux';

//import { authCheck, authUserLoad } from '_models/actions/auth';
//import { clinicLoad } from '_models/actions/clinic';

export const APP_INIT = 'APP_INIT';
export const APP_DATA_LOAD = createRequestTypes('APP_DATA_LOAD');

export const appInit = createAction(APP_INIT);
export const appDataLoad = createAction(APP_DATA_LOAD.START);
export const appDataLoadRequest = createAction(APP_DATA_LOAD.REQUEST);
export const appDataLoadComplete = createAction(APP_DATA_LOAD.COMPLETE);
export const appDataLoadError = createAction(APP_DATA_LOAD.ERROR);
