import React, { Component } from 'react';
import { Modal } from "antd";

import { getUrl } from 'utils';
import styles from './photo.module.css';


export default class PhotoDetail extends Component {
    state = {
        previewVisible: false,
        previewImage: '',
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    };

    render() {
        const { label, photos } = this.props;
        const { previewVisible, previewImage } = this.state;

        return (
            <div className={styles.container}>
                {label && <div className={styles.label}>{label}</div>}

                {photos && photos.length > 0 &&
                <div className={styles.photos}>
                    <div className={styles.mainPhoto} onClick={() => this.handlePreview(photos[0])}>
                        <img src={getUrl(photos[0].url)} alt=""/>
                    </div>

                    {photos.length > 1 &&
                    <div className={styles.otherPhotos}>
                        {photos.slice(1).map(p => (
                            <div key={`photo${p.id}`} className={styles.photo} onClick={() => this.handlePreview(p)}>
                                <img src={getUrl(p.url)} alt=""/>
                            </div>
                        ))}
                    </div>
                    }
                </div>
                }

                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img style={{ width: '100%' }} src={getUrl(previewImage)} alt=""/>
                </Modal>
            </div>
        );
    }
}
