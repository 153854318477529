import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/site';


export function* watch() {
  yield takeEvery(actions.SITE_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.SITE_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.SITE_UPDATE.START, actionUpdate);
  yield takeEvery(actions.SITE_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.siteListLoad,
    {
      request: actions.siteListLoadRequest,
      complete: actions.siteListLoadComplete,
      error: actions.siteListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.siteDetailLoad,
    {
      request: actions.siteDetailLoadRequest,
      complete: actions.siteDetailLoadComplete,
      error: actions.siteDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.siteUpdate,
    {
      request: actions.siteUpdateRequest,
      complete: actions.siteUpdateComplete,
      error: actions.siteUpdateError,
    },
    function* (data) {
      yield put(actions.siteShowEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(actions.siteDetailLoad({ id: data.id }));
        yield put(push(`/site/${data.id}`));
      } else {
        const filter = yield select((state) => state.site.filter);
        yield put(actions.siteListLoad(filter));
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.siteDelete,
    {
      request: actions.siteDeleteRequest,
      complete: actions.siteDeleteComplete,
      error: actions.siteDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.site.filter);
      yield put(actions.siteListLoad(filter));
    }
  );
}
