import { BASE_URL } from "config";

import * as base from './_base';


export const endpoints = {
  list: `${BASE_URL}/api/v1/article/`,
  detail: (id) => `${BASE_URL}/api/v1/article/${id}/`,
};

export const articleListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const articleDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const articleUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const articleDelete = (token, data) => base.Delete(endpoints.detail, token, data);
