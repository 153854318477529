import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { siteDetailLoad } from "store/api/site";
import { DataLoadComponent } from 'components/common';


class SiteValue extends DataLoadComponent {
  apiDetailLoad = siteDetailLoad;

  render() {
    const { id, asLink, state } = this.props;
    const { detail, loadStatus } = this.state;
    const value = (detail && detail.domain && detail.domain.domain) || id;
    const _id = (detail && detail.id) || id;

    return (
      <Spin spinning={loadStatus.isLoading}>
        {asLink && _id ?
          <Link to={{ pathname: `/site/${_id}`, state: state }}>{value}</Link> :
          <span>{value}</span>}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(SiteValue);
