import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const TRANSACTION_LIST_LOAD = createRequestTypes('TRANSACTION_LIST_LOAD');
export const TRANSACTION_DETAIL_LOAD = createRequestTypes('TRANSACTION_DETAIL_LOAD');

export const transactionListLoad = createAction(TRANSACTION_LIST_LOAD.START);
export const transactionListLoadRequest = createAction(TRANSACTION_LIST_LOAD.REQUEST);
export const transactionListLoadComplete = createAction(TRANSACTION_LIST_LOAD.COMPLETE);
export const transactionListLoadError = createAction(TRANSACTION_LIST_LOAD.ERROR);

export const transactionDetailLoad = createAction(TRANSACTION_DETAIL_LOAD.START);
export const transactionDetailLoadRequest = createAction(TRANSACTION_DETAIL_LOAD.REQUEST);
export const transactionDetailLoadComplete = createAction(TRANSACTION_DETAIL_LOAD.COMPLETE);
export const transactionDetailLoadError = createAction(TRANSACTION_DETAIL_LOAD.ERROR);
