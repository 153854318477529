import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const BALANCE_LIST_LOAD = createRequestTypes('BALANCE_LIST_LOAD');
export const BALANCE_DETAIL_LOAD = createRequestTypes('BALANCE_DETAIL_LOAD');
export const BALANCE_UPDATE = createRequestTypes('BALANCE_UPDATE');

export const balanceListLoad = createAction(BALANCE_LIST_LOAD.START);
export const balanceListLoadRequest = createAction(BALANCE_LIST_LOAD.REQUEST);
export const balanceListLoadComplete = createAction(BALANCE_LIST_LOAD.COMPLETE);
export const balanceListLoadError = createAction(BALANCE_LIST_LOAD.ERROR);

export const balanceDetailLoad = createAction(BALANCE_DETAIL_LOAD.START);
export const balanceDetailLoadRequest = createAction(BALANCE_DETAIL_LOAD.REQUEST);
export const balanceDetailLoadComplete = createAction(BALANCE_DETAIL_LOAD.COMPLETE);
export const balanceDetailLoadError = createAction(BALANCE_DETAIL_LOAD.ERROR);

export const balanceUpdate = createAction(BALANCE_UPDATE.START);
export const balanceUpdateRequest = createAction(BALANCE_UPDATE.REQUEST);
export const balanceUpdateComplete = createAction(BALANCE_UPDATE.COMPLETE);
export const balanceUpdateError = createAction(BALANCE_UPDATE.ERROR);
