import { put, takeEvery, select } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/seo_template_page';


export function* watch() {
  yield takeEvery(actions.SEO_TEMPLATE_PAGE_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.SEO_TEMPLATE_PAGE_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.SEO_TEMPLATE_PAGE_UPDATE.START, actionUpdate);
  yield takeEvery(actions.SEO_TEMPLATE_PAGE_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.seoTemplatePageListLoad,
    {
      request: actions.seoTemplatePageListLoadRequest,
      complete: actions.seoTemplatePageListLoadComplete,
      error: actions.seoTemplatePageListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.seoTemplatePageDetailLoad,
    {
      request: actions.seoTemplatePageDetailLoadRequest,
      complete: actions.seoTemplatePageDetailLoadComplete,
      error: actions.seoTemplatePageDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.seoTemplatePageUpdate,
    {
      request: actions.seoTemplatePageUpdateRequest,
      complete: actions.seoTemplatePageUpdateComplete,
      error: actions.seoTemplatePageUpdateError,
    },
    function* (data) {
      yield put(actions.seoTemplatePageShowEditForm({ show: false }));
      const filter = yield select((state) => state.seo_template_page.filter);
      yield put(actions.seoTemplatePageListLoad(filter));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.seoTemplatePageDelete,
    {
      request: actions.seoTemplatePageDeleteRequest,
      complete: actions.seoTemplatePageDeleteComplete,
      error: actions.seoTemplatePageDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.seo_template_page.filter);
      yield put(actions.seoTemplatePageListLoad(filter));
    }
  );
}
