import React from 'react';
import { Spin } from "antd";
import { connect } from "react-redux";

import { domainDetailLoad } from "store/api/domain";
import { DataLoadComponent } from 'components/common';
import styles from './mirrorlink.module.css';


class DomainMirrorsValue extends DataLoadComponent {
  apiDetailLoad = domainDetailLoad;

  render() {
    const { detail, loadStatus } = this.state;

    return (
      <Spin spinning={loadStatus.isLoading}>
        <div className={styles.block}>
          {detail && detail.mirrors.map(d => (
            <div key={`mirror${d.id}`} className={styles.item}>{d.domain}</div>
          ))}
        </div>
      </Spin>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(DomainMirrorsValue);
