import React, { PureComponent } from 'react';

import './styles.css';


export default class ModerationNewValue extends PureComponent {
    render() {
        const { value } = this.props;
        if (!value) return null;

        return (
            <div className="moderationNewValue">
                <span className="lbl">Новое: </span>
                <span className="val">{value}</span>
            </div>
        );
    }
}
