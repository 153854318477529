import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { OnOff, DateTimeText, FieldValue } from "components/common";


class UserMainScreen extends Component {
  render() {
    const { user, actions } = this.props;
    const detail = user.detail || {};

    return (
      <div className="page-content page-content-full">
        <Row gutter={16}>
          <Col span={15}>
            <div className="page-block">
              <FieldValue label="E-mail" labelWidth={6}>
                {detail.email}
              </FieldValue>

              <FieldValue label="Имя Фамилия" labelWidth={6}>
                {detail.first_name} {detail.last_name}
              </FieldValue>

              <FieldValue label="Роль" labelWidth={6}>
                {detail.role}
              </FieldValue>

              <FieldValue label="Активный" labelWidth={6}>
                <OnOff
                  object={detail}
                  param="is_active"
                  loading={user.updateStatus.isLoading}
                  onChange={data => actions.userUpdate(data)}
                />
              </FieldValue>
            </div>
          </Col>

          <Col span={9}>
            <div className="page-block">
              <FieldValue label="Статус персонала" labelWidth={12}>
                <OnOff
                  object={detail}
                  param="is_staff"
                  loading={user.updateStatus.isLoading}
                  onChange={data => actions.userUpdate(data)}
                />
              </FieldValue>

              <FieldValue label="Поддержка" labelWidth={12}>
                <OnOff
                  object={detail}
                  param="is_support"
                  loading={user.updateStatus.isLoading}
                  onChange={data => actions.userUpdate(data)}
                />
              </FieldValue>
            </div>

            <br/>

            <div className="page-block">
              <FieldValue label="Последний вход" labelWidth={12}>
                <DateTimeText date={detail.last_login}/>
              </FieldValue>

              <FieldValue label="Дата регистрации" labelWidth={12}>
                <DateTimeText date={detail.date_joined}/>
              </FieldValue>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.user), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMainScreen);
