import React, { Component } from 'react';
import { Col, Row, Input } from "antd";

import { FormItem } from 'utils';


export default class ConfigFieldSet extends Component {
  handlePhotoChange = (fieldName, photos) => {
    this.setState({
      [fieldName]: photos,
    });
  };

  render() {
    const { form, instance, errorData } = this.props;

    return (
      <div className="form-block">
        <Row gutter={32}>
          <Col span={24}>
            <FormItem
              form={form}
              instance={instance}
              name="config.meta"
              label="Meta"
              required={false}
              errorData={errorData}
            >
              <Input.TextArea autosize={{ minRows: 3 }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="config.scripts_header"
              label="Скрипты header"
              required={false}
              errorData={errorData}
            >
              <Input.TextArea autosize={{ minRows: 3 }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="config.scripts_footer"
              label="Скрипты footer"
              required={false}
              errorData={errorData}
            >
              <Input.TextArea autosize={{ minRows: 3 }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="config.metrics"
              label="Метрика"
              required={false}
              errorData={errorData}
            >
              <Input.TextArea autosize={{ minRows: 3 }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="config.robots"
              label="Robots"
              required={false}
              errorData={errorData}
            >
              <Input.TextArea autosize={{ minRows: 3 }}/>
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}
