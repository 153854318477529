import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { arrayIsEqual, tableColumn } from "utils";
import { ActionButton, TableList } from "components/common";
import { CityValue } from 'components/blocks/City';
import { DomainValue, DomainMirrorsValue } from 'components/blocks/Domain';


class SiteList extends Component {
  state = {
    columnList: ['name', 'domain', 'hidden_domain', 'mirrors', 'city', 'actions'],
    columnByName: {
      name: tableColumn('Название', 'name', {
        render: (val, item) => <Link to={`/site/${item.id}`}>{val}</Link>,
        sorted: true,
        width: 200,
      }),
      domain: tableColumn('Домен', 'domain', {
        render: (val, item) => <DomainValue detail={val}/>,
        width: 200,
      }),
      hidden_domain: tableColumn('Скрытый домен', 'hidden_domain', {
        width: 200,
      }),
      mirrors: tableColumn('Зеркала', 'mirrors', {
        render: (val, item) => <DomainMirrorsValue id={item.domain.id}/>,
        width: 200,
      }),
      city: tableColumn('Город', 'city', {
        render: (val, item) => <CityValue detail={val}/>,
        width: 150,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.siteListLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.siteShowEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.siteDelete({ id: item.id })
  };

  render() {
    const { site, defaultFilter, activeFilter, history } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={site}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
      />
    );
  }
}


function mapStateToProps(state) {
  return {
    site: state.site,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.site), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteList);
