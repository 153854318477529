import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/city';


export function* watch() {
  yield takeEvery(actions.CITY_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.CITY_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.CITY_UPDATE.START, actionUpdate);
  yield takeEvery(actions.CITY_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.cityListLoad,
    {
      request: actions.cityListLoadRequest,
      complete: actions.cityListLoadComplete,
      error: actions.cityListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.cityDetailLoad,
    {
      request: actions.cityDetailLoadRequest,
      complete: actions.cityDetailLoadComplete,
      error: actions.cityDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.cityUpdate,
    {
      request: actions.cityUpdateRequest,
      complete: actions.cityUpdateComplete,
      error: actions.cityUpdateError,
    },
    function* (data) {
      yield put(actions.cityShowEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(actions.cityDetailLoad({ id: data.id }));
        yield put(push(`/city/${data.id}`));
      } else {
        const filter = yield select((state) => state.city.filter);
        yield put(actions.cityListLoad(filter));
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.cityDelete,
    {
      request: actions.cityDeleteRequest,
      complete: actions.cityDeleteComplete,
      error: actions.cityDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.city.filter);
      yield put(actions.cityListLoad(filter));
    }
  );
}
