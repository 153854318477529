import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Button } from "antd";

import { actions } from "store";
import { SiteNavList, SiteNavForm } from 'components/blocks/Site';


class SiteNavListScreen extends Component {
  showForm = (item = null) => {
    const { actions } = this.props;
    actions.siteNavShowEditForm({ show: true, object: item });
  };

  render() {
    const { site, history } = this.props;
    const detail = site.detail;

    return (
      <Fragment>
        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            {detail &&
            <SiteNavList
              history={history}
              activeFilter={{ site: detail.id }}
              topActions={[
                <Button type="primary" className="" icon="plus" onClick={() => this.showForm()}>
                  Добавить навигацию
                </Button>
              ]}
            />}
          </div>
        </div>

        {detail &&
        <SiteNavForm siteId={detail.id}/>}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    site: state.site,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.site_nav), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteNavListScreen);
