import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SERVICE_LIST_LOAD = createRequestTypes('SERVICE_LIST_LOAD');
export const SERVICE_DETAIL_LOAD = createRequestTypes('SERVICE_DETAIL_LOAD');
export const SERVICE_UPDATE = createRequestTypes('SERVICE_UPDATE');
export const SERVICE_DELETE = createRequestTypes('SERVICE_DELETE');
export const SERVICE_SHOW_EDIT_FORM = 'SERVICE_SHOW_EDIT_FORM';

export const serviceShowEditForm = createAction(SERVICE_SHOW_EDIT_FORM);

export const serviceListLoad = createAction(SERVICE_LIST_LOAD.START);
export const serviceListLoadRequest = createAction(SERVICE_LIST_LOAD.REQUEST);
export const serviceListLoadComplete = createAction(SERVICE_LIST_LOAD.COMPLETE);
export const serviceListLoadError = createAction(SERVICE_LIST_LOAD.ERROR);

export const serviceDetailLoad = createAction(SERVICE_DETAIL_LOAD.START);
export const serviceDetailLoadRequest = createAction(SERVICE_DETAIL_LOAD.REQUEST);
export const serviceDetailLoadComplete = createAction(SERVICE_DETAIL_LOAD.COMPLETE);
export const serviceDetailLoadError = createAction(SERVICE_DETAIL_LOAD.ERROR);

export const serviceUpdate = createAction(SERVICE_UPDATE.START);
export const serviceUpdateRequest = createAction(SERVICE_UPDATE.REQUEST);
export const serviceUpdateComplete = createAction(SERVICE_UPDATE.COMPLETE);
export const serviceUpdateError = createAction(SERVICE_UPDATE.ERROR);

export const serviceDelete = createAction(SERVICE_DELETE.START);
export const serviceDeleteRequest = createAction(SERVICE_DELETE.REQUEST);
export const serviceDeleteComplete = createAction(SERVICE_DELETE.COMPLETE);
export const serviceDeleteError = createAction(SERVICE_DELETE.ERROR);
