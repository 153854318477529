import React, { Component } from 'react';

import styles from './screen.module.css';


export default class DashboardScreen extends Component {
    render() {
        return (
            <div className={styles.main}>

            </div>
        );
    }
}
