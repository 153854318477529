import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ActionButton } from "components/common";
import { ReviewForm } from 'components/blocks/Review';
import ReviewMainScreen from './ReviewMainScreen';


class ReviewDetailScreen extends Component {
  state = {
    id: null,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.reviewDetailLoad({ id: match.params.id });
    this.setState({ id: match.params.id });
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.reviewShowEditForm({ show: true, object: item });
  };

  delete = () => {
    const { actions } = this.props;
    const { id } = this.state;
    actions.reviewDelete({ id });
  };

  render() {
    const { review, history } = this.props;
    const detail = review.detail || {};

    return (
      <Page>
        <PageHeader
          history={history}
          title={detail.name}
          actions={[
            <Button onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>,
            <ActionButton
              actions={[
                {
                  key: 'delete',
                  icon: <Icon type="delete"/>,
                  title: 'Удалить',
                  action: this.delete,
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}

            />
          ]}
          basePath={`/review/${this.state.id}`}
        />

        <Route exact path="/review/:id" component={ReviewMainScreen}/>

        <ReviewForm/>
      </Page>
    );
  }
}


function mapStateToProps(state) {
  return {
    review: state.review,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.review), dispatch),
    dispatch: dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetailScreen);
