import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SAUNA_LIST_LOAD = createRequestTypes('SAUNA_LIST_LOAD');
export const SAUNA_DETAIL_LOAD = createRequestTypes('SAUNA_DETAIL_LOAD');
export const SAUNA_UPDATE = createRequestTypes('SAUNA_UPDATE');
export const SAUNA_DELETE = createRequestTypes('SAUNA_DELETE');
export const SAUNA_SHOW_EDIT_FORM = 'SAUNA_SHOW_EDIT_FORM';

export const saunaShowEditForm = createAction(SAUNA_SHOW_EDIT_FORM);

export const saunaListLoad = createAction(SAUNA_LIST_LOAD.START);
export const saunaListLoadRequest = createAction(SAUNA_LIST_LOAD.REQUEST);
export const saunaListLoadComplete = createAction(SAUNA_LIST_LOAD.COMPLETE);
export const saunaListLoadError = createAction(SAUNA_LIST_LOAD.ERROR);

export const saunaDetailLoad = createAction(SAUNA_DETAIL_LOAD.START);
export const saunaDetailLoadRequest = createAction(SAUNA_DETAIL_LOAD.REQUEST);
export const saunaDetailLoadComplete = createAction(SAUNA_DETAIL_LOAD.COMPLETE);
export const saunaDetailLoadError = createAction(SAUNA_DETAIL_LOAD.ERROR);

export const saunaUpdate = createAction(SAUNA_UPDATE.START);
export const saunaUpdateRequest = createAction(SAUNA_UPDATE.REQUEST);
export const saunaUpdateComplete = createAction(SAUNA_UPDATE.COMPLETE);
export const saunaUpdateError = createAction(SAUNA_UPDATE.ERROR);

export const saunaDelete = createAction(SAUNA_DELETE.START);
export const saunaDeleteRequest = createAction(SAUNA_DELETE.REQUEST);
export const saunaDeleteComplete = createAction(SAUNA_DELETE.COMPLETE);
export const saunaDeleteError = createAction(SAUNA_DELETE.ERROR);
