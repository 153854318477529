import React, { Component } from 'react';
import { Dropdown, Menu, Icon } from 'antd';

const SEO_TPL_PARTS = {
  profile: [
    {
      value: '[name]',
      title: 'имя',
      example: 'Марина',
    },
    {
      value: '[slug]',
      title: 'путь',
      example: 'marina',
    },
    {
      value: '[text]',
      title: 'текст',
      example: 'текст',
    },
    {
      value: '[contacts.phone]',
      title: 'телефон',
      example: '79999999999',
    },

    {
      value: '[parameters.age]',
      title: 'возраст',
      example: '20 лет',
    },
    {
      value: '[parameters.weight]',
      title: 'вес',
      example: '55кг',
    },
    {
      value: '[parameters.height]',
      title: 'рост',
      example: '170см',
    },
    {
      value: '[parameters.gender]',
      title: 'пол',
      example: 'женский пол',
    },
    {
      value: '[parameters.ethnicity]',
      title: 'внешность',
      example: 'славянская внешность',
    },
    {
      value: '[parameters.hair_color]',
      title: 'цвет волос',
      example: 'светлые волосы',
    },
    {
      value: '[parameters.breast_size]',
      title: 'размер груди',
      example: '1 размера',
    },
    {
      value: '[parameters.breast_type]',
      title: 'тип груди',
      example: 'натуральная грудь',
    },

    {
      value: '[prices.in_hour]',
      title: 'цена 1 час',
      example: '1500руб',
    },

    {
      value: '[city.name]',
      title: 'город',
      example: 'Уфа',
    },

    {
      value: '[districts.1.name]',
      title: 'район',
      example: 'зеленая роща',
    },

    {
      value: '[services.1.name]',
      title: 'услуга',
      example: 'массаж',
    },
  ],

  sauna: [
    {
      value: '[name]',
      title: 'имя',
      example: 'Сауна',
    },
    {
      value: '[slug]',
      title: 'путь',
      example: 'marina',
    },
    {
      value: '[text]',
      title: 'текст',
      example: 'текст',
    },
    {
      value: '[contacts.phone]',
      title: 'телефон',
      example: '79999999999',
    },
    {
      value: '[city.name]',
      title: 'город',
      example: 'Уфа',
    },
  ],

  salon: [
    {
      value: '[name]',
      title: 'имя',
      example: 'Сауна',
    },
    {
      value: '[slug]',
      title: 'путь',
      example: 'marina',
    },
    {
      value: '[text]',
      title: 'текст',
      example: 'текст',
    },
    {
      value: '[contacts.phone]',
      title: 'телефон',
      example: '79999999999',
    },
    {
      value: '[city.name]',
      title: 'город',
      example: 'Уфа',
    },
  ],

  job: [
    {
      value: '[name]',
      title: 'имя',
      example: 'Сауна',
    },
    {
      value: '[slug]',
      title: 'путь',
      example: 'marina',
    },
    {
      value: '[text]',
      title: 'текст',
      example: 'текст',
    },
    {
      value: '[contacts.phone]',
      title: 'телефон',
      example: '79999999999',
    },
    {
      value: '[city.name]',
      title: 'город',
      example: 'Уфа',
    },
  ],

  service: [
    {
      value: '[name]',
      title: 'название',
      example: 'массаж',
      variants: [
        {
          value: '[name2]',
          title: 'название в род. подеже',
          example: 'массажа',
        },
        {
          value: '[name3]',
          title: 'название в дат. подеже',
          example: 'массажу',
        },
        {
          value: '[name4]',
          title: 'название в вин. подеже',
          example: 'массаж',
        },
        {
          value: '[name5]',
          title: 'название в твор. подеже',
          example: 'массажем',
        },
        {
          value: '[name6]',
          title: 'название в пред. подеже',
          example: 'массаже',
        },
      ],
    },
    {
      value: '[slug]',
      title: 'путь',
      example: 'marina',
    },
  ],

  district: [
    {
      value: '[name]',
      title: 'название',
      example: 'зеленая роща',
      variants: [
        {
          value: '[name2]',
          title: 'название в род. подеже',
          example: 'зеленой рощи',
        },
        {
          value: '[name3]',
          title: 'название в дат. подеже',
          example: 'зеленой роще',
        },
        {
          value: '[name4]',
          title: 'название в вин. подеже',
          example: 'зеленую рощу',
        },
        {
          value: '[name5]',
          title: 'название в твор. подеже',
          example: 'зеленой рощей',
        },
        {
          value: '[name6]',
          title: 'название в пред. подеже',
          example: 'зеленой роще',
        },
      ],
    },
    {
      value: '[slug]',
      title: 'путь',
      example: 'marina',
    },
  ],
};

const replaceAll = function (target, search, replacement) {
  return target.split(search).join(replacement);
};

export function renderTpl(tpl, type) {
  let res = tpl;
  const parts = SEO_TPL_PARTS[type] || [];
  parts.forEach(part => {
    res = replaceAll(res, part.value, part.example);
    if (part.variants) {
      part.variants.forEach(subPart => {
        res = replaceAll(res, subPart.value, subPart.example);
      });
    }
  });
  return res;
}


export default class SeoTemplatePartsDropdown extends Component {
  handleClick = (item) => {
    this.props.onChange(item);
  };

  renderItems() {
    const { type } = this.props;
    const parts = SEO_TPL_PARTS[type] || [];
    return (
      <Menu>
        {parts.map((item, idx) => {
          if (item.variants) {
            return (
              <Menu.SubMenu key={`item-p${idx}`} title={item.title}>
                <Menu.Item key={item.value} onClick={() => this.handleClick(item)}>
                  {item.title}
                </Menu.Item>
                {item.variants.map((opt, idx2) => (
                  <Menu.Item key={opt.value} onClick={() => this.handleClick(opt)}>
                    {opt.title}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            )
          } else {
            return (
              <Menu.Item key={item.value} onClick={() => this.handleClick(item)}>{item.title}</Menu.Item>
            )
          }
        })}
      </Menu>
    )
  }

  render() {
    return (
      <Dropdown overlay={this.renderItems()} trigger={['click']} placement="topLeft">
        <Icon type="plus"/>
      </Dropdown>
    );
  }
}
