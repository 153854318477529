import { Component } from 'react';

import { dictIsEqual, loadStates, loadStatus } from "utils";


export default class DataLoadComponent extends Component {
  apiListLoad = null;
  apiDetailLoad = null;

  state = {
    items: [],
    detail: null,
    loadStatus: loadStatus(loadStates.notAsked),
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !dictIsEqual(prevProps.activeFilter, this.props.activeFilter) ||
      prevProps.id !== this.props.id ||
      !dictIsEqual(prevProps.byId, this.props.byId) ||
      !dictIsEqual(prevProps.detail, this.props.detail)
    ) {
      this.load();
    }
  }

  load = () => {
    const { id, byId, detail } = this.props;

    if (id && byId) {
      this.setState({
        detail: byId[id],
        loadStatus: loadStatus(loadStates.loaded),
      });

    } else {
      if (this.apiListLoad) {
        this.listLoad();
      }

      if (id && this.apiDetailLoad) {
        this.detailLoad();
      }

      if (detail) {
        this.setState({
          detail: detail,
          loadStatus: loadStatus(loadStates.loaded),
        });
      }
    }
  };

  listLoad = () => {
    const { auth_token, activeFilter } = this.props;

    this.setState({
      loadStatus: loadStatus(loadStates.loading),
    });

    let filter = Object.assign(activeFilter || {}, { limit: 1000 });

    this.apiListLoad(auth_token, { activeFilter: filter })
      .then(({ error, data }) => {
        if (error) {
          console.log(error);
          this.setState({
            loadStatus: loadStatus(loadStates.failed, error),
          });
        } else {
          this.setState({
            items: data.results,
            loadStatus: loadStatus(loadStates.loaded),
          });
        }
      })
  };

  detailLoad = () => {
    const { auth_token, id } = this.props;

    this.setState({
      loadStatus: loadStatus(loadStates.loading),
    });

    this.apiDetailLoad(auth_token, { id })
      .then(({ error, data }) => {
        if (error) {
          console.log(error);
          this.setState({
            loadStatus: loadStatus(loadStates.failed, error),
          });
        } else {
          this.setState({
            detail: data,
            loadStatus: loadStatus(loadStates.loaded),
          });
        }
      })
  };
}
