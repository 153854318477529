import { handleActions } from 'redux-actions';
import { loadStates, loadStatus } from 'utils/redux';

import * as actions from './actions';


const initialState = {
    loadStatus: loadStatus(loadStates.notAsked),
};

export default handleActions(
    {
        [actions.appDataLoadRequest]: (state, action) => ({
            ...state,
            loadStatus: loadStatus(loadStates.loading),
        }),
        [actions.appDataLoadComplete]: (state, action) => ({
            ...state,
            loadStatus: loadStatus(loadStates.loaded),
        }),
        [actions.appDataLoadError]: (state, action) => ({
            ...state,
            loadStatus: loadStatus(loadStates.failed, action.payload),
        }),
    },
    initialState
);
