import { BASE_URL } from "config";
import { getHeaders } from "utils";
import * as base from "./_base";

export const endpoints = {
    list: `${BASE_URL}/api/v1/domain/`,
    detail: (id) => `${BASE_URL}/api/v1/domain/${id}/`,
    make_main: (id) => `${BASE_URL}/api/v1/domain/${id}/make_main/`,
};


export const domainListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const domainDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const domainUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const domainDelete = (token, data) => base.Delete(endpoints.detail, token, data);

export const domainMakeMain = (token, data) => {
    const url = endpoints.make_main(data.id);
    return base.Load('put', url, data, {
        headers: getHeaders(token),
    });
};
