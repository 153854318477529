import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const REFILL_LIST_LOAD = createRequestTypes('REFILL_LIST_LOAD');
export const REFILL_DETAIL_LOAD = createRequestTypes('REFILL_DETAIL_LOAD');
export const REFILL_UPDATE = createRequestTypes('REFILL_UPDATE');
export const REFILL_SHOW_EDIT_FORM = 'REFILL_SHOW_EDIT_FORM';

export const refillShowEditForm = createAction(REFILL_SHOW_EDIT_FORM);

export const refillListLoad = createAction(REFILL_LIST_LOAD.START);
export const refillListLoadRequest = createAction(REFILL_LIST_LOAD.REQUEST);
export const refillListLoadComplete = createAction(REFILL_LIST_LOAD.COMPLETE);
export const refillListLoadError = createAction(REFILL_LIST_LOAD.ERROR);

export const refillDetailLoad = createAction(REFILL_DETAIL_LOAD.START);
export const refillDetailLoadRequest = createAction(REFILL_DETAIL_LOAD.REQUEST);
export const refillDetailLoadComplete = createAction(REFILL_DETAIL_LOAD.COMPLETE);
export const refillDetailLoadError = createAction(REFILL_DETAIL_LOAD.ERROR);

export const refillUpdate = createAction(REFILL_UPDATE.START);
export const refillUpdateRequest = createAction(REFILL_UPDATE.REQUEST);
export const refillUpdateComplete = createAction(REFILL_UPDATE.COMPLETE);
export const refillUpdateError = createAction(REFILL_UPDATE.ERROR);
