import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, Photo, DateTimeText, Status, ActionButton } from 'components/common';
import { CityValue } from 'components/blocks/City';
import { SiteValue, SiteSelect } from 'components/blocks/Site';


class SaunaList extends Component {
  state = {
    updateItemId: null,
    columnList: ['id', 'photo', 'name', 'city', 'site', 'status', 'views_count', 'date_active', 'date_up', 'actions'],
    columnByName: {
      id: tableColumn('ID', 'id', {
        width: 50,
        sorter: true,
      }),
      photo: tableColumn('Фото', 'photo', {
        render: (text, item) => (
          <Link to={`/sauna/${item.id}`}>
            <Photo url={item.photos.length && item.photos[0].url} width={80} height={60}/>
          </Link>
        ),
        width: 112,
      }),
      name: tableColumn('Имя', 'name', {
        render: (text, item) => <Link to={`/sauna/${item.id}`}>{text}</Link>,
        sorter: true,
      }),
      city: tableColumn('Город', 'city', {
        render: (text, item) => <CityValue detail={item.site.city}/>,
        width: 130,
      }),
      site: tableColumn('Сайт', 'site', {
        render: (text, item) => <SiteValue detail={item.site}/>,
        width: 130,
      }),
      status: tableColumn('Статус', 'status', {
        render: (text, item) => (
          <div>
            <Status status={text}/>
            <Switch
              style={{ marginTop: '5px', minWidth: '62px' }}
              checkedChildren="вкл" unCheckedChildren="выкл"
              checked={item.is_on}
              loading={this.props.sauna.updateStatus.isLoading && this.state.updateItemId === item.id}
              onClick={() => this.saunaOnOff(item)}
            />
          </div>
        ),
        width: 120,
      }),
      views_count: tableColumn('Просм.', 'views_count', {
        width: 90,
        sorter: true,
      }),
      date_active: tableColumn('Активна до', 'date_active', {
        render: (text, item) => <DateTimeText date={text}/>,
        width: 112,
        sorter: true,
      }),
      date_up: tableColumn('Дата поднятия', 'date_up', {
        render: (text, item) => <DateTimeText date={text}/>,
        width: 112,
        sorter: true,
      }),

      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    const { sauna } = this.props;
    if (activeFilter === undefined) {
      activeFilter = sauna.filter.activeFilter
    }
    this.props.actions.saunaListLoad({ query, activeFilter, locationChange: true });
  };

  showEditForm = (item) => {
    this.props.actions.saunaShowEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.saunaDelete({ id: item.id })
  };

  saunaOnOff = (item) => {
    const data = { id: item.id, is_on: !item.is_on, preventLoadDetail: true };
    this.setState({
      updateItemId: item.id,
    });
    this.props.actions.saunaUpdate(data);
  };

  addPage = () => {
    const { actions, sauna } = this.props;
    actions.pageShowEditForm({ show: true, object: null, editInitial: { sauna_filter: sauna.query } });
  };

  showForm = (item = null) => {
    const { actions } = this.props;
    actions.saunaShowEditForm({ show: true, object: item });
  };

  render() {
    const { history, defaultFilter, activeFilter, topActions, sauna } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={sauna}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        topActions={topActions}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}

        onRow={(item, rowIndex) => {
          return {
            onDoubleClick: event => {
              this.props.history.push(`/sauna/${item.id}`);
            },
          };
        }}

        showSearch={true}
        filtersAction={[
          <Button key="addPage" size="small" type="primary" onClick={this.addPage}>
            <Icon type="save"/>
            <span>Создать страницу</span>
          </Button>
        ]}
        filterFields={{
          list: [
            'has_reviews', 'site',
          ],
          byName: {
            'has_reviews': {
              label: 'С отзывами',
              type: 'bool',
            },
            'site': {
              label: 'Сайт',
              type: 'list_int',
              component: SiteSelect,
              valueComponent: SiteValue,
            },
          }
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    sauna: state.sauna,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.sauna, actions.page), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaunaList);
