import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ActionButton } from "components/common";
import { UserForm } from 'components/blocks/User';
import UserMainScreen from './UserMainScreen';


class UserDetailScreen extends Component {
  state = {
    id: null,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.userDetailLoad({ id: match.params.id });
    this.setState({ id: match.params.id });
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.userShowEditForm({ show: true, object: item });
  };

  delete = () => {
    const { actions } = this.props;
    const { id } = this.state;
    actions.userDelete({ id });
  };

  render() {
    const { user, history } = this.props;
    const detail = user.detail || {};

    return (
      <Page>
        <PageHeader
          history={history}
          title={detail.email}
          actions={[
            <Button onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>,
            <ActionButton
              actions={[
                {
                  key: 'delete',
                  icon: "delete",
                  title: 'Удалить',
                  action: this.delete,
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}

            />
          ]}
          basePath={`/user/${this.state.id}`}
        />

        <Route exact path="/user/:id" component={UserMainScreen}/>

        <UserForm/>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.user), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailScreen);
