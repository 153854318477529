import { put, call, takeEvery, all } from 'redux-saga/effects';

import * as actions from './actions';
import { actions as AuthActions } from 'store/auth';
import { sagas as UserSagas } from 'store/user';
import { getToken } from 'utils/helpers';
import { apiAuthTokenCheck } from 'store/api/auth';


export function* watchAppInit() {
  yield takeEvery(actions.APP_INIT, appInit);
}

export function* watchAppDataLoad() {
  yield takeEvery(actions.APP_DATA_LOAD.START, appDataLoad);
}


function* appInit(action) {
  const tokenOld = getToken();
  const token = yield call(() => apiAuthTokenCheck(tokenOld));
  yield put(actions.appDataLoad({ token, authorize: true }));
}


function* appDataLoad(action) {
  yield put(actions.appDataLoadRequest());
  const { token, authorize } = action.payload;

  try {
    if (token) {
      if (authorize) yield put(AuthActions.authLoginComplete({ token }));
      yield all([
        call(UserSagas.currentUserLoad, { token }),
      ]);
      yield put(actions.appDataLoadComplete());
    } else {
      yield put(actions.appDataLoadComplete());
    }

  } catch (error) {
    yield put(actions.appDataLoadError());
  }
}
