import React, { Component } from 'react';
import { Layout, Icon, Breadcrumb } from "antd";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getBreadcrumbs } from "utils";
import { HeaderUserPanel } from 'components/common';
import styles from './header.module.css';


const routes = [
  { path: '/', title: ({ id, detail }) => 'Home' },
  { path: '/city', title: ({ id, detail }) => 'Города' },
  {
    path: '/city/:id',
    title: ({ id, detail }) => (detail && detail.name) || id,
    detail: ({ id, state }) => state.city && (state.city.byId[id] || state.city.detail)
  },

  { path: '/site', title: ({ id, detail }) => 'Сайты' },
  {
    path: '/site/:id',
    title: ({ id, detail }) => (detail && detail.name) || id,
    detail: ({ id, state }) => state.site && (state.site.byId[id] || state.site.detail)
  },

  { path: '/domain', title: ({ id, detail }) => 'Домены' },
  {
    path: '/domain/:id',
    title: ({ id, detail }) => (detail && detail.domain) || id,
    detail: ({ id, state }) => state.domain && (state.domain.byId[id] || state.domain.detail)
  },

  { path: '/service', title: ({ id, detail }) => 'Услуги' },

  { path: '/profile', title: ({ id, detail }) => 'Анкеты' },
  {
    path: '/profile/:id',
    title: ({ id, detail }) => (detail && detail.name) || id,
    detail: ({ id, state }) => state.profile && (state.profile.byId[id] || state.profile.detail)
  },

  { path: '/sauna', title: ({ id, detail }) => 'Сауны' },
  {
    path: '/sauna/:id',
    title: ({ id, detail }) => (detail && detail.name) || id,
    detail: ({ id, state }) => state.sauna && (state.sauna.byId[id] || state.sauna.detail)
  },

  { path: '/salon', title: ({ id, detail }) => 'Салоны' },
  {
    path: '/salon/:id',
    title: ({ id, detail }) => (detail && detail.name) || id,
    detail: ({ id, state }) => state.salon && (state.salon.byId[id] || state.salon.detail)
  },

  { path: '/job', title: ({ id, detail }) => 'Вакансии' },
  {
    path: '/job/:id',
    title: ({ id, detail }) => (detail && detail.name) || id,
    detail: ({ id, state }) => state.job && (state.job.byId[id] || state.job.detail)
  },

  { path: '/balance', title: ({ id, detail }) => 'Балансы пользователей' },
  {
    path: '/balance/:id',
    title: ({ id, detail }) => (detail && detail.user.email) || id,
    detail: ({ id, state }) => state.balance && (state.balance.byId[id] || state.balance.detail)
  },

  { path: '/transaction', title: ({ id, detail }) => 'Транзакции' },

  { path: '/user', title: ({ id, detail }) => 'Пользователи' },
  {
    path: '/user/:id',
    title: ({ id, detail }) => (detail && detail.email) || id,
    detail: ({ id, state }) => state.user && (state.user.byId[id] || state.user.detail)
  },

  { path: '/review', title: ({ id, detail }) => 'Отзывы' },
  {
    path: '/review/:id',
    title: ({ id, detail }) => (detail && detail.name) || id,
    detail: ({ id, state }) => state.review && (state.review.byId[id] || state.review.detail)
  },
];


class Header extends Component {
  itemRender = (item, params, routes, paths) => {
    const detail = item.detail ? item.detail({ id: item.match.params.id, state: this.props }) : null;
    const _params = Object.assign({}, item.match.params, { detail });

    const icon = item.icon;
    const title = item.title(_params);

    const icon_comp = icon && <Icon type={icon}/>;
    const title_comp = title && <span>{title}</span>;

    return <Link to={item.path}>{icon_comp}{title_comp}<span className="arrow"/></Link>;
  };

  render() {
    const { history } = this.props;

    const breadcrumbs = getBreadcrumbs({ routes, location: history.location });

    return (
      <Layout.Header className={styles.header}>
        <Breadcrumb className={styles.headerLeft} itemRender={this.itemRender} routes={breadcrumbs}/>

        <div className={styles.headerRight}>
          <HeaderUserPanel/>
        </div>
      </Layout.Header>
    );
  }
}


function mapStateToProps(state) {
  return {
    city: state.city,
    site: state.site,
    domain: state.domain,
    profile: state.profile,
    sauna: state.sauna,
    salon: state.salon,
    job: state.job,
    user: state.user,
    balance: state.balance,
    transaction: state.transaction,
    review: state.review,
  };
}

export default connect(mapStateToProps)(Header);
