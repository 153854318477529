import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button } from 'antd';
import classNames from 'classnames';

import { actions } from 'store';
import { getUrlParams } from 'utils/helpers';

import styles from './login.module.css';


class LoginForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, location_search } = this.props;

        const next = getUrlParams(location_search).next;
        actions.authLogin({ data: values, next });
      }
    });
  };

  getValidateStatus = (fieldName, errorData) => {
    if (fieldName in errorData) {
      return {
        validateStatus: "error",
        help: errorData[fieldName]
      }
    }
    return {};
  };

  render() {
    const { auth, className } = this.props;
    const { getFieldDecorator } = this.props.form;

    const cx = classNames([
      styles.main,
      className,
    ]);

    const error = auth.loginStatus.error || {};
    const errorData = error.data || {};

    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit} className={cx}>
          {errorData.non_field_errors &&
          <div className={styles.error}>{errorData.non_field_errors}</div>}

          <Form.Item {...this.getValidateStatus('username', errorData)}>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Введите логин' }],
            })(
              <Input
                prefix={<Icon type="user"/>}
                placeholder="Логин"
                size="large"
              />
            )}
          </Form.Item>

          <Form.Item {...this.getValidateStatus('password', errorData)}>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Введите пароль' }],
            })(
              <Input
                prefix={<Icon type="lock"/>}
                type="password"
                placeholder="Пароль"
                size="large"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.button} size="large">
              Войти
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    );
  }
}

const WrappedLoginForm = Form.create()(LoginForm);

function mapStateToProps(state) {
  return {
    auth: state.auth,
    location_search: state.router.location.search,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.auth), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedLoginForm);
