import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { RefillForm } from 'components/blocks/Balance';
import BalanceMainScreen from './BalanceMainScreen';
import BalanceTransactionListScreen from './BalanceTransactionListScreen';


class BalanceDetailScreen extends Component {
  state = {
    id: null,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.balanceDetailLoad({ id: match.params.id });
    this.setState({ id: match.params.id });
  }

  showForm = () => {
    const { actions } = this.props;
    actions.refillShowEditForm({ show: true });
  };

  render() {
    const { history, balance } = this.props;
    const detail = balance.detail || {};
    const user = detail.user || {};

    return (
      <Page>
        <PageHeader
          history={history}
          title={user.email}
          actions={[
            <Button onClick={() => this.showForm(detail)}>
              <Icon type="plus"/>
              <span>Пополнить</span>
            </Button>
          ]}
          basePath={`/balance/${this.state.id}`}
          tabs={[
              { title: 'Детали', key: 'detail' },
              { title: 'Транзакции', key: 'transaction' },
          ]}
        />

        <Route exact path="/balance/:id" component={BalanceMainScreen}/>
        <Route exact path="/balance/:id/transaction" component={BalanceTransactionListScreen}/>

        {user.id &&
        <RefillForm user_id={user.id}/>}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    balance: state.balance,
    refill: state.refill,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.balance, actions.refill), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceDetailScreen);
