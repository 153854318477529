import { put, takeEvery, select } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/service_group';


export function* watch() {
  yield takeEvery(actions.SERVICE_GROUP_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.SERVICE_GROUP_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.SERVICE_GROUP_UPDATE.START, actionUpdate);
  yield takeEvery(actions.SERVICE_GROUP_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.serviceGroupListLoad,
    {
      request: actions.serviceGroupListLoadRequest,
      complete: actions.serviceGroupListLoadComplete,
      error: actions.serviceGroupListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.serviceGroupDetailLoad,
    {
      request: actions.serviceGroupDetailLoadRequest,
      complete: actions.serviceGroupDetailLoadComplete,
      error: actions.serviceGroupDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.serviceGroupUpdate,
    {
      request: actions.serviceGroupUpdateRequest,
      complete: actions.serviceGroupUpdateComplete,
      error: actions.serviceGroupUpdateError,
    },
    function* (data) {
      yield put(actions.serviceGroupShowEditForm({ show: false }));
      const filter = yield select((state) => state.service_group.filter);
      yield put(actions.serviceGroupListLoad(filter));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.serviceGroupDelete,
    {
      request: actions.serviceGroupDeleteRequest,
      complete: actions.serviceGroupDeleteComplete,
      error: actions.serviceGroupDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.service_group.filter);
      yield put(actions.serviceGroupListLoad(filter));
    }
  );
}
