import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { tableColumn, arrayIsEqual } from "utils";
import { TableList, ActionButton } from "components/common";
import { CityValue } from 'components/blocks/City';


class CityList extends Component {
  state = {
    columnList: ['name', 'slug', 'district_count', 'profile_count', 'actions'],
    columnByName: {
      name: tableColumn('Название', 'name', {
        render: (text, item) => <CityValue detail={item} asLink={true}/>,
        sorter: true,
      }),
      slug: tableColumn('Путь', 'slug'),
      district_count: tableColumn('Кол-во районов', 'district_count', {
        width: 150,
        sorter: true,
      }),
      profile_count: tableColumn('Кол-во анкет', 'profile_count', {
        width: 150,
        sorter: true,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.cityListLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.cityShowEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.cityDelete({ id: item.id })
  };

  render() {
    const { city, defaultFilter, activeFilter, history } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={city}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        onRow={(item, rowIndex) => {
          return {
            onDoubleClick: event => {
              this.props.history.push(`/city/${item.id}`);
            },
          };
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.city), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityList);
