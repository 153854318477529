import React from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';

import { profileListLoad } from "store/api/profile";
import { FormItem } from "utils";
import { DataLoadComponent, Photo } from 'components/common';


class ProfileSelect extends DataLoadComponent {
  apiListLoad = profileListLoad;

  render() {
    const { form, instance, name, label, required, errorData, disabled, onChange } = this.props;
    const { items, loadStatus } = this.state;
    const initialValue = instance && instance.id ? null : this.props.initialValue;

    return (
      <FormItem
        form={form}
        instance={instance}
        name={name}
        label={label}
        required={required}
        errorData={errorData}
        initialValue={initialValue}
      >
        <Select
          style={{ width: 200 }}
          loading={loadStatus.isLoading}
          onChange={onChange}
          showSearch={true}
          optionFilterProp="children"
          filterOption={(input, option) => {
            const children = option.props.children.props.children[1].props.children;
            return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
          disabled={disabled}
        >
          {!required && <Select.Option value={null}>---</Select.Option>}
          {items.map(item => (
            <Select.Option key={`profile${item.id}`} value={item.id}>
              <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}>
                <Photo
                  style={{ borderRadius: 15, marginRight: 10 }}
                  url={item.photos.length && (item.photos[0].small || item.photos[0].url)}
                  width={30} height={30}
                />
                <span style={{ marginLeft: 10 }}>{item.name}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    );
  }
}


function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}


export default connect(mapStateToProps)(ProfileSelect);
