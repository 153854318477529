import React, { Component } from 'react';
import { Table, Icon, Button, Popconfirm } from 'antd';
import Measure from 'react-measure'

import { getObjectList, getNoun, getUrlParams, ordering } from "utils";


export default class ServiceGroupList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dimensions: {
                width: -1,
                height: -1,
            },
            search: null,
            params: { ordering: 'name' },
        }
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.search !== this.state.search) {
            this.load();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.location.search === prevState.search) {
            return null;
        }

        return {
            search: nextProps.location.search,
            params: getUrlParams(nextProps.location.search)
        }
    }

    load = () => {
        this.props.actions.serviceGroupLoad(this.state.params);
    };

    handleTableChange = (pagination, filters, sorter) => {
        const order = o => o === 'descend' ? '-' : '';
        const ordering = sorter.field ? `ordering=${order(sorter.order)}${sorter.field}` : '';
        console.log(filters);

        let filtering = '';
        for (let field in filters) {
            const value = filters[field][0];
            if (value !== undefined) {
                filtering += `${field}=${value}`;
            }
        }

        this.props.history.push({
            search: `?${ordering}&${filtering}`,
        });
    };

    render() {
        const { service_group, actions } = this.props;
        const dataSource = getObjectList(service_group);
        const { loadStatus, pageSize, count } = service_group;

        const { height } = this.state.dimensions;

        const { params } = this.state;

        const columns = [
            {
                title: 'Нзвание',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                sortOrder: ordering('name', params.ordering),
            }, {
                title: 'Путь',
                dataIndex: 'slug',
                key: 'slug',
                width: 150,
                sorter: true,
                sortOrder: ordering('slug', params.ordering),
            }, {
                title: 'Сортировка',
                dataIndex: 'sort',
                key: 'sort',
                width: 150,
                sorter: true,
                sortOrder: ordering('sort', params.ordering),
            }, {
                title: 'Действия',
                dataIndex: 'actions',
                key: 'actions',
                width: 150,
                render: (text, item) => {
                    return (
                        <div>
                            <Button
                                size="small"
                                onClick={() => actions.serviceGroupShowEditForm({ show: true, object: item })}
                            >
                                <Icon type="edit"/>
                            </Button>

                            <span>&nbsp;&nbsp;&nbsp;</span>

                            <Popconfirm
                                placement="bottomLeft" title="Уверены, что хотите удалить?"
                                okText="Да" cancelText="Нет"
                                onConfirm={() => actions.serviceGroupDelete({ id: item.id })}
                            >
                                <Button type="danger" size="small">
                                    <Icon type="delete"/>
                                </Button>
                            </Popconfirm>

                        </div>
                    )
                }
            }
        ];

        return (
            <Measure
                bounds
                onResize={contentRect => {
                    this.setState({ dimensions: contentRect.bounds })
                }}
            >
                {({ measureRef }) => (
                    <div ref={measureRef} className="page-block-content">
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            rowKey="id"
                            loading={loadStatus.isLoading}
                            scroll={{ y: height - 96 }}
                            style={{ height: `${height}px` }}
                            pagination={{ pageSize: pageSize }}
                            onChange={this.handleTableChange}
                        />
                        <div className="table-stat">
                            {count} {getNoun(count, 'домен', 'домена', 'доменов')}
                        </div>
                    </div>
                )}
            </Measure>
        );
    }
}
