import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.jobShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.jobListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.jobListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.jobListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.jobDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.jobDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.jobDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.jobUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.jobUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.jobUpdateError]: (state, action) => base.updateError(state, action),

    [actions.jobDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.jobDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.jobDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
