import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd';

import { getObjectList, getUrlSearch, getUrlParams, dictIsEqual, getColumns, arrayIsEqual } from "utils";
import { TableTop, Filter } from 'components/common';


class TableList extends Component {
  constructor(props, context) {
    super(props, context);
    const { columnList, columnByName } = props;
    this.state = {
      columnList: columnList,
      columns: getColumns(columnByName, columnList, ''),
      orderField: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data, columnList, columnByName } = nextProps;
    const { params } = data.filter;
    if (!arrayIsEqual(columnList, prevState.columnList) || (params.ordering !== prevState.orderField)) {
      return {
        columnList: columnList,
        columns: getColumns(columnByName, columnList, params.ordering),
        orderField: params.ordering,
      }
    }
    return null;
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!dictIsEqual(this.props.activeFilter, prevProps.activeFilter)) {
      this.loadData()
    }
  }

  loadData = () => {
    const { history, data, defaultFilter, activeFilter } = this.props;
    let query = data.filter.query;
    if (query === null) {
      if (!history.location.search) {
        query = `?${getUrlSearch(defaultFilter)}`;
      } else {
        query = history.location.search;
      }
    }
    this.props.onLoadData({ query, activeFilter });
  };

  onChange = (pagination, filters, sorter) => {
    const { history, activeFilter } = this.props;

    let params = getUrlParams(history.location.search);
    const order = o => o === 'descend' ? '-' : '';
    params.ordering = sorter.field ? `${order(sorter.order)}${sorter.field}` : '';
    params.offset = (pagination.current - 1) * pagination.pageSize;

    const query = `?${getUrlSearch(params)}`;
    this.props.onLoadData({ query, activeFilter });
  };

  render() {
    const { history, data, filterFields, onLoadData, filtersAction, topActions, showSearch, showPrint, title, dataSource, dragSorting, hideNav, ...props } = this.props;
    const { loadListStatus, pageSize, count, filter } = data;
    const dataList = dataSource ? dataSource : getObjectList(data);
    const offset = parseInt(filter.params.offset) || 0;
    const pageSize2 = pageSize < dataList.length ? dataList.length : pageSize;
    const pagination = hideNav ? false : { pageSize: pageSize2, total: count, current: offset / pageSize + 1 };

    return (
      <div className="page-block-content">
        <TableTop
          onLoadData={onLoadData}
          data={data}
          filterFields={filterFields}
          filtersAction={filtersAction}
          topActions={topActions}
          showSearch={showSearch}
        />

        <Table
          title={title}
          columns={this.state.columns}
          dataSource={dataList}
          rowKey="id"
          loading={loadListStatus.isLoading}
          pagination={pagination}
          onChange={this.onChange}
          {...props}
        />
        {!hideNav &&
        <div className="table-stat">Показано {pageSize} из {count}</div>}

        {filterFields &&
        <Filter onLoadData={onLoadData} data={data} fields={filterFields}/>}
      </div>
    );
  }
}

export default withRouter(TableList);
