import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';

import { districtDetailLoad, districtListLoad } from "store/api/district";
import { DataLoadComponent } from 'components/common';


class DistrictValue extends DataLoadComponent {
  apiListLoad = districtListLoad;
  apiDetailLoad = districtDetailLoad;

  renderDetail = (item) => {
    const value = (item && item.name) || '';
    return (
      <span>{value}</span>
    );
  };

  renderList = (items) => {
    return items.map((item, index) => (
      <span key={`district${item.id}`}>
        {index > 0 ? <span>, </span> : ''}
        {this.renderDetail(item)}
       </span>
    ))
  };

  render() {
    const { id } = this.props;
    const { detail, items, loadStatus } = this.state;

    return (
      <Spin spinning={loadStatus.isLoading}>
        {id && this.renderDetail(detail)}
        {items.length > 0 && this.renderList(items)}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth_token: state.auth.token,
  };
}

export default connect(mapStateToProps)(DistrictValue);
