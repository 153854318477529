import React from 'react';
import { Form } from 'antd';


function getValidateStatus(fieldName, errorData) {
  if (fieldName in errorData) {
    let error = errorData[fieldName];
    if (error.non_field_errors) {
      error = error.non_field_errors;
    }
    return {
      validateStatus: "error",
      help: error,
    }
  }
  return {};
}


export const FormItem = props => {
  const { form, name, label, required, errorData, instance, initialValue, valuePropName, className, extra, convertValue } = props;
  const { getFieldDecorator } = form;

  let value;
  if (instance) {
    value = instance;
    name.split('.').forEach(n => {
      value = value && value[n];
    });

    if (convertValue) {
      value = convertValue(value);
    }
  }

  let params = {
    rules: [{ required: required, message: 'Обязательное поле' }],
    initialValue: value || initialValue
  };
  if (valuePropName) {
    params.valuePropName = valuePropName;
  }

  return (
    <Form.Item
      className={className}
      {...getValidateStatus(name, errorData || {})}
      label={label}
      extra={extra}
    >
      {getFieldDecorator(name, params)(
        props.children
      )}
    </Form.Item>
  )
};
