import { put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router'

import * as actions from './actions';
import { actions as AppActions } from 'store/app';
import { setToken, removeToken } from 'utils/helpers';
import { apiAuthLogin } from 'store/api/auth';


export function* watchAuthLogin() {
    yield takeEvery(actions.AUTH_LOGIN.START, login);
}


function* login(action) {
    yield put(actions.authLoginRequest());
    const next = action.payload.next || '/';

    try {
        const { error, data, statusCode } = yield call(() => apiAuthLogin(action.payload.data));
        const { token } = data;

        if (error) {
            yield put(actions.authLoginError({ data, statusCode }));
        } else {
            setToken(token);
            yield put(actions.authLoginComplete(data));
            yield put(AppActions.appDataLoad({ token }));
            yield put(push(next))
        }

    } catch (error) {
        yield put(actions.authLoginError());
    }
}

export function* watchAuthLogout() {
    yield takeEvery(actions.AUTH_LOGOUT, logout);
}

function* logout(action) {
    removeToken();
    yield put(push('/login'));
}



