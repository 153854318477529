import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { SaunaList, SaunaForm } from 'components/blocks/Sauna';


class SaunaListScreen extends Component {
  state = {
    activeStatus: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { history } = nextProps;
    const parts = history.location.pathname.replace("/sauna", '').split('/');
    const activeStatus = parts.length >= 2 ? parts[parts.length - 1].replace('status', '') : null;
    if (prevState.activeStatus !== activeStatus) {
      return {
        activeStatus: activeStatus,
      };
    }
    return null;
  };

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.saunaShowEditForm({ show: true, object: item });
  };

  onTabChange = (key) => {
    const url = key === 'detail' ? `/sauna` : `/sauna/${key}`;
    this.props.history.push(url);
  };

  render() {
    const { sauna, history } = this.props;
    const info = sauna.info || {};
    const { activeStatus } = this.state;

    return (
      <Page>
        <PageHeader
          title="Сауны"
          actions={[
            <Button type="primary" className="" icon="plus" onClick={() => this.showEditForm()}>
              Добавить сауну
            </Button>
          ]}
          history={history}
          basePath={`/sauna`}
          tabPathPrefix="?status="
          onTabChange={this.onTabChange}
          tabs={[
            { title: 'Все', count: info['all'], key: 'detail' },
            { title: 'Новые', count: info['0'], key: 'status0' },
            { title: 'На проверке', count: info['1'], key: 'status1' },
            { title: 'Проверенные', count: info['3'], key: 'status3' },
            { title: 'Отклонённые', count: info['4'], key: 'status4' },
            { title: 'Забаненные', count: info['5'], key: 'status5' },
          ]}
        />

        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            <SaunaList
              history={history}
              activeFilter={activeStatus ? { status: activeStatus } : null}
            />
          </div>
        </div>

        <SaunaForm/>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    sauna: state.sauna,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.sauna), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaunaListScreen);
