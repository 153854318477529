import React, { PureComponent } from 'react';
import classNames from 'classnames';

import styles from './status.module.css';

export const STATUS = {
  0: 'новая',
  1: 'на проверке',
  3: 'проверена',
  4: 'отклонена',
  5: 'забанена',
};

export default class Status extends PureComponent {
  render() {
    const { status } = this.props;

    const cx = classNames([
      styles.status,
      styles[`s${status}`],
    ]);

    return (
      <div className={cx}>{STATUS[status] || status}</div>
    );
  }
}
