import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/profile_salon';


export function* watch() {
  yield takeEvery(actions.SALON_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.SALON_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.SALON_UPDATE.START, actionUpdate);
  yield takeEvery(actions.SALON_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.salonListLoad,
    {
      request: actions.salonListLoadRequest,
      complete: actions.salonListLoadComplete,
      error: actions.salonListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.salonDetailLoad,
    {
      request: actions.salonDetailLoadRequest,
      complete: actions.salonDetailLoadComplete,
      error: actions.salonDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.salonUpdate,
    {
      request: actions.salonUpdateRequest,
      complete: actions.salonUpdateComplete,
      error: actions.salonUpdateError,
    },
    function* (data) {
      yield put(actions.salonShowEditForm({ show: false }));
      if (!action.payload.preventLoadDetail) {
        yield put(actions.salonDetailLoad({ id: data.id }));
        yield put(push(`/salon/${data.id}`));
      } else {
        const filter = yield select((state) => state.salon.filter);
        yield put(actions.salonListLoad(filter));
      }
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.salonDelete,
    {
      request: actions.salonDeleteRequest,
      complete: actions.salonDeleteComplete,
      error: actions.salonDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.salon.filter);
      yield put(actions.salonListLoad(filter));
    }
  );
}
