import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { arrayIsEqual, tableColumn } from "utils";
import { ActionButton, TableList } from "components/common";


class SeoTemplatePhotoList extends Component {
  state = {
    columnList: ['tpl_path', 'tpl_alt', 'actions'],
    columnByName: {
      tpl_path: tableColumn('Путь', 'tpl_path', {
        sorter: true,
      }),
      tpl_alt: tableColumn('Alt', 'tpl_alt', {
        sorter: true,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.seoTemplatePhotoListLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.seoTemplatePhotoShowEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.seoTemplatePhotoDelete({ id: item.id })
  };

  render() {
    const { history, defaultFilter, activeFilter, topActions, seo_template_photo } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={seo_template_photo}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        topActions={topActions}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    seo_template_photo: state.seo_template_photo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.seo_template_photo), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeoTemplatePhotoList);