import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as base from "../BaseReducers";

const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.reviewShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.reviewListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.reviewListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.reviewListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.reviewDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.reviewDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.reviewDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.reviewUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.reviewUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.reviewUpdateError]: (state, action) => base.updateError(state, action),

    [actions.reviewDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.reviewDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.reviewDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
