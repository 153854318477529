import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Input, Select } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { FormItem } from 'utils/form_helpers';
import { Error } from "components/common";


class SiteNavForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, site_nav, siteId } = this.props;
        const instance = site_nav.editObject;

        let data = Object.assign({ site: siteId }, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        actions.siteNavUpdate(data);
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.siteNavShowEditForm({ show: false });
  };

  render() {
    const { form, site_nav } = this.props;
    const { showEditForm, updateStatus, editObject } = site_nav;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = site_nav.updateStatus.isLoading;

    return (
      <Modal
        visible={showEditForm}
        title="Редактирование навигации"
        onCancel={this.close}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit}>
            <Error errors={errorData.non_field_errors}/>

            <FormItem
              form={form}
              instance={instance}
              name="type"
              label="Тип"
              required={true}
              errorData={errorData}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Выберите"
              >
                <Select.Option value='top'>Top</Select.Option>
                <Select.Option value='left'>Left</Select.Option>
                <Select.Option value='right'>Right</Select.Option>
                <Select.Option value='bottom'>Bottom</Select.Option>
              </Select>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="name"
              label="Название"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="path"
              label="Путь"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="sort"
              label="Сортировка"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(SiteNavForm);

function mapStateToProps(state) {
  return {
    site_nav: state.site_nav,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.site_nav), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);

