import React, { Component } from 'react';
import { Table, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { ActionButton, DateTimeText, FieldValue } from "components/common";


class DomainMainScreen extends Component {
  showEditForm = (item) => {
    this.props.actions.domainShowEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.domainDelete({ id: item.id })
  };

  domainMakeMain = (item) => {
    this.props.actions.domainMakeMain({ id: item.id })
  };

  render() {
    const { domain } = this.props;
    const detail = domain.detail || {};

    return (
      <div className="page-content page-content-full">
        <div className="page-block">
          <FieldValue label="Домен" labelWidth={5}>
            {detail.domain}
          </FieldValue>

          <FieldValue label="Дата регистрации" labelWidth={5}>
            <DateTimeText date={detail.date_created} onlyDate={true}/>
          </FieldValue>

          <FieldValue label="Дата продления" labelWidth={5}>
            <DateTimeText date={detail.date_ended} onlyDate={true}/>
          </FieldValue>

          <FieldValue label="Заблокирован" labelWidth={5}>
            {detail.is_blocked ?
              <Icon type="close-circle" theme="filled" style={{ color: '#ff0000' }}/> : 'Нет'}
          </FieldValue>

          {detail.block_records && detail.block_records.length > 0 &&
          <Table
            columns={[
              {
                title: 'Дата',
                dataIndex: 'date',
                key: 'date',
                width: 150,
                render: (val, item) => <DateTimeText date={val} onlyDate={true}/>,
              }, {
                title: 'Гос. орган',
                dataIndex: 'gos_organ',
                key: 'gos_organ',
                width: 150,
              }, {
                title: 'Постановление',
                dataIndex: 'postanovlenie',
                key: 'postanovlenie',
                width: 150,
              }
            ]}
            dataSource={detail.block_records}
            pagination={false}
            rowKey="postanovlenie"
          />}
        </div>

        <br/>

        {detail.mirrors && detail.mirrors.length > 0 &&
        <div className="page-block">
          <div className="page-block-title">Зеркала</div>

          <Table
            columns={[
              {
                title: 'Домен',
                dataIndex: 'domain',
                key: 'domain',
              },
              {
                title: 'Дата регистрации',
                dataIndex: 'date_created',
                key: 'date_created',
                width: 120,
                render: (text, item) => <DateTimeText date={text} onlyDate={true}/>,
              },
              {
                title: 'Дата продления',
                dataIndex: 'date_ended',
                key: 'date_ended',
                width: 120,
                render: (text, item) => <DateTimeText date={text} onlyDate={true}/>,
              },
              {
                title: 'Заблокирован',
                dataIndex: 'is_blocked',
                key: 'is_blocked',
                width: 100,
                render: (text, item) => text &&
                  <Icon type="close-circle" theme="filled" style={{ color: '#ff0000' }}/>,
              },
              {
                title: 'Действия',
                dataIndex: 'actions',
                key: 'actions',
                width: 130,
                align: 'right',
                render: (val, item) => (
                  <ActionButton
                    actions={[{
                      title: 'Сделать главным',
                      key: 'make_main',
                      icon: 'up-square',
                      action: () => this.domainMakeMain(item),
                    }, {
                      title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
                    }, {
                      title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
                      confirm: {
                        title: 'Уверены, что хотите удалить?',
                        okText: 'Удалить',
                        cancelText: 'Отмена'
                      }
                    }]}
                  />
                )
              }
            ]}
            dataSource={detail.mirrors}
            pagination={false}
            rowKey="id"
          />
        </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    domain: state.domain,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.domain), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainMainScreen);
