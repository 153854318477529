import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SEO_TEMPLATE_PAGE_LIST_LOAD = createRequestTypes('SEO_TEMPLATE_PAGE_LIST_LOAD');
export const SEO_TEMPLATE_PAGE_DETAIL_LOAD = createRequestTypes('SEO_TEMPLATE_PAGE_DETAIL_LOAD');
export const SEO_TEMPLATE_PAGE_UPDATE = createRequestTypes('SEO_TEMPLATE_PAGE_UPDATE');
export const SEO_TEMPLATE_PAGE_DELETE = createRequestTypes('SEO_TEMPLATE_PAGE_DELETE');
export const SEO_TEMPLATE_PAGE_SHOW_EDIT_FORM = 'SEO_TEMPLATE_PAGE_SHOW_EDIT_FORM';

export const seoTemplatePageShowEditForm = createAction(SEO_TEMPLATE_PAGE_SHOW_EDIT_FORM);

export const seoTemplatePageListLoad = createAction(SEO_TEMPLATE_PAGE_LIST_LOAD.START);
export const seoTemplatePageListLoadRequest = createAction(SEO_TEMPLATE_PAGE_LIST_LOAD.REQUEST);
export const seoTemplatePageListLoadComplete = createAction(SEO_TEMPLATE_PAGE_LIST_LOAD.COMPLETE);
export const seoTemplatePageListLoadError = createAction(SEO_TEMPLATE_PAGE_LIST_LOAD.ERROR);

export const seoTemplatePageDetailLoad = createAction(SEO_TEMPLATE_PAGE_DETAIL_LOAD.START);
export const seoTemplatePageDetailLoadRequest = createAction(SEO_TEMPLATE_PAGE_DETAIL_LOAD.REQUEST);
export const seoTemplatePageDetailLoadComplete = createAction(SEO_TEMPLATE_PAGE_DETAIL_LOAD.COMPLETE);
export const seoTemplatePageDetailLoadError = createAction(SEO_TEMPLATE_PAGE_DETAIL_LOAD.ERROR);

export const seoTemplatePageUpdate = createAction(SEO_TEMPLATE_PAGE_UPDATE.START);
export const seoTemplatePageUpdateRequest = createAction(SEO_TEMPLATE_PAGE_UPDATE.REQUEST);
export const seoTemplatePageUpdateComplete = createAction(SEO_TEMPLATE_PAGE_UPDATE.COMPLETE);
export const seoTemplatePageUpdateError = createAction(SEO_TEMPLATE_PAGE_UPDATE.ERROR);

export const seoTemplatePageDelete = createAction(SEO_TEMPLATE_PAGE_DELETE.START);
export const seoTemplatePageDeleteRequest = createAction(SEO_TEMPLATE_PAGE_DELETE.REQUEST);
export const seoTemplatePageDeleteComplete = createAction(SEO_TEMPLATE_PAGE_DELETE.COMPLETE);
export const seoTemplatePageDeleteError = createAction(SEO_TEMPLATE_PAGE_DELETE.ERROR);
