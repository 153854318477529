import { BASE_URL } from "config";
import * as baseApi from "./_base";

export const endpoints = {
    list: `${BASE_URL}/api/v1/billing/transaction/`,
    detail: (id) => `${BASE_URL}/api/v1/billing/transaction/${id}/`,
};

export const transactionListLoad = (token, payload) => baseApi.ListLoad(endpoints.list, token, payload);
export const transactionDetailLoad = (token, { id }) => baseApi.DetailLoad(endpoints.detail, token, { id });
