import React, { Component } from 'react';
import { Icon } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { arrayIsEqual, tableColumn } from "utils";
import { ActionButton, TableList } from "components/common";
import { SiteSelect, SiteValue } from "../index";


class SitePageList extends Component {
  state = {
    columnList: ['path', 'title', 'type', 'seo_template_off', 'actions'],
    columnByName: {
      path: tableColumn('Путь', 'path', {
        sorter: true,
      }),
      title: tableColumn('Тайтл', 'title', {
        sorter: true,
      }),
      type: tableColumn('Тип', 'type', {
        sorter: true,
      }),
      seo_template_off: tableColumn('Не использ. шаблон', 'seo_template_off', {
        render: (val, item) => val && <Icon type="check-circle" theme="filled" style={{ color: '#fff' }}/>,
        width: 100,
        sorter: true,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.pageListLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.pageShowEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.pageDelete({ id: item.id })
  };

  render() {
    const { history, defaultFilter, activeFilter, topActions, page } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={page}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        topActions={topActions}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}

        filterFields={{
          list: [
            'seo_template_off', 'type',
          ],
          byName: {
            'seo_template_off': {
              label: 'Не использовать шаблон',
              type: 'bool',
            },
            'type': {
              label: 'Тип',
              type: 'list',
              options: [
                { label: 'страница', value: 'page' },
                { label: 'страница c анкетами', value: 'profile_list' },
                { label: 'анкета', value: 'profile' },
                { label: 'фотогалерея', value: 'gallery' },
                { label: 'страница c саунами', value: 'sauna_list' },
                { label: 'сауна', value: 'sauna' },
                { label: 'страница c салонами', value: 'salon_list' },
                { label: 'салон', value: 'salon' },
                { label: 'страница c вакансиями', value: 'job_list' },
                { label: 'вакансия', value: 'job' },
                { label: 'страница cо статьями', value: 'article_list' },
                { label: 'статья', value: 'article' },
                { label: 'услуга', value: 'service' },
                { label: 'район', value: 'district' },
                { label: 'внешность', value: 'ethnicity' },
                //{ label: 'страница c отзывами', value: 'review_list' },
                { label: 'контакты', value: 'contact' },
              ]
            },
          }
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.page,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.page), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SitePageList);