import React, { Component } from 'react';
import { Row, Col, Collapse } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { getUrl } from "utils";
import { ModerationNewValue, Status, FieldValue, OnOff } from "components/common";
import { SiteValue } from "components/blocks/Site";
import { CityValue } from "components/blocks/City";
import styles from './detail.module.css';


class SalonMainScreen extends Component {
  render() {
    const { salon } = this.props;
    const detail = salon.detail || {};
    const moderation_data = detail.moderation_data || {};

    const siteObj = detail.site || {};
    const parameters = detail.parameters || {};
    const contacts = detail.contacts || {};
    const photos = moderation_data.photos || detail.photos;

    const prices = detail.prices || {};

    return (
      <div className="page-content page-content-full">
        <Row gutter={16}>
          <Col span={16}>
            <div className="page-block">
              <FieldValue label="Название" labelWidth={5}>
                {detail.name}
                <ModerationNewValue value={moderation_data.name}/>
              </FieldValue>

              <FieldValue label="Описание" labelWidth={5}>
                {detail.text}
                <ModerationNewValue value={moderation_data.text}/>
              </FieldValue>

              <FieldValue label="Сайт" labelWidth={5}>
                <SiteValue detail={siteObj}/>
              </FieldValue>

              <FieldValue label="Город" labelWidth={5}>
                <CityValue detail={siteObj.city}/>
              </FieldValue>

              <FieldValue label="Статус" labelWidth={5}>
                <Status status={detail.status}/>
              </FieldValue>

              <FieldValue label="Включена" labelWidth={5}>
                <OnOff
                  object={detail}
                  param="is_on"
                  loading={salon.updateStatus.isLoading}
                  onChange={data => this.props.actions.salonUpdate(data)}
                />
              </FieldValue>
            </div>

            <br/>

            <div className="page-block">
              <FieldValue label="Телефон" labelWidth={5}>
                {contacts.phone}
              </FieldValue>

              <FieldValue label="Адрес" labelWidth={5}>
                {contacts.address}
              </FieldValue>

              <FieldValue label="WhatsApp" labelWidth={5}>
                {contacts.whatsapp}
              </FieldValue>

              <FieldValue label="Telegram" labelWidth={5}>
                {contacts.telegram}
              </FieldValue>

              <FieldValue label="Skype" labelWidth={5}>
                {contacts.skype}
              </FieldValue>

              <FieldValue label="Viber" labelWidth={5}>
                {contacts.viber}
              </FieldValue>
            </div>
          </Col>

          <Col span={8}>
            <div className="page-block">
              {photos && photos.length > 0 &&
              <div>
                <div className={styles.photoDetail}>
                  <img src={getUrl(photos[0].medium || photos[0].url)} alt=""/>
                </div>

                {photos.length > 1 &&
                <div className={styles.photos}>
                  {photos.slice(1).map(p => (
                    <div key={`photo${p.id}`} className={styles.photo}>
                      <img src={getUrl(p.medium || p.url)} alt=""/>
                    </div>
                  ))}
                </div>
                }
              </div>
              }
            </div>

            <br/>

            <Collapse defaultActiveKey={['in']}>
              <Collapse.Panel header="Стоимость" key="in">
                <FieldValue label="1 час" labelWidth={8}>
                  {prices.hour} {prices.hour && 'руб'}
                </FieldValue>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    salon: state.salon,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.salon), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalonMainScreen);
