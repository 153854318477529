import React, { Component } from 'react';
import { Switch } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { arrayIsEqual, tableColumn } from "utils";
import { ActionButton, Photo, TableList } from "components/common";


class BannerList extends Component {
  state = {
    updateItemId: null,
    columnList: ['bg_image', 'text', 'position', 'is_published', 'actions'],
    columnByName: {
      text: tableColumn('Текст', 'text', {
        sorter: true,
        render: (val, item) => <div dangerouslySetInnerHTML={{ __html: val }} />,
      }),
      bg_image: tableColumn('Картинка', 'bg_image', {
        render: (val, item) => <Photo url={val && val.url} width={200} height={100}/>,
        width: 112,
      }),
      position: tableColumn('Сортировка', 'position', {
        sorter: true,
      }),
      is_published: tableColumn('Опубликовано', 'is_published', {
        width: 120,
        sorter: true,
        render: (val, item) =>
          <div>
            <Switch
              style={{ marginTop: '5px', minWidth: '62px' }}
              checkedChildren="да" unCheckedChildren="нет"
              checked={item.is_published}
              loading={this.props.banner.updateStatus.isLoading && this.state.updateItemId === item.id}
              onClick={() => this.bannerOnOff(item)}
            />
          </div>,
      }),
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton
            actions={[{
              title: 'Редактировать', key: 'edit', icon: 'edit', action: () => this.showEditForm(item),
            }, {
              title: 'Удалить', key: 'delete', icon: 'delete', action: () => this.delete(item),
              confirm: {
                title: 'Уверены, что хотите удалить?',
                okText: 'Удалить',
                cancelText: 'Отмена'
              }
            }]}
          />
        ),
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    this.props.actions.bannerListLoad({ query, activeFilter });
  };

  showEditForm = (item) => {
    this.props.actions.bannerShowEditForm({ show: true, object: item })
  };

  delete = (item) => {
    this.props.actions.bannerDelete({ id: item.id })
  };

  bannerOnOff = (item) => {
    const data = { id: item.id, is_published: !item.is_published, preventLoadDetail: true };
    this.setState({
      updateItemId: item.id,
    });
    this.props.actions.bannerUpdate(data);
  };

  render() {
    const { history, defaultFilter, activeFilter, topActions, banner } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={banner}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        topActions={topActions}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        showSearch={true}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    banner: state.banner,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.banner), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerList);