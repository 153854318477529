import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { arrayIsEqual, tableColumn } from "utils";
import { TableList } from 'components/common';
import { Switch } from "antd";


class BalanceList extends Component {
  state = {
    updateItemId: null,
    columnList: ['user__email', 'balance', 'is_charging'],
    columnByName: {
      user__email: tableColumn('Пользователь', 'user__email', {
        render: (text, item) => <Link to={`/balance/${item.id}`}>{item.user.email}</Link>,
      }),
      balance: tableColumn('Баланс', 'balance', {
        sorter: true,
      }),
      is_charging: tableColumn('Вести учёт', 'is_charging', {
        sorter: true,
        width: 150,
        render: (val, item) =>
          <div>
            <Switch
              style={{ marginTop: '5px', minWidth: '62px' }}
              checkedChildren="да" unCheckedChildren="нет"
              checked={item.is_charging}
              loading={this.props.balance.updateStatus.isLoading && this.state.updateItemId === item.id}
              onClick={() => this.balanceOnOff(item)}
            />
          </div>,
      }),
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnList } = nextProps;
    if (columnList && !arrayIsEqual(prevState.columnList, columnList)) {
      return {
        columnList: columnList,
      }
    }
    return null;
  }

  load = ({ query, activeFilter }) => {
    const { balance } = this.props;
    if (activeFilter === undefined) {
      activeFilter = balance.filter.activeFilter
    }
    this.props.actions.balanceListLoad({ query, activeFilter, locationChange: true });
  };

  showEditForm = (item) => {
    this.props.actions.balanceShowEditForm({ show: true, object: item })
  };

  balanceOnOff = (item) => {
    const data = { id: item.id, is_charging: !item.is_charging, preventLoadDetail: true };
    this.setState({
      updateItemId: item.id,
    });
    this.props.actions.balanceUpdate(data);
  };

  render() {
    const { balance, defaultFilter, activeFilter, topActions, history } = this.props;
    const { columnList, columnByName } = this.state;

    return (
      <TableList
        history={history}
        data={balance}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        columnList={columnList}
        columnByName={columnByName}
        onLoadData={this.load}
        topActions={topActions}
        showSearch={true}
        onRow={(item, rowIndex) => {
          return {
            onDoubleClick: event => {
              this.props.history.push(`/balance/${item.id}`);
            },
          };
        }}
      />
    );
  }
}


function mapStateToProps(state) {
  return {
    balance: state.balance,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.balance), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceList);


