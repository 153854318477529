import { BASE_URL } from "config";

import * as base from './_base';


export const endpoints = {
  list: `${BASE_URL}/api/v1/advert/banner/`,
  detail: (id) => `${BASE_URL}/api/v1/advert/banner/${id}/`,
};

export const bannerListLoad = (token, payload) => base.ListLoad(endpoints.list, token, payload);
export const bannerDetailLoad = (token, { id }) => base.DetailLoad(endpoints.detail, token, { id });
export const bannerUpdate = (token, data) => base.Update(endpoints.list, endpoints.detail, token, data);
export const bannerDelete = (token, data) => base.Delete(endpoints.detail, token, data);
