import React, { Component } from 'react';
import { Col, Row, Input, Select } from "antd";

import { FormItem } from 'utils';
import { PhotoUpload } from 'components/common';
import { SiteSelect } from 'components/blocks/Site';


export default class MainFieldSet extends Component {
  render() {
    const { form, profile, siteId } = this.props;
    const { updateStatus, editObject } = profile;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    return (
      <Row gutter={32}>
        <Col span={16}>
          <div className="form-block">
            <SiteSelect
              form={form}
              instance={instance}
              name="site"
              label="Сайт"
              errorData={errorData}
              convertValue={value => value.id}
              initialValue={siteId}
            />

            <FormItem
              form={form}
              instance={instance}
              name="status"
              label="Статус"
              errorData={errorData}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Выберите"
              >
                <Select.Option value={0}>Новая</Select.Option>
                <Select.Option value={1}>На проверке</Select.Option>
                <Select.Option value={3}>Проверена</Select.Option>
                <Select.Option value={4}>Отклонена</Select.Option>
                <Select.Option value={5}>Забанена</Select.Option>
              </Select>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="name"
              label="Имя"
              required={true}
              errorData={errorData}
            >
              <Input size="large" style={{ width: '100%' }}/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="text"
              label="Описание"
              required={true}
              errorData={errorData}
            >
              <Input.TextArea size="large" rows={10}/>
            </FormItem>
          </div>
        </Col>

        <Col span={8}>
          <FormItem
            form={form}
            instance={instance}
            name="photos"
            label="Фотографии"
            required={false}
            errorData={errorData}
            valuePropName="fileList"
          >
            <PhotoUpload
              title="Загрузить фото"
            />
          </FormItem>
        </Col>
      </Row>
    );
  }
}
