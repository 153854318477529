import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Input } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { FormItem } from 'utils/form_helpers';
import { Error } from 'components/common';


class CityForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, city } = this.props;
        const instance = city.editObject;

        let data = Object.assign({}, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        actions.cityUpdate(data);
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.cityShowEditForm({ show: false });
  };

  render() {
    const { form, city } = this.props;
    const { showEditForm, updateStatus, editObject } = city;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = city.updateStatus.isLoading;

    return (
      <Modal
        visible={showEditForm}
        title="Редактирование города"
        onCancel={this.close}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit}>
            <Error errors={errorData.non_field_errors}/>

            <FormItem
              form={form}
              instance={instance}
              name="name"
              label="Название"
              required={true}
              errorData={errorData}

            >
              <Input size="large"/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="slug"
              label="Путь"
              required={true}
              errorData={errorData}

            >
              <Input size="large"/>
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedCityForm = Form.create()(CityForm);

function mapStateToProps(state) {
  return {
    city: state.city,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.city), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedCityForm);

