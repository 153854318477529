import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";


export default class GenericFKValue extends PureComponent {
  render() {
    const { object } = this.props;
    if (object && object.id) {
      return (
        <Link to={`/${object.type}/${object.id}`}>
          {object.name}
        </Link>
      );
    }
    return '';
  }
}
