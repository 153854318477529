import React, { Component } from 'react';
import { Col, Row, Input, Checkbox } from "antd";

import { FormItem } from 'utils';


export default class TariffFieldSet extends Component {
  render() {
    const { form, instance, errorData } = this.props;

    return (
      <div className="form-block">
        <FormItem
          form={form}
          instance={instance}
          name="billing_is_on"
          required={false}
          errorData={errorData}
          valuePropName="checked"
        >
          <Checkbox>
            Биллинг включён
          </Checkbox>
        </FormItem>

        <Row gutter={16}>
          <Col span={6}>
            <div className="fieldset">
              <div className="fieldset-title">Анкета</div>
              <div>
                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.profile_period"
                  label="Период"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="час"
                  />
                </FormItem>

                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.profile_price"
                  label="Цена размещения"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="руб"
                  />
                </FormItem>

                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.profile_up_price"
                  label="Цена поднятия"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="руб"
                  />
                </FormItem>
              </div>
            </div>
          </Col>

          <Col span={6}>
            <div className="fieldset">
              <div className="fieldset-title">Сауна</div>
              <div>
                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.sauna_period"
                  label="Период"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="час"
                  />
                </FormItem>

                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.sauna_price"
                  label="Цена размещения"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="руб"
                  />
                </FormItem>

                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.sauna_up_price"
                  label="Цена поднятия"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="руб"
                  />
                </FormItem>
              </div>
            </div>
          </Col>

          <Col span={6}>
            <div className="fieldset">
              <div className="fieldset-title">Салон</div>
              <div>
                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.salon_period"
                  label="Период"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="час"
                  />
                </FormItem>

                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.salon_price"
                  label="Цена размещения"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="руб"
                  />
                </FormItem>

                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.salon_up_price"
                  label="Цена поднятия"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="руб"
                  />
                </FormItem>
              </div>
            </div>
          </Col>

          <Col span={6}>
            <div className="fieldset">
              <div className="fieldset-title">Вакансия</div>
              <div>
                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.job_period"
                  label="Период"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="час"
                  />
                </FormItem>

                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.job_price"
                  label="Цена размещения"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="руб"
                  />
                </FormItem>

                <FormItem
                  form={form}
                  instance={instance}
                  name="tariff.job_up_price"
                  label="Цена поднятия"
                  required={false}
                  errorData={errorData}
                >
                  <Input
                    size="large" style={{ width: '100%' }}
                    addonAfter="руб"
                  />
                </FormItem>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
