import { put, takeEvery, select } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/article';


export function* watch() {
  yield takeEvery(actions.ARTICLE_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.ARTICLE_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.ARTICLE_UPDATE.START, actionUpdate);
  yield takeEvery(actions.ARTICLE_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.articleListLoad,
    {
      request: actions.articleListLoadRequest,
      complete: actions.articleListLoadComplete,
      error: actions.articleListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.articleDetailLoad,
    {
      request: actions.articleDetailLoadRequest,
      complete: actions.articleDetailLoadComplete,
      error: actions.articleDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.articleUpdate,
    {
      request: actions.articleUpdateRequest,
      complete: actions.articleUpdateComplete,
      error: actions.articleUpdateError,
    },
    function* (data) {
      yield put(actions.articleShowEditForm({ show: false }));
      const filter = yield select((state) => state.article.filter);
      yield put(actions.articleListLoad(filter));
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.articleDelete,
    {
      request: actions.articleDeleteRequest,
      complete: actions.articleDeleteComplete,
      error: actions.articleDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.article.filter);
      yield put(actions.articleListLoad(filter));
    }
  );
}
