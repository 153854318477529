import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SITE_LIST_LOAD = createRequestTypes('SITE_LIST_LOAD');
export const SITE_DETAIL_LOAD = createRequestTypes('SITE_DETAIL_LOAD');
export const SITE_UPDATE = createRequestTypes('SITE_UPDATE');
export const SITE_DELETE = createRequestTypes('SITE_DELETE');
export const SITE_SHOW_EDIT_FORM = 'SITE_SHOW_EDIT_FORM';

export const siteShowEditForm = createAction(SITE_SHOW_EDIT_FORM);

export const siteListLoad = createAction(SITE_LIST_LOAD.START);
export const siteListLoadRequest = createAction(SITE_LIST_LOAD.REQUEST);
export const siteListLoadComplete = createAction(SITE_LIST_LOAD.COMPLETE);
export const siteListLoadError = createAction(SITE_LIST_LOAD.ERROR);

export const siteDetailLoad = createAction(SITE_DETAIL_LOAD.START);
export const siteDetailLoadRequest = createAction(SITE_DETAIL_LOAD.REQUEST);
export const siteDetailLoadComplete = createAction(SITE_DETAIL_LOAD.COMPLETE);
export const siteDetailLoadError = createAction(SITE_DETAIL_LOAD.ERROR);

export const siteUpdate = createAction(SITE_UPDATE.START);
export const siteUpdateRequest = createAction(SITE_UPDATE.REQUEST);
export const siteUpdateComplete = createAction(SITE_UPDATE.COMPLETE);
export const siteUpdateError = createAction(SITE_UPDATE.ERROR);

export const siteDelete = createAction(SITE_DELETE.START);
export const siteDeleteRequest = createAction(SITE_DELETE.REQUEST);
export const siteDeleteComplete = createAction(SITE_DELETE.COMPLETE);
export const siteDeleteError = createAction(SITE_DELETE.ERROR);
