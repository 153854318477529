import {put, takeEvery, select} from 'redux-saga/effects';
import {push} from "connected-react-router";

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/seo';


export function* watch() {
  yield takeEvery(actions.PAGE_LIST_LOAD.START, actionListLoad);
  yield takeEvery(actions.PAGE_DETAIL_LOAD.START, actionDetailLoad);
  yield takeEvery(actions.PAGE_UPDATE.START, actionUpdate);
  yield takeEvery(actions.PAGE_DELETE.START, actionDelete);
}

function* actionListLoad(action) {
  yield base.Request(action, api.pageListLoad,
    {
      request: actions.pageListLoadRequest,
      complete: actions.pageListLoadComplete,
      error: actions.pageListLoadError,
    },
  );
}

function* actionDetailLoad(action) {
  yield base.Request(action, api.pageDetailLoad,
    {
      request: actions.pageDetailLoadRequest,
      complete: actions.pageDetailLoadComplete,
      error: actions.pageDetailLoadError,
    },
  );
}

function* actionUpdate(action) {
  yield base.Request(action, api.pageUpdate,
    {
      request: actions.pageUpdateRequest,
      complete: actions.pageUpdateComplete,
      error: actions.pageUpdateError,
    },
    function* (data) {
      yield put(actions.pageShowEditForm({show: false}));
      /*if (!action.payload.preventLoadDetail) {
        yield put(actions.pageDetailLoad({ id: data.id }));
        yield put(push(`/page/${data.id}`));
      } else {*/
      const filter = yield select((state) => state.page.filter);
      yield put(actions.pageListLoad(filter));
      //}
    }
  );
}

function* actionDelete(action) {
  yield base.Request(action, api.pageDelete,
    {
      request: actions.pageDeleteRequest,
      complete: actions.pageDeleteComplete,
      error: actions.pageDeleteError,
    },
    function* (data) {
      const filter = yield select((state) => state.page.filter);
      yield put(actions.pageListLoad(filter));
    }
  );
}
