import React, { Component } from 'react';

import { ServiceTransferField } from 'components/blocks/Service';


export default class ServiceFieldSet extends Component {
  render() {
    const { form, profile } = this.props;
    const { updateStatus, editObject } = profile;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    return (
      <div className="form-block">
        <ServiceTransferField
          form={form}
          instance={instance}
          name="services"
          label="Услуги"
          required={true}
          errorData={errorData}
        />
      </div>
    );
  }
}
