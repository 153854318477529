import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.saunaShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.saunaListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.saunaListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.saunaListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.saunaDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.saunaDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.saunaDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.saunaUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.saunaUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.saunaUpdateError]: (state, action) => base.updateError(state, action),

    [actions.saunaDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.saunaDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.saunaDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
