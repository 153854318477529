import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { TransactionList } from 'components/blocks/Transaction';


class BalanceTransactionListScreen extends Component {
  render() {
    const { balance, history } = this.props;
    const detail = balance.detail || {};

    return (
      <Fragment>
        <div className="page-content page-content-full">
          <div className="page-block page-block-full">
            {detail.id &&
            <TransactionList
              history={history}
              activeFilter={{user_balance: detail.id}}
            />}
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    balance: state.balance,
  };
}

export default connect(mapStateToProps)(BalanceTransactionListScreen);
