import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.salonShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.salonListLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.salonListLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.salonListLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.salonDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.salonDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.salonDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.salonUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.salonUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.salonUpdateError]: (state, action) => base.updateError(state, action),

    [actions.salonDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.salonDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.salonDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
