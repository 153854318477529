import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Input } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { FormItem } from 'utils';
import { Error } from "components/common";


class ServiceGroupForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, service_group } = this.props;
        const instance = service_group.editObject;

        let data = Object.assign({}, values);
        if (instance && instance.id) {
          data.id = instance.id;
        }
        actions.serviceGroupUpdate(data);
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.serviceGroupShowEditForm({ show: false });
  };

  render() {
    const { form, service_group } = this.props;
    const { showEditForm, updateStatus, editObject } = service_group;
    const instance = editObject;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = service_group.updateStatus.isLoading;

    return (
      <Modal
        visible={showEditForm}
        title="Редактирование группы услуг"
        onCancel={this.close}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit}>
            <Error errors={errorData.non_field_errors}/>

            <FormItem
              form={form}
              instance={instance}
              name="name"
              label="Название"
              required={true}
              errorData={errorData}

            >
              <Input size="large"/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="sort"
              label="Сортировка"
              required={true}
              errorData={errorData}

            >
              <Input size="large"/>
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(ServiceGroupForm);

function mapStateToProps(state) {
  return {
    service_group: state.service_group,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.service_group), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);
