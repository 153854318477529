import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getObject } from 'utils';


class PageLink extends PureComponent {
    render() {
        const { id, page } = this.props;
        const obj = getObject(page, id);

        return (
            !obj ?
                <span>{id}</span>
                :
                <span>{obj.title} {obj.path}</span>
        );
    }
}

function mapStateToProps(state) {
    return {
        page: state.page,
    };
}

export default connect(mapStateToProps)(PageLink);
